import {
	Typography,
	Grid,
	Chip,
} from "@mui/material";

import { memo, useState, useEffect } from "react";

import ReactFlow, {
	Position,
	Handle,
} from 'reactflow';

import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ErrorIcon from '@mui/icons-material/Error';

import Popup from "../Popup.js";

import ParametersInput from "./parameters-input.js";
import ParametersSelect from "./parameters-select.js";
import ParametersSlider from "./parameters-slider.js";
import ParametersColor from "./parameters-color.js";
import ParametersBroker from "./parameters-broker.js";
import ParametersSwitch from "./parameters-switch.js";
import ParametersThread from "./parameters-thread.js";

import CustomHandle from "./custom-node-handle.js";

import { useDispatch } from 'react-redux';
import { updateVariable } from "./slice-storage-variables.js";
import { updateNodeParameters } from "./slice-nodes.js";

// Create a custom node based on inputs
const CustomNode = memo(({ data, isConnectable }) => {
	const dispatch = useDispatch();
	const [popupOpen, setPopupOpen] = useState(false);
	const [visibleParameters, setVisibleParameters] = useState(false);
	const [parameters, setParameters] = useState(data.parameters);
	const [internalData, setInternalData] = useState(data);

	// Check if all required parameters have values
	const allRequired = data.parameters?.filter((param) => param.required === true);
	const missingRequired = allRequired?.filter((param) => {
		const found = parameters.find((p) => p.id === param.id);
		return found === undefined || found.value === "" || found.value === undefined || found.value === null;
	}).length;

	return (
		<Grid
			style={{
				background: data.backgroundColor ?? '#fff',
				border: '1px solid #000',
				borderRadius: '10px',
				padding: '10px',
				paddingRight: '30px',
				paddingLeft: '30px',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			}}
		>
			<Popup
				width="800px"
				open={popupOpen}
				title={data.label}
				onClose={async () => {
					setPopupOpen(false);
					// Check for variables
					if (data.action && data.action.storage) {
						await dispatch(updateVariable({
							nodeId: data.id,
							variable: data.action.storage,
							data,
							parameters,
							count: data.count,
							value: undefined,
						}));
					}

					// Check if the node is Create variable
					if (data.label === "Create variable") {
						const variable = parameters.find((p) => p.id === "variable").value;
						const value = parameters.find((p) => p.id === "value").value;
						console.log("Variable", variable);
						await dispatch(updateVariable({
							nodeId: data.id,
							variable,
							data,
							parameters,
							count: data.count,
							value,
						}));
					}

					// Update the parameters
					setInternalData({ ...internalData, parameters });
					await dispatch(updateNodeParameters({
						id: data.id,
						parameters: { ...internalData, parameters },
					}));
				}}
			>
				<Grid
					container
					display="flex"
					direction="column"
					justifyContent="flex-start"
					alignItems="flex-start"
				>
					{data.parameters && data.parameters.length > 0 ? (
						// Switch based on the type of the parameter
						data.parameters.map((param) => (
							param.type === "input" ? (
								<ParametersInput
									key={`${param.id}${data.name}${data.toolbox}`}
									param={param}
									parameters={parameters}
									setParameters={setParameters}
									nodeData={data}
								/>
							) : param.type === "switch" ? (
								<ParametersSwitch
									key={`${param.id}${data.name}${data.toolbox}`}
									param={param}
									parameters={parameters}
									setParameters={setParameters}
									nodeData={data}
								/>
							) : param.type === "select" ? (
								<ParametersSelect
									key={`${param.id}${data.name}${data.toolbox}`}
									param={param}
									parameters={parameters}
									setParameters={setParameters}
									nodeData={data}
								/>
							) : param.type === "slider" ? (
								<ParametersSlider
									key={`${param.id}${data.name}${data.toolbox}`}
									param={param}
									parameters={parameters}
									setParameters={setParameters}
									nodeData={data}
								/>
							) : param.type === "color" ? (
								<ParametersColor
									key={`${param.id}${data.name}${data.toolbox}`}
									param={param}
									parameters={parameters}
									setParameters={setParameters}
									nodeData={data}
								/>
							) : param.type === "broker" ? (
								<ParametersBroker
									key={`${param.id}${data.name}${data.toolbox}`}
									param={param}
									parameters={parameters}
									setParameters={setParameters}
									nodeData={data}
								/>
							) : param.type === "thread" ? (
								<ParametersThread
									key={`${param.id}${data.name}${data.toolbox}`}
									param={param}
									parameters={parameters}
									setParameters={setParameters}
									nodeData={data}
								/>
							) : (
								"Unsupported type"
							)
						))
					) : (
						<Typography>
							{"No parameters"}
						</Typography>
					)}
				</Grid>
			</Popup>
			{/* Iterate on data.inputs */}
			{Array.from({ length: data.inputs ?? 1 }, (_, i) => (
				<CustomHandle
					key={Math.random().toString()}
					id={`in_${i.toString()}`}
					type="target"
					position={Position.Top}
					// Place them in different positions
					style={{
						left: `calc(${(i + 1) * (100 / (data.inputs + 1))}%)`,
						top: "-18px",
						background: '#0a0',
						width: '15px',
						height: '15px',
					}}
					isConnectable={100}
					onConnect={(params) => console.log('handle onConnect', params)}
				/>
			))}
			<Grid
				item
				container
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				sx={{
					width: "100%",
					height: "100%",
					position: "relative",
				}}
			>
				<Grid
					item
					container
					display="flex"
					flexDirection="row"
					justifyContent="center"
					alignItems="center"
				>
					<Typography
						px={1}
						sx={{
							borderRadius: "50px",
							marginRight: "10px",
							backgroundColor: data.fontColor,
							color: data.backgroundColor,
							fontSize: "0.8em",
							fontWeight: "bold",
						}}
					>
						{data.count}
					</Typography>
					<Typography
						sx={{
							fontSize: "1.2em",
							color: data.fontColor,
							marginBottom: data.parameters && visibleParameters ? "10px" : "0px",
						}}
					>
						{data.label}
					</Typography>
				</Grid>
				<Grid
					item
					container
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="flex-start"
				>

					{!visibleParameters && parameters && parameters.length > 0 && parameters.find((p) => p.show === true) && (
						// Iterate over all parameters with show true
						parameters.map((param) => (
							param.show && (
								<Typography
									key={Math.random().toString()}
									sx={{
										fontSize: "0.8em",
										color: data.fontColor,
									}}
								>
									{param.label}
									{" : "}
									{param.type === "switch" ? (
										`${param.value}`
									) : (
										`${param.value}`
									)}
								</Typography>
							)
						))

					)}
				</Grid>

				{visibleParameters && (
					<Grid
						itemc
						container
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="flex-start"
					>
						{parameters.map((param) => (
							<Typography
								key={Math.random().toString()}
								sx={{
									fontSize: "0.8em",
									color: data.fontColor,
								}}
							>
								{param.label}
								{" : "}
								{param.type === "switch" ? (
									`${param.value}`
								) : (
									`${param.value}`
								)}
							</Typography>
						))}
					</Grid>
				)}
			</Grid>
			{/* Add a parameters icon which opens the popup */}
			{
				data.parameters && data.parameters.length > 0 && (
					<Grid
						item
						// container
						flexDirection="column"
						display="flex"
						justifyContent="flex-end"
						alignItems="flex-start"
						sx={{
							position: "absolute",
							right: "-23.5px",
							top: "7px",
							padding: "4px",
							paddingLeft: "4px",
							paddingRight: "4px",
							borderWidth: "1px",
							border: "1px solid #666",
							borderLeftWidth: "0px",
							borderRadius: "10px",
							borderTopLeftRadius: "0px",
							borderBottomLeftRadius: "0px",
							// backgroundColor: "gray",
						}}
					>
						<SettingsIcon
							sx={{
								cursor: "pointer",
								color: data.fontColor,
								fontSize: "1em",
								// marginLeft: "5px",
							}}
							onClick={() => {
								setPopupOpen(true);
							}}
						/>
						{visibleParameters && (
							<VisibilityOffIcon
								sx={{
									cursor: "pointer",
									color: data.fontColor,
									fontSize: "1em",
								}}
								onClick={() => {
									setVisibleParameters(false);
								}}
							/>
						)}
						{!visibleParameters && (
							<VisibilityIcon
								sx={{
									cursor: "pointer",
									color: data.fontColor,
									fontSize: "1em",
								}}
								onClick={() => {
									setVisibleParameters(true);
								}}
							/>
						)}
						{missingRequired > 0 && (
							<ErrorIcon
								sx={{
									color: "orange",
									fontSize: "1em",
									// marginRight: "5px",
								}}
							/>
						)}
					</Grid>
				)
			}
			{/* <input className="nodrag" type="color" defaultValue={data.color} onChange={data.onChange} /> */}
			{
				Array.from({ length: data.outputs ?? 1 }, (_, i) => (
					<CustomHandle
						key={Math.random().toString()}
						type="source"
						position={Position.Bottom}
						id={`out_${i.toString()}`}
						style={{
							right: `calc(${(i + 1) * (100 / (data.outputs + 1))}%)`,
							bottom: "-18px",
							background: '#a00',
							width: '15px',
							height: '15px',
						}}
						isConnectable={100}
					/>
				))
			}
		</Grid>
	);
});

export default CustomNode;

export const getDocumentation = () => `
![EnvMakerLogo](https://media.discordapp.net/attachments/1164516702554427472/1164901515023093770/Env_maker_logo_Light.png)

This graphical DSL allows for blueprint creation of environments, to be used in IoT and CPS applications.

## Usage

1. Specify the with and the height of the environment in cells (rows/columns)
2. Select one (or more) of the four environments (City, Farm, Home, Supermarket)
3. Drag and drop the icons in each grid
4. Change the orientation of the icons by clicking on them

The model is automatically saved after each icon drop or rotation.
`;

/* eslint-disable max-len */

import { Collapse } from '@blueprintjs/core';
import React from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';
import availableItems from './available-items';
import AutoWall from './available-items/general/auto-wall';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

const MainBox = styled.div`
	width: 100%;
	height: 100%;
	padding: 0px;
	margin: 0px;
	display: flex;
`;

const MainMap = styled.div`
	width: calc(100% - 250px);
	height: 100%;
	display: flex;
	align-items: center;
	background: rgba(255, 255, 255, 0.1);
`;

const Map = styled.div`
	width: ${(props) => `${props.mapWidth}px`};
	height: ${(props) => `${props.mapHeight}px`};
	background: white;
	position: relative;
	margin: auto;
	transform: ${(props) => `scale(${props.zoom}) translate(${props.moveX}px, ${props.moveY}px)`};
`;

const Menu = styled.div`
	width: 250px;
	height: 100%;
	padding-left: 20px;
	padding-right: 20px;
	display: block;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow-y: auto;
	overflow-x: hidden;
`;

const CategoryDiv = styled.div`
	width: 100%;
	min-height: 20px;
	background: white;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	margin-top: 2px;
`;

const DragComp = styled.div`
	width: 50px;
	height: 50px;
	border-radius: 50px;
	cursor: move;
	z-index: 1;
	align-items: center;
	justify-content: center;
	margin: 5px;
`;

const EmptyComp = styled.div`
	width: 50px;
	height: 50px;
	cursor: move;
	z-index: 1;
	align-items: center;
	justify-content: center;
	margin: 5px;
`;

const categoriesPrefix = {
	general: 'gn',
	sensors: 'sn',
	effectors: 'ef',
};

const getMapDimensions = (mainMapWidth, mainMapHeight) => {
	const availWidth = mainMapWidth;
	const availHeight = mainMapHeight;
	const initRatio = mainMapWidth / mainMapHeight;
	const ratio = 1.5;

	if (initRatio === ratio) {
		return { width: availWidth, height: availHeight };
	}

	if (initRatio > ratio) {
		return { width: ratio * availHeight, height: availHeight };
	}

	return { width: availWidth, height: availWidth / ratio };
};

const getElementDimension = (mapWidth, mapHeight, nRows, nCols) => {
	const elementWidth = (mapWidth) / nCols;
	const elementHeight = (mapHeight) / nRows;
	return Math.min(elementWidth, elementHeight);
};

export class SetSensorsObstacles extends React.Component {
	constructor(props) {
		super(props);

		this.selectMap = props.selectMap;
		this.newItem = props.newItem;
		this.updateItem = props.updateItem;
		this.deleteItem = props.deleteItem;
		this.changeGridDimension = props.changeGridDimension;
		this.checkItemNameExists = props.checkItemNameExists;
		this.setMapDimensions = props.setMapDimensions;
		this.clearItems = props.clearItems;

		// This should be in state since it changes dynamically
		this.envmakerModel = props.envmakerModel;

		this.state = {
			display: props.display,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
			mapWidthM: props.mapWidthM,
			resolution: props.resolution,
			gridMeters: props.gridMeters,
			gridDimension: props.gridDimension,
			elementDimensions: 30,
			openCategory: null,
			selectedMap: props.selectedMap,
			autoWalls: props.autoWalls,
			showAutoWalls: false,
			nextId: props.nextId,
			dragging: null,
			draggingCat: null,
			draggedItem: { x: 0, y: 0 },
			items: props.items,
			warningAlertOpen: false,
			zoom: 1,
			moveX: 0,
			moveY: 0,
		};

		this.onStart = this.onStart.bind(this);
		this.onDrag = this.onDrag.bind(this);
		this.onStop = this.onStop.bind(this);
		this.firstChangeMapDimensions = this.firstChangeMapDimensions.bind(this);
		this.changeMapDimensions = this.changeMapDimensions.bind(this);
		this.changeCategoryState = this.changeCategoryState.bind(this);
		this.deleteAutoWall = this.deleteAutoWall.bind(this);
		this.changeShowAutoWalls = this.changeShowAutoWalls.bind(this);
		this.confirmShowWalls = this.confirmShowWalls.bind(this);
		this.zoom = this.zoom.bind(this);
	}

	componentDidMount() {
		const { display } = this.state;
		if (display) {
			this.firstChangeMapDimensions();
		}
	}

	static getDerivedStateFromProps(props) {
		return {
			display: props.display,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
			mapWidthM: props.mapWidthM,
			resolution: props.resolution,
			gridMeters: props.gridMeters,
			gridDimension: props.gridDimension,
			selectedMap: props.selectedMap,
			autoWalls: props.autoWalls,
			nextId: props.nextId,
			items: props.items,
		};
	}

	componentDidUpdate(prevProps) {
		const { display, envmakerModel } = this.props;
		if (display !== prevProps.display) {
			if (display) {
				this.firstChangeMapDimensions();
			} else {
				window.removeEventListener('resize', this.changeMapDimensions);
			}
		}

		if (envmakerModel !== prevProps.envmakerModel) {
			// console.log("Envmaker changed", envmakerModel);
			this.envmakerModel = envmakerModel;
			this.firstChangeMapDimensions();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.changeMapDimensions);
	}

	onStart(event) {
		event.preventDefault();
	}

	onDrag(event, compCat, comp) {
		event.preventDefault();
		this.setState({ dragging: comp, draggingCat: compCat, draggedItem: { x: event.pageX, y: event.pageY } });
	}

	onStop(event) {
		event.preventDefault();
		const { bottom, left, top, right } = document.querySelector('#sensorsobstacles-map').getBoundingClientRect();
		const { x, y } = event;
		const { mapWidth, mapHeight, dragging, draggingCat, nextId } = this.state;

		if ((x >= left) && (x <= right) && (y <= bottom) && (y >= top)) {
			const newX = ((x - left) / mapWidth) * 100;
			const newY = ((bottom - y) / mapHeight) * 100;
			const newElem = {
				id: nextId, itemName: `${categoriesPrefix[draggingCat]}_${dragging}_${nextId}`, itemCat: dragging, cat: draggingCat, x: newX, y: newY,
			};
			this.newItem(draggingCat, dragging, nextId, Object.assign(newElem, availableItems[draggingCat].components[dragging].props));
		}

		this.setState({ dragging: null, draggingCat: null, draggedItem: { x: 0, y: 0 } });
	}

	firstChangeMapDimensions() {
		window.addEventListener('resize', this.changeMapDimensions);
		const mainMapWidth = document.querySelector('#sensorsobstacles-mainmap').offsetWidth;
		const mainMapHeight = document.querySelector('#sensorsobstacles-mainmap').offsetHeight;
		const dimensions = getMapDimensions(mainMapWidth, mainMapHeight);
		this.setMapDimensions(dimensions.width, dimensions.height, true);
		this.setState({ elementDimensions: (35 / 750) * dimensions.width });
	}

	changeMapDimensions() {
		const { mapWidthM, gridMeters } = this.state;
		const mainMapWidth = document.querySelector('#sensorsobstacles-mainmap').offsetWidth;
		const mainMapHeight = document.querySelector('#sensorsobstacles-mainmap').offsetHeight;
		const dimensions = getMapDimensions(mainMapWidth, mainMapHeight);
		const gridDimension = (gridMeters * dimensions.width) / mapWidthM;
		this.setMapDimensions(dimensions.width, dimensions.height);
		this.setState({ elementDimensions: (35 / 750) * dimensions.width }, () => { this.changeGridDimension(gridDimension); });
	}

	changeCategoryState(cat) {
		const { openCategory } = this.state;
		if (openCategory === cat) {
			this.setState({ openCategory: null });
		} else {
			this.setState({ openCategory: cat });
		}
	}

	deleteAutoWall(id) {
		const { selectedMap } = this.state;
		const newWalls = selectedMap.walls.filter((el) => el.id !== id);
		this.selectMap({
			name: selectedMap.name,
			map: selectedMap.map,
			walls: newWalls,
		});
	}

	changeShowAutoWalls() {
		const { showAutoWalls } = this.state;
		if (showAutoWalls) {
			this.setState({ showAutoWalls: false });
		} else {
			this.setState({ warningAlertOpen: true });
		}
	}

	confirmShowWalls() {
		this.setState({ showAutoWalls: true, warningAlertOpen: false });
	}

	zoom(event) {
		let { zoom } = this.state;
		if (event.deltaY > 0) {
			zoom -= 0.05;
		} else {
			zoom += 0.05;
		}

		if (zoom < 1) {
			zoom = 1;
		} else if (zoom > 2) {
			zoom = 2;
		}

		this.setState({ zoom });
	}

	render() {
		const { display, selectedMap, resolution, gridMeters, gridDimension, elementDimensions, autoWalls, dragging, draggingCat, draggedItem, items, showAutoWalls, warningAlertOpen, zoom, moveX, moveY, mapWidth, mapHeight } = this.state;

		const nCols = this.envmakerModel?.nCols;
		const nRows = this.envmakerModel?.nRows;
		// const mapWidth = this.envmakerModel?.mapWidth;
		// const mapHeight = this.envmakerModel?.mapHeight;

		const elementDimension = getElementDimension(mapWidth, mapHeight, nRows, nCols);
		const grid = [];
		if (this.envmakerModel?.boxes) {
			for (const box of Object.keys(this.envmakerModel.boxes)) {
				grid.push(
					<Draggable
						key={box}
						allowAnyClick={false}
						position={{ x: 0, y: 0 }}
						onStart={this.onCompStart}
						onDrag={(event) => { this.onCompDrag(event, box); }}
						onStop={(event) => { this.onCompStop(event, box); }}
					>
						<div
							key={box}
							id={box}
							style={{
								display: 'flex',
								width: elementDimension,
								height: elementDimension,
								position: 'absolute',
								left: ((mapWidth - nCols * elementDimension) / 2 + (this.envmakerModel.boxes[box].c - 1) * elementDimension),
								top: ((mapHeight - nRows * elementDimension) / 2 + (this.envmakerModel.boxes[box].r - 1) * elementDimension),
								cursor: 'move',
								justifyContent: 'center',
								opacity: 0.3,
							}}
						>
							<img src={this.envmakerModel.boxes[box].content} alt="" style={{ maxWidth: '100%', maxHeight: '100%', transform: `rotate(${this.envmakerModel.boxes[box].rotation}deg)` }} />
						</div>
					</Draggable>,
				);
			}
		}

		const objects = [];
		for (const cat of Object.keys(items)) {
			for (const catItem of Object.keys(items[cat])) {
				for (const item of Object.keys(items[cat][catItem])) {
					objects.push(
						availableItems[cat].components[catItem].component(
							items[cat][catItem][item],
							resolution,
							elementDimensions,
							mapWidth,
							mapHeight,
							this.updateItem,
							this.deleteItem,
							this.checkItemNameExists,
						),
					);
				}
			}
		}

		return ([
			<MainBox key="sensorsobstacles" style={{ display: (display) ? 'flex' : 'none' }}>
				<MainMap id="sensorsobstacles-mainmap">
					<Map id="sensorsobstacles-map" mapWidth={mapWidth} mapHeight={mapHeight} zoom={zoom} moveX={moveX} moveY={moveY} onWheel={this.zoom}>
						{grid}
						{autoWalls && showAutoWalls && selectedMap.name !== null
							&& selectedMap.walls.map((aWall) => (
								<AutoWall key={`auto_${aWall.id}`} id={aWall.id} x1={aWall.x1} y1={aWall.y1} x2={aWall.x2} y2={aWall.y2} elementDimensions={elementDimensions} mapWidth={mapWidth} mapHeight={mapHeight} deleteItem={this.deleteAutoWall} resolution={resolution} />
							))}
						{objects}
					</Map>
				</MainMap>
				<div
					style={{
						display: ((dragging === null) ? 'none' : 'flex'),
						width: elementDimensions,
						height: elementDimensions,
						borderRadius: `${elementDimensions}px`,
						opacity: 0.6,
						background: '#3c9764',
						position: 'absolute',
						left: (draggedItem.x - (elementDimensions / 2)),
						top: (draggedItem.y - 100 - (elementDimensions / 2)),
						cursor: 'pointer',
						zIndex: 1,
						transform: "translate3d(0, 0, 0)",
					}}
				/>
				<Menu>
					{Object.keys(availableItems).map((avItemsCat) => (
						<React.Fragment key={avItemsCat}>
							<Button
								key={`${avItemsCat}_int`}
								color="secondary"
								variant="contained"
								style={{
									width: "100%",
									// marginBottom: "10px",
									// marginTop: "10px",
								}}
								id={avItemsCat}
								size="small"
								onClick={() => { this.changeCategoryState(avItemsCat); }}
							>
								{availableItems[avItemsCat].name}
							</Button>
							<Collapse key={`${avItemsCat}_collapse`} isOpen>
								<CategoryDiv style={{ background: 'transparent' }}>
									{Object.keys(availableItems[avItemsCat].components).map((avItem) => (
										<React.Fragment key={`${avItemsCat}_${avItem}_fragment`}>
											<Draggable
												allowAnyClick={false}
												position={{ x: 0, y: 0 }}
												onStart={this.onStart}
												onDrag={(event) => { this.onDrag(event, avItemsCat, avItem); }}
												onStop={this.onStop}
											>
												<DragComp style={{ display: (dragging === avItem && draggingCat === avItemsCat) ? 'none' : 'flex' }}>
													<Tooltip
														arrow
														title={(
															<div style={{
																display: (dragging) ? 'none' : 'flex', flexDirection: 'column', textAlign: 'center', maxWidth: '250px', overflow: 'auto',
															}}
															>
																<div style={{ fontWeight: 'bold', fontSize: '18px' }}>
																	{availableItems[avItemsCat].components[avItem].header}
																</div>
																<div style={{ textAlign: 'left', fontSize: '14px' }}>
																	{availableItems[avItemsCat].components[avItem].text}
																</div>
															</div>
														)}
														placement="right"
													>
														<img src={availableItems[avItemsCat].components[avItem].icon} alt={avItem} draggable={false} style={{ maxWidth: '100%', maxHeight: '100%' }} />
													</Tooltip>
												</DragComp>
											</Draggable>
											<EmptyComp style={{ display: (dragging !== avItem || draggingCat !== avItemsCat) ? 'none' : 'flex' }} />
										</React.Fragment>
									))}
								</CategoryDiv>
							</Collapse>
						</React.Fragment>
					))}
					<Button
						color="error"
						variant="contained"
						style={{
							width: "100%",
						}}
						size="small"
						onClick={this.clearItems}
					>
						{"Clear all"}
					</Button>
				</Menu>
			</MainBox>,
		]);
	}
}

export default SetSensorsObstacles;

/* eslint-disable max-len */
import ambientLightIcon from '../../../../assets/images/missionItems/sensors/ambientLight.png';
import areaAlarmIcon from '../../../../assets/images/missionItems/sensors/areaAlarm.png';
import cameraIcon from '../../../../assets/images/missionItems/sensors/camera.png';
import distanceIcon from '../../../../assets/images/missionItems/sensors/distance.png';
import gasIcon from '../../../../assets/images/missionItems/sensors/gas.png';
import humidityIcon from '../../../../assets/images/missionItems/sensors/humidity.png';
import linearAlarmIcon from '../../../../assets/images/missionItems/sensors/alarm.png';
import microphoneIcon from '../../../../assets/images/missionItems/sensors/microphone.png';
import phIcon from '../../../../assets/images/missionItems/sensors/ph.png';
import temperatureIcon from '../../../../assets/images/missionItems/sensors/temperature.png';
import createAmbientLight from './ambient-light';
import createAreaAlarm from './area-alarm';
import createCamera from './camera';
import createDistance from './distance';
import createGas from './gas';
import createHumidity from './humidity';
import createLinearAlarm from './linear-alarm';
import createMicrophone from './microphone';
import createPH from './ph';
import createTemperature from './temperature';

const sensors = {
	name: 'Sensors',
	components: {
		ambientLight: {
			icon: ambientLightIcon,
			component: createAmbientLight,
			props: {
				hz: 3,
				mode: 'mock',
				selectedOperation: 'sinus',
				operationParameters: {
					constant: { value: 60 },
					random: {
						min: 30,
						max: 70,
					},
					triangle: {
						min: 30,
						max: 70,
						step: 0.01,
					},
					normal: {
						mean: 60,
						std: 5,
					},
					sinus: {
						dc: 60,
						amplitude: 10,
						step: 0.01,
					},
				},
			},
			header: 'Ambient Light',
			text: 'An ambient light sensor measures the amount of ambient light (luminosity) of its surrounding area',
		},
		areaAlarm: {
			icon: areaAlarmIcon,
			component: createAreaAlarm,
			props: {
				hz: 3,
				range: 3,
				mode: 'simulation',
			},
			header: 'Area Alarm',
			text: 'An area alarm detects robots that go through the sensors radius',
		},
		// camera: {
		// 	icon: cameraIcon,
		// 	component: createCamera,
		// 	props: {
		// 		theta: 0,
		// 		hz: 1,
		// 		fov: 60,
		// 		range: 20,
		// 		width: 640,
		// 		height: 480,
		// 		mode: 'simulation',
		// 		onPanTilt: 0,
		// 		panTiltLimits: {
		// 			pan: {
		// 				min: -90,
		// 				max: 90
		// 			},
		// 			tilt: {
		// 				min: -90,
		// 				max: 90
		// 			}
		// 		},
		// 		panTiltMode: 'mock',
		// 		panTiltSelectedOperation: 'sinus',
		// 		panTiltOperationParameters: {
		// 			sinus: {
		// 				hz: 3,
		// 				step: 0.01
		// 			}
		// 		}
		// 	},
		// 	header: 'Camera',
		// 	text: 'A camera captures an image defined by its field of view and range, which can be used to perform various detections (human, QR etc.)'
		// },
		distance: {
			icon: distanceIcon,
			component: createDistance,
			props: {
				theta: 0,
				hz: 3,
				range: 20,
				mode: 'simulation',
				selectedOperation: 'sinus',
				operationParameters: {
					constant: { value: 1 },
					random: {
						min: 0,
						max: 10,
					},
					triangle: {
						min: 0,
						max: 10,
						step: 0.01,
					},
					normal: {
						mean: 1,
						std: 0.05,
					},
					sinus: {
						dc: 1,
						amplitude: 0.5,
						step: 0.01,
					},
				},
				onPanTilt: 0,
				panTiltLimits: {
					pan: {
						min: -90,
						max: 90,
					},
					tilt: {
						min: -90,
						max: 90,
					},
				},
				panTiltMode: 'mock',
				panTiltSelectedOperation: 'sinus',
				panTiltOperationParameters: {
					sinus: {
						hz: 3,
						step: 0.01,
					},
				},
			},
			header: 'Distance',
			text: 'A distance sensor measures the distance of the closest item up until its nominal range',
		},
		gas: {
			icon: gasIcon,
			component: createGas,
			props: {
				hz: 3,
				mode: 'simulation',
				selectedOperation: 'sinus',
				operationParameters: {
					constant: { value: 400 },
					random: {
						min: 300,
						max: 500,
					},
					triangle: {
						min: 300,
						max: 500,
						step: 0.01,
					},
					normal: {
						mean: 400,
						std: 10,
					},
					sinus: {
						dc: 400,
						amplitude: 50,
						step: 0.01,
					},
				},
			},
			header: 'Gas',
			text: 'A gas sensor detects the presence of CO2 in its surrounding area',
		},
		humidity: {
			icon: humidityIcon,
			component: createHumidity,
			props: {
				hz: 3,
				mode: 'simulation',
				selectedOperation: 'sinus',
				operationParameters: {
					constant: { value: 60 },
					random: {
						min: 40,
						max: 80,
					},
					triangle: {
						min: 40,
						max: 80,
						step: 0.01,
					},
					normal: {
						mean: 60,
						std: 0.05,
					},
					sinus: {
						dc: 60,
						amplitude: 10,
						step: 0.01,
					},
				},
			},
			header: 'Humidity',
			text: 'A humidity sensor measures the humidity level in its surrounding area',
		},
		linearAlarm: {
			icon: linearAlarmIcon,
			component: createLinearAlarm,
			props: {
				hz: 3,
				mode: 'simulation',
			},
			header: 'Linear Alarm',
			text: 'A linear alarm sensor detects robots that go through the sensor’s line',
		},
		// microphone: {
		// 	icon: microphoneIcon,
		// 	component: createMicrophone,
		// 	props: { mode: 'simulation' },
		// 	header: 'Microphone',
		// 	text: 'A microphone records any voice or sound present in the area it is placed',
		// },
		ph: {
			icon: phIcon,
			component: createPH,
			props: {
				hz: 3,
				mode: 'simulation',
				selectedOperation: 'sinus',
				operationParameters: {
					constant: { value: 7 },
					random: {
						min: 3,
						max: 10,
					},
					triangle: {
						min: 3,
						max: 10,
						step: 0.01,
					},
					normal: {
						mean: 7,
						std: 0.1,
					},
					sinus: {
						dc: 7,
						amplitude: 0.5,
						step: 0.01,
					},
				},
			},
			header: 'PH',
			text: 'A pH sensor measures the pH scale in the area it is placed',
		},
		temperature: {
			icon: temperatureIcon,
			component: createTemperature,
			props: {
				hz: 3,
				mode: 'simulation',
				selectedOperation: 'sinus',
				operationParameters: {
					constant: { value: 22 },
					random: {
						min: 20,
						max: 25,
					},
					triangle: {
						min: 10,
						max: 30,
						step: 0.01,
					},
					normal: {
						mean: 22,
						std: 5,
					},
					sinus: {
						dc: 22,
						amplitude: 0.5,
						step: 0.01,
					},
				},
			},
			header: 'Temperature',
			text: 'A temperature sensor measures the ambient temperature of its surrounding area',
		},
	},
};

export default sensors;

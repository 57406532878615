import blue1 from '../../../assets/images/background/blueprint/1.png';
import blue2 from '../../../assets/images/background/blueprint/2.png';
import blue3 from '../../../assets/images/background/blueprint/3.png';
import blue4 from '../../../assets/images/background/blueprint/4.png';
import blue5 from '../../../assets/images/background/blueprint/5.png';
import blue6 from '../../../assets/images/background/blueprint/6.png';
import blue7 from '../../../assets/images/background/blueprint/7.png';
import blue8 from '../../../assets/images/background/blueprint/8.png';
import blue9 from '../../../assets/images/background/blueprint/9.png';
import blue10 from '../../../assets/images/background/blueprint/10.png';

const blueprint = {
	name: 'Blueprint',
	components: {
		blue_1: {
			id: 'blue_1',
			image: blue1,
			text: 'Blueprint tile 1',
			walls: [],
			waters: [],
		},
		blue_2: {
			id: 'blue_2',
			image: blue2,
			text: 'Blueprint tile 2',
			walls: [],
			waters: [],
		},
		blue_3: {
			id: 'blue_3',
			image: blue3,
			text: 'Blueprint tile 3',
			walls: [],
			waters: [],
		},
		blue_4: {
			id: 'blue_4',
			image: blue4,
			text: 'Blueprint tile 4',
			walls: [],
			waters: [],
		},
		blue_5: {
			id: 'blue_5',
			image: blue5,
			text: 'Blueprint tile 5',
			walls: [],
			waters: [],
		},
		blue_6: {
			id: 'blue_6',
			image: blue6,
			text: 'Blueprint tile 6',
			walls: [],
			waters: [],
		},
		blue_7: {
			id: 'blue_7',
			image: blue7,
			text: 'Blueprint tile 7',
			walls: [],
			waters: [],
		},
		blue_8: {
			id: 'blue_8',
			image: blue8,
			text: 'Blueprint tile 8',
			walls: [],
			waters: [],
		},
		blue_9: {
			id: 'blue_9',
			image: blue9,
			text: 'Blueprint tile 9',
			walls: [],
			waters: [],
		},
		blue_10: {
			id: 'blue_10',
			image: blue10,
			text: 'Blueprint tile 10',
			walls: [],
			waters: [],
		},
	},
};

export default blueprint;

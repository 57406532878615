export const EntityExample = `entities
	Entity entity_name
	example1,
	example2,
	example3
	end
end
`;

export const SynonymExample = `synonyms
	Synonym synonym_name
	synonym1,
	synonym2,
	synonym3
	end
end
`;

export const ExternalServiceExample = `eservices
	EServiceHTTP service_name
	verb: GET
	host: 'localhost'
	port: 8080
	path: '/api/path'
	end
end
`;

export const GlobalSlotExample = `gslots
	slotA: int = 0,
	slotB: str = ""
end
`;

export const IntentExample = `triggers
	Intent intent_name
	"example text",
	"text with trainable entity" TE:name,
	"text with trainable entity" TE:name "!",
	TE:name "!",
	"text with pretrained entity" PE:PERSON "!",
	"pretrained entity with pretrained entity" PE:PERSON["John"]
	end
end
`;

export const EventExample = `triggers
	Event event_name
	"/uri"
	end
end
`;

export const DialogueExample = `dialogues
	Dialogue dialogue_name
	on: intent_name
	responses:
		Form form_name
			slot1: str = HRI('What to ask the user to fill the slot')
			slot2: str = HRI('What to ask the user to fill the slot', [PE:PERSON])
			slot3: str = service_name(header=[param1=""],)[response.object.value] //dynamic params defined here (header, query, body, path)
		end,
		ActionGroup answers
			Speak('Hello' form_name.slot1 'how are you')
			SetFSlot(form_name.slot2, "")
			SetFSlot(slotA, 5)
			FireEvent('/uri', "value")
			service_name(header=[param1=""],) //dynamic params defined here (header, query, body, path)
		end
	end
end
`;

export const ConnectorExample = `connectors
	Connector connector_name //slack or telegram
	token: "xoxb-XXX"
	channel: "your-channel"
	signing_secret: "YYY"
	end
end
`;

export const AccessControlExample = `access_controls
	Roles
	role1,
	role2

	default: 
		role2
	end

	Users
	role1:
		role1@email.com

	role2: 
		role2@email.com
	end

	Policy all_actions_policy
	on: 
		all_actions
	roles: 
		role1
	end

	Path
	"/user/roles/policies/path.txt"
	end

	Authentication
	method: user_id //slot or user_id or slack or telegram
	end
end
`;

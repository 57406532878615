// import { shortDescription } from "./envmaker/short-description.js";
// import { getDocumentation } from "./envmaker/documentation.js";
import ToolboxerLight from "../assets/dsls-logos/toolboxer_L.png";
import ToolboxerDark from "../assets/dsls-logos/toolboxer_D.png";

/**
 * Retrieves the constants for the ToolBoxer DSL.
 * @returns {Promise<Object>} The constants object.
 */
export const getToolBoxerConstants = async () => {
	const constants = {
		enabled: false,
		_id: "toolboxer",
		name: "ToolBoxer",
		short: "toolboxer",
		description: "Tool for creating toolboxes for AppCreator.",
		logoUrl: ToolboxerLight,
		logoUrlDark: ToolboxerDark,
		insertableEntities: {},
		shortDescription: "Tool for creating toolboxes for AppCreator.",
		documentation: "Tool for creating toolboxes for AppCreator.",
		subtypes: [],
		hasValidation: true,
		type: "Textual",
		canTransformTo: [],
		mustImport: [],
		canImport: [],
		supportsGeneration: true,
		generationLanguage: "",
		supportsDeployment: false,
		generations: ["Default"],
		generationsOutputMap: {
			Default: "string",
		},
		keywords: [],
		llmSupport: false,
	};
	return constants;
};

import { memo, useCallback, useState, useMemo, useRef, useEffect } from "react";

import { useSelector, useDispatch } from 'react-redux';
import { addBroker, setBrokers } from "../components/AppCreator/slice-brokers.js";
import { addVariable, removeVariable, clearVariables, setVariables, updateVariableValue } from "../components/AppCreator/slice-storage-variables.js";
import { addThread, deleteThread, setThreads } from "../components/AppCreator/slice-threads.js";
import { addNodeParameters, deleteNodeParameters, setNodesParameters } from "../components/AppCreator/slice-nodes.js";
import { addLog, cleanLogs } from "../components/AppCreator/slice-logs.js";

import {
	Chip,
	Grid,
	Typography,
} from "@mui/material";

import ReactFlow, {
	MiniMap,
	Controls,
	Background,
	useNodesState,
	useEdgesState,
	addEdge,
	applyEdgeChanges,
	applyNodeChanges,
	ReactFlowProvider,
	useReactFlow,
} from 'reactflow';

import 'reactflow/dist/style.css';

import Spinner from "../components/Spinner.js";
// import { useSnackbar } from "../utils/index.js";

import { systemToolboxes } from "../utils/system-toolboxes.js";
import CustomNode from "../components/AppCreator/node-custom.js";
import ThreadNode from "../components/AppCreator/node-thread.js";
import ConditionNode from "../components/AppCreator/node-condition.js";
import RandomNode from "../components/AppCreator/node-random.js";

const {
	REACT_APP_WEBSOCKET_URL,
} = process.env;

const nodeTypes = {
	custom: CustomNode,
	thread: ThreadNode,
	condition: ConditionNode,
	random: RandomNode,
};

const Testbed = (props) => {
	// const { error, success } = useSnackbar();
	const isLoading = useMemo(() => false, []);

	const [nodes, setNodes] = useNodesState([]);
	const [edges, setEdges] = useEdgesState([]);
	// const { setViewport } = useReactFlow();
	const reactFlowWrapper = useRef(null);
	const [reactFlowInstance, setReactFlowInstance] = useState(null);

	const toolboxes = useMemo(() => systemToolboxes, []);
	const [counter, setCounter] = useState(0);
	const modelUpdate = props.modelUpdate;
	const dispatch = useDispatch();

	// Get all variables from redux
	const storeVariables = useSelector((state) => state.storageVariables);
	const storeThreads = useSelector((state) => state.threads);
	const storeBrokers = useSelector((state) => state.brokers);
	const storeNodes = useSelector((state) => state.nodes);

	const [initialModelLoaded, setInitialModelLoaded] = useState(false);

	const prevModel = useRef(props.model);

	const [programRunning, setProgramRunning] = useState(true);
	const [lastExecutionTimestamp, setLastExecutionTimestamp] = useState(0);
	const [runningNodes, setRunningNodes] = useState([]);

	const fixNodesValues = (_nodes, _store) => {
		const nds = _nodes.map((node) => {
			const foundNode = _store.storeNodes.find((n) => n.id === node.id);
			if (foundNode) {
				node.data.parameters = "parameters" in foundNode.parameters ? foundNode.parameters.parameters : foundNode.parameters;
				if ("inputs" in foundNode.parameters) {
					node.data.inputs = foundNode.parameters.inputs;
				}

				if ("outputs" in foundNode.parameters) {
					node.data.outputs = foundNode.parameters.outputs;
				}
			}

			return node;
		});
		return nds;
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const updateModel = useCallback(() => {
		if (reactFlowInstance === null || programRunning) {
			return;
		}

		const toStore = reactFlowInstance.toObject();
		toStore.store = {
			storeVariables,
			storeThreads,
			storeBrokers,
			storeNodes,
		};

		// console.log("Saving model to DB:", toStore);
		modelUpdate(JSON.stringify(toStore));
	});

	useEffect(() => {
		// console.log(">> programRunning changed!", programRunning);
		setNodes((nds) => nds.map((node) => {
			node.style = {
				...node.style,
				opacity: programRunning ? 0.3 : 1,
			};
			return node;
		}));
	}, [programRunning]);

	useEffect(() => {
		// console.log(">> runningNodes changed!", runningNodes);
		setNodes((nds) => nds.map((node) => {
			node.style = {
				...node.style,
				opacity: programRunning && runningNodes.includes(node.id) ? 1 : (programRunning ? 0.3 : 1),
			};
			return node;
		}));
	}, [runningNodes]);

	// Used to load the stored model from the props
	useEffect(() => {
		// Second time, where the model is properly fetched
		if (prevModel.current !== props.model && prevModel.current === "" && props.model !== "") {
			const flow = JSON.parse(props.model);
			console.log("Loading model from DB", flow);
			if (flow) {
				// Update all nodes with the respective parameters in the flow.store.storeNodes
				const nds = fixNodesValues(flow.nodes, flow.store);
				console.log("Nodes from db, after updating the parameters", nds);
				flow.nodes = nds;
				setNodes(flow.nodes || []);
				setEdges(flow.edges || []);
				// Give the max counter value of nodes plus one to the counter
				for (const node of flow.nodes) {
					if (node.data.count > counter) {
						setCounter(node.data.count);
					}
				}

				dispatch(setVariables(flow.store.storeVariables || []));
				dispatch(setThreads(flow.store.storeThreads || []));
				dispatch(setBrokers(flow.store.storeBrokers || []));
				dispatch(setNodesParameters(flow.store.storeNodes || []));
				setInitialModelLoaded(true);
			}

			prevModel.current = props.model;
			setProgramRunning(false);
			setLastExecutionTimestamp(Date.now());
		}
	}, [props]);

	useEffect(() => {
		const ws = new WebSocket(REACT_APP_WEBSOCKET_URL);

		ws.addEventListener('open', () => {
			console.log('WebSocket connected');
		});

		ws.onmessage = (event) => {
			const payload = JSON.parse(event.data);
			if (payload.modelId !== props.modelid) {
				return;
			}

			// console.log("Payload received", payload);
			if (payload.label === "Log" && (payload.message !== "start" && payload.message !== "end")) {
				// console.log("Log received", payload.message);
				dispatch(addLog({
					payload: payload.message,
				}));
			}

			if ("program" in payload) {
				console.log("Program received", payload.program);
				// Make the background of all nodes white
				setProgramRunning(payload.program === "start");

				if (payload.program === "start") {
					dispatch(cleanLogs());
				} else {
					const ts = Date.now();
					setLastExecutionTimestamp(ts);
					console.log("Program ended on", new Date(ts).toLocaleString());
				}
			}

			if ("node_id" in payload) {
				// console.log("Node id", payload.message, payload.node_id);
				if (payload.message === "start") {
					// Add the id in the running nodes
					setRunningNodes((prevNodes) => {
						if (prevNodes.includes(payload.node_id)) {
							return prevNodes;
						}

						return [...prevNodes, payload.node_id];
					});
				} else {
					// Remove the id from the running nodes
					setRunningNodes((prevNodes) => prevNodes.filter((id) => id !== payload.node_id));
				}
			}

			if ("type" in payload && payload.type === "storage") {
				// console.log("Storage received", payload);
				const _var = payload.key;
				const _value = payload.value;
				dispatch(updateVariableValue({
					variable: _var,
					value: _value,
				}));
				// error(payload.message);
			}
		};

		ws.addEventListener('close', () => {
			console.log('WebSocket disconnected');
		});

		return () => {
			ws.close();
		};
	}, []);

	useEffect(() => {
		const timeFromLastExecution = Date.now() - lastExecutionTimestamp;
		if (programRunning || timeFromLastExecution < 500) return;
		// console.log("Time from last execution", timeFromLastExecution);
		updateModel();
	}, [storeVariables, storeThreads, storeBrokers, storeNodes, nodes, edges, initialModelLoaded]);

	const onConnect = useCallback(
		async (params) => {
			await setEdges((eds) => addEdge(params, eds));
		},
		[setEdges],
	);

	const onNodesChange = useCallback(
		async (changes) => {
			await setNodes((nds) => applyNodeChanges(changes, nds));
		},
		[setNodes],
	);
	const onEdgesChange = useCallback(
		async (changes) => {
			await setEdges((eds) => applyEdgeChanges(changes, eds));
		},
		[setEdges],
	);

	const onDragStart = (event, nodeType) => {
		event.dataTransfer.setData('application/reactflow', JSON.stringify(nodeType));
		event.dataTransfer.effectAllowed = 'move';
	};

	const onDragOver = useCallback((event) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = 'move';
	}, []);

	const onNodesDelete = useCallback(
		async (nodesToDelete) => {
			console.log("In onNodesDelete");
			console.log("Nodes to delete", nodesToDelete);
			// Remove the node from the storageVariables
			for (const node of nodesToDelete) {
				console.log("Removing variable for node", node);
				dispatch(removeVariable({
					nodeId: node.id,
				}));

				console.log("Removing thread for node", node);
				dispatch(deleteThread({
					id: node.id,
				}));

				console.log("Removing node", node);
				dispatch(deleteNodeParameters({
					id: node.id,
				}));
			}

			await setNodes((nds) => nds.filter((n) => !nodesToDelete.includes(n.id)));
			await setEdges((eds) => eds.filter((e) => !nodesToDelete.includes(e.source) && !nodesToDelete.includes(e.target)));
			// updateModel();
		},
		[dispatch],
	);

	const onDrop = useCallback(
		async (event) => {
			console.log("In onDrop");
			event.preventDefault();

			const node = JSON.parse(event.dataTransfer.getData('application/reactflow'));

			// check if the dropped element is valid
			if (node.type === undefined || !node.type) {
				node.type = "default";
			}

			const position = reactFlowInstance.screenToFlowPosition({
				x: event.clientX,
				y: event.clientY,
			});

			// Types mapping
			const typesMapping = {
				"Thread split": "thread",
				"Thread join": "thread",
				Condition: "condition",
				Random: "random",
			};

			const id = Math.random().toString();
			const newNode = {
				id,
				type: typesMapping[node.name] ?? "custom",
				sourcePosition: 'right',
				targetPosition: 'left',
				position,
				data: {
					id,
					label: node.name,
					inputs: node.inputs,
					outputs: node.outputs,
					backgroundColor: node.backgroundColor,
					toolboxColor: node.toolboxColor,
					fontColor: node.fontColor,
					toolbox: node.toolbox,
					parameters: node.parameters,
					action: node.action,
					count: counter + 1,
				},
			};

			console.log("Node to update the store", node);

			// This happens for nodes with variable inputs/outputs but no parameters
			const toDispatch = {
				id,
				parameters: node.parameters ?? [],
			};
			if (newNode.data.label === "Thread split" || newNode.data.label === "Thread join" || newNode.data.label === "Random") {
				toDispatch.parameters = newNode.data;
			}

			await dispatch(addNodeParameters(toDispatch));

			// Check for variables
			if (node.action && node.action.storage) {
				await dispatch(addVariable({
					nodeId: id,
					variable: node.action.storage,
					data: node,
					count: counter + 1,
					value: undefined,
				}));
			}

			if (newNode.data.label === "Create variable") {
				console.log("Adding variable", node.parameters[0].value, node.parameters[1].value);
				await dispatch(addVariable({
					nodeId: id,
					variable: node.parameters[0].value, // first param is the variable
					data: node,
					count: counter + 1,
					value: node.parameters[1].value, // second param is the value
				}));
			}

			// Increment the counter
			await setCounter((prevCount) => prevCount + 1);

			console.log("New node", newNode);

			// If the node is thread split, update the redux
			if (newNode.data.label === "Thread split") {
				await dispatch(addThread({
					id: newNode.data.id,
					data: newNode.data,
					parameters: node.parameters,
				}));
			}

			await setNodes((nds) => [...nds, newNode]);
		},
		[reactFlowInstance, counter],
	);

	return (
		<>
			<Spinner open={isLoading} />
			<Grid
				container
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				p={1}
			>
				<Grid
					item
					container
					xs={2}
					display="flex"
					alignItems="flex-start"
					justifyContent="center"
					overflow="auto"
					height="calc(100vh - 280px)"
					p={3}
					pt={0}
					sx={{
						border: "1px solid black",
						borderRadius: "20px",
						backgroundColor: "#222",
					}}
				>
					<Chip
						label="Clear all"
						color="warning"
						variant="outlined"
						sx={{
							width: "90%",
							marginTop: "10px",
							cursor: "pointer",
						}}
						onClick={async () => {
							console.log("Clear all");
							await setNodes([]);
							await setEdges([]);
							await dispatch(clearVariables());
							await dispatch(setVariables([]));
							await dispatch(setThreads([]));
							await dispatch(setBrokers([]));
							await dispatch(setNodesParameters([]));
							await dispatch(cleanLogs());
						}}
					/>
					{/* Parse all toolboxes and nodes */}
					{toolboxes.map((toolbox) => (
						<Grid
							key={toolbox.name}
							item
							container
							style={{
								width: "90%",
							}}
							justifyContent="center"
						>
							<Typography
								style={{
									width: "100%",
									color: toolbox.fontColor ?? "black",
									fontSize: "1em",
									fontWeight: "bold",
									padding: "5px",
									margin: "1px",
									marginTop: "5px",
									borderRadius: "5px",
								}}
							>
								{toolbox.name}
							</Typography>
							{toolbox.nodes.map((node) => (
								<Typography
									key={node.name}
									draggable
									style={{
										width: "100%",
										backgroundColor: toolbox.backgroundColor ?? "#fff",
										toolboxColor: toolbox.backgroundColor ?? "black",
										color: toolbox.fontColor ?? "black",
										border: "1px solid black",
										padding: "5px",
										margin: "1px",
										borderRadius: "5px",
										cursor: "pointer",
										fontSize: "0.9em",
									}}
									textAlign="center"
									onDragStart={(event) => {
										node.toolbox = toolbox.name;
										node.backgroundColor = toolbox.backgroundColor;
										node.toolboxColor = toolbox.backgroundColor;
										node.fontColor = toolbox.fontColor;
										onDragStart(event, node);
									}}
								>
									{node.name}
								</Typography>
							))}
						</Grid>
					))}
				</Grid>
				<Grid
					item
					container
					xs={9.9}
					display="flex"
					direction="row"
					alignItems="center"
					justifyContent="center"
					sx={{
						backgroundColor: "black",
						border: "1px solid black",
						borderRadius: "20px",
					}}
				>
					<ReactFlowProvider>
						<div
							ref={reactFlowWrapper}
							className="reactflow-wrapper"
							style={{
								width: '100%',
								height: "calc(100vh - 280px)",
								// height: "100%",
							}}
						>
							<ReactFlow
								nodes={nodes}
								edges={edges}
								nodeTypes={nodeTypes}
								onNodesChange={onNodesChange}
								onNodesDelete={onNodesDelete}
								onEdgesChange={onEdgesChange}
								onConnect={onConnect}
								onInit={setReactFlowInstance}
								onDrop={onDrop}
								onDragOver={onDragOver}
							>
								<Controls />
								{/* <MiniMap zoomable pannable style={{ height: 100 }} /> */}
								<Background variant="dots" gap={12} size={1} />
							</ReactFlow>
						</div>
					</ReactFlowProvider>
				</Grid>

			</Grid>
		</>
	);
};

export default memo(Testbed);

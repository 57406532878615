import city from './city';
import farm from './farm';
import blueprint from './blueprint';
import home from './home';
import superMarket from './super-market';

const categories = {
	home,
	blueprint,
	city,
	farm,
	superMarket,
};

export default categories;

import general from './general';
import sensors from './sensors';
import effectors from './effectors';

const availableItems = {
	general,
	sensors,
	effectors,
};

export default availableItems;

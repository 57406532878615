import { Typography, Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

const Unauthorized = () => (
	<div style={{ padding: "3rem 1.5rem" }}>
		<div className="container">
			<Typography gutterBottom variant="h4" sx={{ my: 2 }} component="h1">{"401, Unauthorized!"}</Typography>
			<img
				src="https://media.tenor.com/IS74NONBpy4AAAAd/abraxas-lotr.gif"
				alt=""
			/>
			<Typography gutterBottom variant="h6" component="p" sx={{ my: 2 }}>
				{"The page you requested has information that is not for your eyes!"}
			</Typography>
			<Button size="small" startIcon={<ArrowBack />} variant="outlined" onClick={() => { window.location.href = "/"; }}>
				{"Go Back"}
			</Button>
		</div>
	</div>
);

export default Unauthorized;

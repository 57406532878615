import { ViewPlugin, Decoration } from '@codemirror/view';
import { getDslKeywords } from '../dsls';

let customStyles = [];

export const customHighlighter = ViewPlugin.fromClass(class {
	decorations;

	constructor(view) {
		this.decorations = this.highlight(view);
	}

	update(update) {
		if (update.docChanged || update.viewportChanged) {
			this.decorations = this.highlight(update.view);
		}
	}

	generateStyleClass(style) {
		const className = `cm-custom-${Object.entries(style).map(([key, value]) => `${key}-${value}`).join('-').replaceAll(/\s+/g, '-')}`;

		// eslint-disable-next-line unicorn/prefer-query-selector
		if (!document.getElementById(className)) {
			const styleElement = document.createElement('style');
			styleElement.id = className;
			styleElement.innerHTML = `.${className} { ${Object.entries(style).map(([key, value]) => `${key}: ${value}`).join('; ')} }`;
			document.head.append(styleElement);
		}

		return className;
	}

	highlight(view) {
		const decorations = [];

		for (const customStyle of customStyles) {
			const regex = new RegExp(`\\b(${customStyle.keywords.join('|')})\\b`, 'g');
			const styleClass = this.generateStyleClass(customStyle.style);

			for (const { from, to } of view.visibleRanges) {
				const text = view.state.doc.sliceString(from, to);
				let match = regex.exec(text);
				while (match) {
					const start = from + match.index;
					const end = start + match[0].length;
					decorations.push(Decoration.mark({ class: styleClass }).range(start, end));
					match = regex.exec(text);
				}
			}
		}

		return Decoration.set(decorations);
	}
}, {
	decorations: (v) => v.decorations,
});

export const setCustomViews = async (language) => {
	const keywords = await getDslKeywords(language);
	customStyles = [
		{
			keywords,
			style: { color: "green", fontWeight: "bold" },
		},
	];
};

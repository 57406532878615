export const MQTTExample = `Broker<MQTT> BROKER_NAME // change this
	host: "" // fill this
	port: 0000 // change this
	auth:
		username: "" // fill this
		password: "" // fill this
end
`;

export const EntitySensorExample = `Entity SENSOR_NAME // change this
	type: sensor
	freq: 0 // change this
	topic: "" // fill this
	broker: default_broker // change this with the name of your broker
	attributes:
		- attr1: type1 // change this
end
`;

export const EntityActuatorExample = `Entity ACTUATOR_NAME // change this
	type: actuator
	topic: "" // fill this
	broker: default_broker // change this with the name of your broker
	attributes:
		- attr1: type1 // change this
end
`;

export const AutomationExample = `Automation AUTOMATION_NAME // change this
	condition:
		CONDITION_HERE // change this
	enabled: true // change this if needed
	continuous: false // change this if needed
	actions:
		- ACTION_HERE // change this
end
`;

export const RTMonirotExample = `RTMonitor
	broker: default_broker // change this with the name of your broker
	namespace: "" // the topic namespace
	eventTopic: "event"
	logsTopic: "logs"
end
`;

export const MetadataExample = `Metadata
	name: A_NAME // change this
	version: "0.0.0" // change this
	description: "" // fill this
	author: "the author" // change this
	email: "the.author@gmail.com" // change this
end
`;

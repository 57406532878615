import { getDocumentation } from "./codintxt/documentation.js";
import {
	MetadataExample,
	BrokerExample,
	GaugeExample,
	ValueDisplayExample,
	JsonViewerExample,
	AliveExample,
	LogsExample,
	ButtonExample,
	ButtonGroupExample,
	PlotExample,
	PlotViewExample,
} from "./codintxt/insertables.js";

// eslint-disable-next-line camelcase
import CodinTxtLight from "../assets/dsls-logos/codintxt_L.png";
import CodinTxtDark from "../assets/dsls-logos/codintxt_D.png";

/**
 * Retrieves the CodinTxt constants.
 * @returns {Promise<Object>} The CodinTxt constants.
 */
export const getCodinConstants = async () => {
	const constants = {
		enabled: true,
		_id: "codintxt",
		name: "CodinTxt",
		short: "codintxt",
		description: "Tool for creating dashboards for IoT/CPS systems",
		// eslint-disable-next-line camelcase
		logoUrl: CodinTxtLight,
		logoUrlDark: CodinTxtDark,
		insertableEntities: {
			Metadata: MetadataExample,
			Broker: BrokerExample,
			Gauge: GaugeExample,
			ValueDisplay: ValueDisplayExample,
			JsonViewer: JsonViewerExample,
			Alive: AliveExample,
			Logs: LogsExample,
			Button: ButtonExample,
			ButtonGroup: ButtonGroupExample,
			Plot: PlotExample,
			PlotView: PlotViewExample,
		},
		shortDescription: await getDocumentation(),
		documentation: await getDocumentation(),
		subtypes: [],
		hasValidation: true,
		type: "Textual",
		canTransformTo: [],
		mustImport: [],
		canImport: [],
		supportsGeneration: false,
		generationLanguage: "",
		generations: ["Default"],
		generationsOutputMap: {
			Default: "string",
		},
		supportsDeployment: true,
		keywords: [
			"Broker",
			"Metadata",
			"Gauge",
			"LogsDisplay",
			"ValueDisplay",
			"AliveDisplay",
			"JsonViewer",
			"Plot",
			"PlotView",
			"Button",
			"ButtonGroup",
			"end",
		],
		llmSupport: true,
	};
	return constants;
};

import {
	Typography,
	Grid,
} from "@mui/material";

import { memo, useState } from "react";

import ReactFlow, {
	Position,
	Handle,
} from 'reactflow';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import CustomHandle from "./custom-node-handle.js";

import { useDispatch } from 'react-redux';
import { updateThread } from "./slice-threads.js";
import { updateNodeParameters } from "./slice-nodes.js";

// Create a custom node based on inputs
const ThreadNode = memo(({ data, isConnectable }) => {
	const [outputs, setOutputs] = useState(data.outputs);
	const [inputs, setInputs] = useState(data.inputs);
	const [internalData, setInternalData] = useState(data);
	const dispatch = useDispatch();

	return (
		<Grid
			style={{
				background: data.backgroundColor ?? '#fff',
				border: '1px solid #000',
				borderRadius: '10px',
				padding: '10px',
				paddingRight: '30px',
				paddingLeft: '30px',
				paddingTop: `${((outputs > inputs ? outputs : inputs) + 1) * 7}px`,
				paddingBottom: `${((outputs > inputs ? outputs : inputs) + 1) * 7}px`,
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			}}
		>
			{/* Iterate on data.inputs */}
			{Array.from({ length: inputs ?? 1 }, (_, i) => (
				<Handle
					key={Math.random().toString()}
					id={`in_${i.toString()}`}
					type="target"
					isConnectable={100}
					position={Position.Left}
					// Place them in different positions
					style={{
						top: `calc(${(i + 1) * (100 / (inputs + 1))}%)`,
						left: "-30px",
						background: '#0a0',
						width: '15px',
						height: '15px',
					}}
					onConnect={(params) => console.log('handle onConnect', params)}
				>
					{data.label === "Thread join" && (
						<Typography
							sx={{
								position: "relative",
								fontSize: "0.6em",
								color: data.fontColor,
								left: "18px",
							}}
						>
							{`${i}`}
						</Typography>
					)}
				</Handle>
			))}
			<Grid
				item
				container
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				sx={{
					width: "100%",
					height: "100%",
				}}
			>
				<Grid
					item
					container
					display="flex"
					flexDirection="row"
					justifyContent="center"
					alignItems="center"
				>
					<Typography
						px={1}
						sx={{
							borderRadius: "50px",
							marginRight: "10px",
							backgroundColor: data.fontColor,
							color: data.backgroundColor,
							fontSize: "0.8em",
							fontWeight: "bold",
						}}
					>
						{data.count}
					</Typography>
					<Typography
						sx={{
							fontSize: "1.2em",
							color: data.fontColor,
							marginRight: "10px",
						}}
					>
						{data.label}
					</Typography>
				</Grid>
				<AddCircleIcon
					sx={{
						color: data.fontColor,
						fontSize: "1em",
						marginRight: "5px",
						position: "absolute",
						right: "0.5px",
						top: "5px",
					}}
					onClick={async () => {
						if (data.label === "Thread split") {
							await setOutputs(outputs + 1);
							await dispatch(updateThread({
								id: data.id,
								count: data.count,
								outputs: outputs + 1,
							}));
							// Update the parameters
							await setInternalData({
								...internalData,
								outputs: outputs + 1,
							});
							await dispatch(updateNodeParameters({
								id: data.id,
								parameters: {
									...internalData,
									outputs: outputs + 1,
								},
							}));
						} else if (data.label === "Thread join") {
							await setInputs(inputs + 1);
							// Update the parameters
							await setInternalData({
								...internalData,
								inputs: inputs + 1,
							});
							await dispatch(updateNodeParameters({
								id: data.id,
								parameters: {
									...internalData,
									inputs: inputs + 1,
								},
							}));
						}
					}}
				/>
				<RemoveCircleIcon
					sx={{
						color: data.fontColor,
						fontSize: "1em",
						marginRight: "5px",
						position: "absolute",
						right: "0.5px",
						top: "25px",
					}}
					onClick={async () => {
						if (data.label === "Thread split" && outputs > 1) {
							await setOutputs(outputs - 1);
							await dispatch(updateThread({
								id: data.id,
								count: data.count,
								outputs: outputs - 1,
							}));
							// Update the parameters
							await setInternalData({
								...internalData,
								outputs: outputs - 1,
							});
							await dispatch(updateNodeParameters({
								id: data.id,
								parameters: {
									...internalData,
									outputs: outputs - 1,
								},
							}));
						} else if (data.label === "Thread join" && inputs > 1) {
							await setInputs(inputs - 1);
							// Update the parameters
							await setInternalData({
								...internalData,
								inputs: inputs - 1,
							});
							await dispatch(updateNodeParameters({
								id: data.id,
								parameters: {
									...internalData,
									inputs: inputs - 1,
								},
							}));
						}
					}}
				/>
			</Grid>
			{Array.from({ length: outputs ?? 1 }, (_, i) => (
				<Handle
					key={Math.random().toString()}
					type="source"
					position={Position.Right}
					id={`out_${i.toString()}`}
					isConnectable={100}
					style={{
						top: `calc(${(i + 1) * (100 / (outputs + 1))}%)`,
						right: "-30px",
						background: '#a00',
						width: '15px',
						height: '15px',
					}}
					onConnect={(params) => console.log('handle onConnect', params)}
				>
					{data.label === "Thread split" && (
						<Typography
							sx={{
								position: "relative",
								fontSize: "0.6em",
								color: data.fontColor,
								left: "-11px",
							}}
						>
							{`${i}`}
						</Typography>
					)}
				</Handle>
			))}
		</Grid>
	);
});

export default ThreadNode;

/* eslint-disable no-unused-vars */

/* eslint-disable max-len */

import {
	EditableText, Menu, NumericInput, Popover, Tooltip,
} from '@blueprintjs/core';
import { faTrash, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';
import { ToasterBottom } from '../../../lib_socioty/toaster';
import { GreenBorderButton } from '../../../lib_socioty/buttons';
import ph from '../../../../assets/images/missionItems/sensors/ph.png';

import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const PrettoSlider = styled(Slider)({
	color: '#52af77',
	height: 8,
	'& .MuiSlider-track': {
		border: 'none',
	},
	'& .MuiSlider-thumb': {
		height: 24,
		width: 24,
		backgroundColor: '#fff',
		border: '2px solid currentColor',
		'&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
			boxShadow: 'inherit',
		},
		'&:before': {
			display: 'none',
		},
	},
});

const parameters = {
	constant: {
		value: {
			min: 0,
			max: Number.POSITIVE_INFINITY,
			stepSize: 0.1,
			minorStepSize: 0.01,
			majorStepSize: 1,
		},
	},
	random: {
		min: {
			min: 0,
			max: 'max',
			stepSize: 0.1,
			minorStepSize: 0.01,
			majorStepSize: 1,
		},
		max: {
			min: 'min',
			max: Number.POSITIVE_INFINITY,
			stepSize: 0.1,
			minorStepSize: 0.01,
			majorStepSize: 1,
		},
	},
	triangle: {
		min: {
			min: 0,
			max: 'max',
			stepSize: 0.1,
			minorStepSize: 0.01,
			majorStepSize: 1,
		},
		max: {
			min: 'min',
			max: Number.POSITIVE_INFINITY,
			stepSize: 0.1,
			minorStepSize: 0.01,
			majorStepSize: 1,
		},
		step: {
			min: 0,
			max: Number.POSITIVE_INFINITY,
			stepSize: 0.01,
			minorStepSize: 0.001,
			majorStepSize: 0.1,
		},
	},
	normal: {
		mean: {
			min: 0,
			max: Number.POSITIVE_INFINITY,
			stepSize: 0.01,
			minorStepSize: 0.001,
			majorStepSize: 0.1,
		},
		std: {
			min: 0,
			max: Number.POSITIVE_INFINITY,
			stepSize: 0.01,
			minorStepSize: 0.001,
			majorStepSize: 0.1,
		},
	},
	sinus: {
		dc: {
			min: 0,
			max: Number.POSITIVE_INFINITY,
			stepSize: 0.01,
			minorStepSize: 0.001,
			majorStepSize: 0.1,
		},
		amplitude: {
			min: 0,
			max: Number.POSITIVE_INFINITY,
			stepSize: 0.01,
			minorStepSize: 0.001,
			majorStepSize: 0.1,
		},
		step: {
			min: 0,
			max: Number.POSITIVE_INFINITY,
			stepSize: 0.01,
			minorStepSize: 0.001,
			majorStepSize: 0.1,
		},
	},
};

const ItemDiv = styled.div`
	width: ${(props) => `${props.elementDimensions}px`};
	height: ${(props) => `${props.elementDimensions}px`};
	border-radius: ${(props) => `${props.elementDimensions}px`};
	position: absolute;
	left: ${(props) => `calc(${props.x}% - ${(props.elementDimensions / 2)}px)`};
	bottom: ${(props) => `calc(${props.y}% - ${(props.elementDimensions / 2)}px)`};
	cursor: ${(props) => ((props.dragging) ? 'move' : 'pointer')};
	display: flex;
	transform: ${(props) => (props.dragging ? '' : 'none!important')};
`;

const BasicPopover = ({ className, elementDimensions, popoverOpen, closePopover, changeName, checkName, itemName, changeHz, hz, selectedOperation, operationParameters, changeOperation, changeParameter, mode, changeMode, deleteItem }) => (
	<Popover usePortal popoverClassName={`${className} item-popover`} elementDimensions={elementDimensions} isOpen={popoverOpen}>
		<div style={{
			width: '300px', padding: '10px', color: 'white', background: 'black', boxShadow: '4px 4px 10px 1px #333333',
		}}
		>
			{/* Name */}
			<div style={{
				width: '100%', maxWidth: '100%', paddingLeft: '1px', paddingRight: '1px', display: 'flex', justifyContent: 'space-around',
			}}
			>
				<TextField
					value={itemName}
					label="Name"
					variant="filled"
					sx={{
						backgroundColor: '#aaa',
						mx: 1,
						width: '100%',
					}}
					size="small"
					color="success"
					onChange={(event) => changeName(event.target.value)}
				/>
			</div>

			{/* Hz */}
			<div style={{
				width: '100%', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "10px",
			}}
			>
				<Typography color="#ffffff" variant="caption" mr={1}>
					{`Hz (${hz})`}
				</Typography>
				<PrettoSlider
					aria-label="Always visible"
					value={hz}
					color="secondary"
					min={0}
					max={10}
					step={1}
					sx={{
						width: "150px",
					}}
					onChange={(event) => changeHz(event.target.value)}
				/>
			</div>

			{/* Mode */}
			<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
				<FormControl error sx={{ m: 1, width: "100%" }}>
					<Select
						value={mode}
						label="Mode"
						sx={{
							backgroundColor: 'gray',
						}}
						size="small"
					>
						{['mock', 'simulation'].map((m) => (
							<MenuItem key={Math.random()} value={m} onClick={() => changeMode(m)}>
								{`Mode: ${m.charAt(0).toUpperCase()}${m.slice(1)}`}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
			{/* Parameters */}
			{mode === 'mock'
				&& (
					<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
						<FormControl error sx={{ m: 1, width: "100%" }} size="small">
							<Select
								value={selectedOperation}
								label="Data generation"
								sx={{
									backgroundColor: 'gray',
								}}
							>
								{Object.keys(operationParameters).map((operation) => (
									<MenuItem key={Math.random()} value={operation} onClick={() => changeOperation(operation)}>
										{`Data generation: ${operation.charAt(0).toUpperCase()}${operation.slice(1)}`}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				)}
			{mode === 'mock'
				&& (
					<div
						style={{
							width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
						}}
					>
						{Object.keys(parameters[selectedOperation]).map((param) => (
							<div key={param}>
								<div
									style={{
										width: '100%', marginTop: '10px', display: 'flex', alignItems: 'center',
									}}
								>
									{parameters[selectedOperation][param].name || param}
								</div>
								<div style={{
									width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
								}}
								>
									<NumericInput
										clampValueOnBlur
										className="parameter-input"
										min={(typeof parameters[selectedOperation][param].min === 'number') ? parameters[selectedOperation][param].min : operationParameters[selectedOperation][parameters[selectedOperation][param].min]}
										max={(typeof parameters[selectedOperation][param].max === 'number') ? parameters[selectedOperation][param].max : operationParameters[selectedOperation][parameters[selectedOperation][param].max]}
										minorStepSize={parameters[selectedOperation][param].minorStepSize}
										placeholder={param}
										stepSize={parameters[selectedOperation][param].stepSize}
										defaultValue={operationParameters[selectedOperation][param]}
										onValueChange={(value) => changeParameter(value, param)}
									/>
								</div>
							</div>
						))}
					</div>
				)}
			<div style={{
				width: '100%', height: '20px', display: 'flex', justifyContent: 'space-around', marginTop: '10px',
			}}
			>
				<FontAwesomeIcon icon={faTrash} style={{ fontSize: '20px', cursor: 'pointer' }} onClick={deleteItem} />
				<FontAwesomeIcon icon={faXmarkCircle} style={{ fontSize: '20px', cursor: 'pointer' }} onClick={closePopover} />
			</div>
		</div>
	</Popover>
);

const CustomPopover = styled(BasicPopover)`
	transform: ${(props) => `translate(${-(props.elementDimensions / 2)}px, 0px)`};
	border-radius: 10px;
`;

class PH extends React.Component {
	constructor(props) {
		super(props);

		const initialState = props.state;
		this.cat = initialState.cat;
		this.itemCat = initialState.itemCat;
		this.updateItem = props.updateItem;
		this.deleteItem = props.deleteItem;
		this.checkItemNameExists = props.checkItemNameExists;

		this.state = {
			id: initialState.id,
			itemName: initialState.itemName,
			x: initialState.x,
			y: initialState.y,
			hz: initialState.hz,
			mode: initialState.mode,
			selectedOperation: initialState.selectedOperation,
			operationParameters: initialState.operationParameters,
			elementDimensions: props.elementDimensions,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
			dragging: false,
			popoverOpen: false,
			resolution: props.resolution,
			metersPopupOpen: false,
			xMeters: 0,
			yMeters: 0,
		};
		this.interval = null;
		this.initialName = initialState.itemName;

		this.onStart = this.onStart.bind(this);
		this.onDrag = this.onDrag.bind(this);
		this.onStop = this.onStop.bind(this);
		this.onClick = this.onClick.bind(this);
		this.delete = this.delete.bind(this);
		this.sendUpdate = this.sendUpdate.bind(this);
		this.changeName = this.changeName.bind(this);
		this.checkName = this.checkName.bind(this);
		this.changeHz = this.changeHz.bind(this);
		this.changeMode = this.changeMode.bind(this);
		this.changeOperation = this.changeOperation.bind(this);
		this.changeParameter = this.changeParameter.bind(this);
	}

	componentDidMount() {
		const { itemName, x, y, hz, mode, selectedOperation, operationParameters } = this.state;
		this.sendUpdate(itemName, x, y, hz, mode, selectedOperation, operationParameters);
	}

	static getDerivedStateFromProps(props) {
		const initialState = props.state;
		return {
			id: initialState.id,
			itemName: initialState.itemName,
			x: initialState.x,
			y: initialState.y,
			hz: initialState.hz,
			mode: initialState.mode,
			selectedOperation: initialState.selectedOperation,
			operationParameters: initialState.operationParameters,
			elementDimensions: props.elementDimensions,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
			resolution: props.resolution,
		};
	}

	onStart(event) {
		event.preventDefault();
		const { x, y, mapWidth, mapHeight, resolution } = this.state;
		const xMeters = ((x / 100) * mapWidth) * resolution;
		const yMeters = ((y / 100) * mapHeight) * resolution;
		this.setState({ metersPopupOpen: true, xMeters, yMeters });
	}

	onDrag(event, data) {
		event.preventDefault();
		const { x, y, mapWidth, mapHeight, resolution } = this.state;
		const xMeters = (((x / 100) * mapWidth) + data.x) * resolution;
		const yMeters = (((y / 100) * mapHeight) - data.y) * resolution;
		this.setState({ dragging: true, xMeters, yMeters });
	}

	onStop(event, data) {
		event.preventDefault();
		const { dragging } = this.state;
		this.setState({ dragging: false, metersPopupOpen: false });
		if (!dragging) {
			this.onClick();
		}

		const { itemName, x, y, hz, mode, selectedOperation, operationParameters, mapWidth, mapHeight } = this.state;
		let newX = ((((x / 100) * mapWidth) + data.x) / mapWidth) * 100;
		if (newX > 100) {
			newX = 100;
		}

		if (newX < 0) {
			newX = 0;
		}

		let newY = ((((y / 100) * mapHeight) - data.y) / mapHeight) * 100;
		if (newY > 100) {
			newY = 100;
		}

		if (newY < 0) {
			newY = 0;
		}

		this.sendUpdate(itemName, newX, newY, hz, mode, selectedOperation, operationParameters);
	}

	onClick() {
		this.setState({ popoverOpen: true });
	}

	delete() {
		const { id } = this.state;
		this.deleteItem(this.cat, this.itemCat, id);
	}

	sendUpdate(itemName, x, y, hz, mode, selectedOperation, operationParameters) {
		const { id } = this.state;
		this.updateItem(this.cat, this.itemCat, id, {
			id, itemName, x, y, hz, mode, selectedOperation, operationParameters, cat: this.cat, itemCat: this.itemCat,
		});
	}

	changeName(value) {
		const { x, y, hz, mode, selectedOperation, operationParameters } = this.state;
		this.sendUpdate(value.toLowerCase().split(' ').join('_'), x, y, hz, mode, selectedOperation, operationParameters);
	}

	checkName(value) {
		const { id } = this.state;
		const { matchesPattern, found } = this.checkItemNameExists(value, this.initialName, this.cat, this.itemCat, id);
		if (matchesPattern) {
			ToasterBottom.show({
				intent: 'danger',
				message: 'Name cannot follow the template category_number',
			});
			const { x, y, hz, mode, selectedOperation, operationParameters } = this.state;
			this.sendUpdate(this.initialName, x, y, hz, mode, selectedOperation, operationParameters);
		} else if (found) {
			ToasterBottom.show({
				intent: 'danger',
				message: 'Name already exists',
			});
			const { x, y, hz, mode, selectedOperation, operationParameters } = this.state;
			this.sendUpdate(this.initialName, x, y, hz, mode, selectedOperation, operationParameters);
		}
	}

	changeHz(hz) {
		const { itemName, x, y, mode, selectedOperation, operationParameters } = this.state;
		this.sendUpdate(itemName, x, y, hz, mode, selectedOperation, operationParameters);
	}

	changeMode(mode) {
		const { itemName, x, y, hz, selectedOperation, operationParameters } = this.state;
		this.sendUpdate(itemName, x, y, hz, mode, selectedOperation, operationParameters);
	}

	changeOperation(operation) {
		const { itemName, x, y, hz, mode, operationParameters } = this.state;
		this.sendUpdate(itemName, x, y, hz, mode, operation, operationParameters);
	}

	changeParameter(value, param) {
		const { itemName, x, y, hz, mode, selectedOperation, operationParameters } = this.state;
		const temp = {};
		for (const opParameter of Object.keys(operationParameters)) {
			temp[opParameter] = {};
			for (const opParam of Object.keys(operationParameters[opParameter])) {
				temp[opParameter][opParam] = operationParameters[opParameter][opParam];
			}
		}

		temp[selectedOperation][param] = value;
		this.sendUpdate(itemName, x, y, hz, mode, selectedOperation, temp);
	}

	render() {
		const { id, itemName, x, y, hz, mode, selectedOperation, operationParameters, elementDimensions, dragging, popoverOpen, metersPopupOpen, xMeters, yMeters } = this.state;

		return (
			<Draggable
				key={`ph_${id}`}
				allowAnyClick={false}
				grid={[10, 10]}
				position={{ x: 0, y: 0 }}
				handle=".itemhandler"
				onStart={this.onStart}
				onDrag={this.onDrag}
				onStop={this.onStop}
			>
				<ItemDiv id={`item_${id}`} x={x} y={y} elementDimensions={elementDimensions} dragging={dragging}>
					<Tooltip
						popoverClassName="item-info-tooltip"
						disabled={dragging || popoverOpen}
						content={(
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									textAlign: 'center',
									maxWidth: '200px',
									backgroundColor: "rgba(0, 0, 0, 0.8)",
									padding: '10px',
									borderRadius: '10px',
									color: 'white',
								}}
							>
								<div style={{ fontWeight: 'bold', fontSize: '16px' }}>
									{"Hz"}
								</div>
								<div style={{ textAlign: 'center', height: 'fit-content', wordBreak: 'break-word' }}>
									{`${hz}hz`}
								</div>
								<div style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '10px' }}>
									{"Mode"}
								</div>
								<div style={{ textAlign: 'center', height: 'fit-content', wordBreak: 'break-word' }}>
									{mode}
								</div>
								{mode === 'mock'
									&& (
										<div style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '10px' }}>
											{"Operation Mode"}
										</div>
									)}
								{mode === 'mock'
									&& (
										<div style={{ textAlign: 'center', height: 'fit-content', wordBreak: 'break-word' }}>
											{selectedOperation}
										</div>
									)}
							</div>
						)}
						interactionKind="hover"
					>
						<img
							key={Math.random()}
							id={`itemImg_${id}`}
							className="itemhandler"
							src={ph}
							alt=""
							draggable={false}
							style={{ maxWidth: '100%', maxHeight: '100%' }}
						/>
					</Tooltip>
					<div
						style={{
							display: (metersPopupOpen) ? 'block' : 'none', width: 'max-content', paddingLeft: '5px', paddingRight: '5px', height: '20px', position: 'absolute', top: `-${(elementDimensions / 2) + 5}px`, left: `${(elementDimensions / 2) - (145 / 2)}px`, fontSize: '14px', textAlign: 'center', background: '#7a8585', borderRadius: '10px', color: 'white',
						}}
					>
						{`x: ${xMeters.toFixed(3)}m, y: ${yMeters.toFixed(3)}m`}
					</div>
					{popoverOpen && (
						<CustomPopover
							elementDimensions={elementDimensions}
							popoverOpen={popoverOpen}
							closePopover={() => { this.setState({ popoverOpen: false }); }}
							changeName={this.changeName}
							checkName={this.checkName}
							itemName={itemName}
							changeHz={this.changeHz}
							hz={hz}
							selectedOperation={selectedOperation}
							operationParameters={operationParameters}
							changeOperation={this.changeOperation}
							changeParameter={this.changeParameter}
							mode={mode}
							changeMode={this.changeMode}
							deleteItem={this.delete}
						/>
					)}
				</ItemDiv>
			</Draggable>
		);
	}
}

const createPH = (state, resolution, elementDimensions, mapWidth, mapHeight, updateItem, deleteItem, checkItemNameExists) => (
	<PH key={state.id} state={state} resolution={resolution} elementDimensions={elementDimensions} mapWidth={mapWidth} mapHeight={mapHeight} updateItem={updateItem} deleteItem={deleteItem} checkItemNameExists={checkItemNameExists} />
);

export default createPH;

/* eslint-disable no-unused-vars */

/* eslint-disable max-len */

import {
	EditableText, Menu, NumericInput, Popover, Tooltip, Icon, Checkbox,
} from '@blueprintjs/core';
import { faTrash, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';
import { ToasterBottom } from '../../../lib_socioty/toaster.js';
import { GreenBorderButton } from '../../../lib_socioty/buttons.js';
import distance from '../../../../assets/images/missionItems/sensors/distance.png';
import distancePanTilt from '../../../../assets/images/missionItems/sensors/distancePanTilt.png';

import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const PrettoSlider = styled(Slider)({
	color: '#52af77',
	height: 8,
	'& .MuiSlider-track': {
		border: 'none',
	},
	'& .MuiSlider-thumb': {
		height: 24,
		width: 24,
		backgroundColor: '#fff',
		border: '2px solid currentColor',
		'&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
			boxShadow: 'inherit',
		},
		'&:before': {
			display: 'none',
		},
	},
});

const parameters = {
	constant: {
		value: {
			min: 0,
			max: Number.POSITIVE_INFINITY,
			stepSize: 0.1,
			minorStepSize: 0.01,
			majorStepSize: 1,
		},
	},
	random: {
		min: {
			min: 0,
			max: 'max',
			stepSize: 0.1,
			minorStepSize: 0.01,
			majorStepSize: 1,
		},
		max: {
			min: 'min',
			max: Number.POSITIVE_INFINITY,
			stepSize: 0.1,
			minorStepSize: 0.01,
			majorStepSize: 1,
		},
	},
	triangle: {
		min: {
			min: 0,
			max: 'max',
			stepSize: 0.1,
			minorStepSize: 0.01,
			majorStepSize: 1,
		},
		max: {
			min: 'min',
			max: Number.POSITIVE_INFINITY,
			stepSize: 0.1,
			minorStepSize: 0.01,
			majorStepSize: 1,
		},
		step: {
			min: 0,
			max: Number.POSITIVE_INFINITY,
			stepSize: 0.01,
			minorStepSize: 0.001,
			majorStepSize: 0.1,
		},
	},
	normal: {
		mean: {
			min: 0,
			max: Number.POSITIVE_INFINITY,
			stepSize: 0.01,
			minorStepSize: 0.001,
			majorStepSize: 0.1,
		},
		std: {
			min: 0,
			max: Number.POSITIVE_INFINITY,
			stepSize: 0.01,
			minorStepSize: 0.001,
			majorStepSize: 0.1,
		},
	},
	sinus: {
		dc: {
			min: 0,
			max: Number.POSITIVE_INFINITY,
			stepSize: 0.01,
			minorStepSize: 0.001,
			majorStepSize: 0.1,
		},
		amplitude: {
			min: 0,
			max: Number.POSITIVE_INFINITY,
			stepSize: 0.01,
			minorStepSize: 0.001,
			majorStepSize: 0.1,
		},
		step: {
			min: 0,
			max: Number.POSITIVE_INFINITY,
			stepSize: 0.01,
			minorStepSize: 0.001,
			majorStepSize: 0.1,
		},
	},
};

const panTiltParameters = {
	sinus: {
		hz: {
			min: 0,
			max: Number.POSITIVE_INFINITY,
			stepSize: 1,
			minorStepSize: 0.1,
			majorStepSize: 10,
		},
		step: {
			min: 0,
			max: Number.POSITIVE_INFINITY,
			stepSize: 0.01,
			minorStepSize: 0.001,
			majorStepSize: 0.1,
		},
	},
};

const limits = {
	pan: {
		min: {
			min: -180,
			max: 'max',
			stepSize: 1,
			minorStepSize: 0.1,
			majorStepSize: 10,
		},
		max: {
			min: 'min',
			max: 180,
			stepSize: 1,
			minorStepSize: 0.1,
			majorStepSize: 10,
		},
	},
	tilt: {
		min: {
			min: -180,
			max: 'max',
			stepSize: 1,
			minorStepSize: 0.1,
			majorStepSize: 10,
		},
		max: {
			min: 'min',
			max: 180,
			stepSize: 1,
			minorStepSize: 0.1,
			majorStepSize: 10,
		},
	},
};

const ItemDiv = styled.div`
	width: ${(props) => `${props.elementDimensions}px`};
	height: ${(props) => `${props.elementDimensions}px`};
	border-radius: ${(props) => `${props.elementDimensions}px`};
	position: absolute;
	left: ${(props) => `calc(${props.x}% - ${(props.elementDimensions / 2)}px)`};
	bottom: ${(props) => `calc(${props.y}% - ${(props.elementDimensions / 2)}px)`};
	cursor: ${(props) => ((props.dragging) ? 'move' : 'pointer')};
	display: flex;
	transform: ${(props) => (props.dragging ? '' : 'none!important')};
`;

const BasicPopover = ({ className, elementDimensions, popoverOpen, closePopover, changeName, checkName, itemName, rotateLeft, rotateRight, rotate90Left, rotate90Right, changeHz, hz, changeRange, range, mode, changeMode, selectedOperation, operationParameters, changeOperation, changeParameter, onPanTilt, changeOnPanTilt, onPanTiltClick, deleteItem, mapWidth, mapResolution, theta }) => (
	<Popover usePortal popoverClassName={`${className} item-popover`} elementDimensions={elementDimensions} isOpen={popoverOpen}>
		<div style={{
			width: '300px', padding: '10px', color: 'white', background: 'black', boxShadow: '4px 4px 10px 1px #333333',
		}}
		>
			{/* Name */}
			<div style={{
				width: '100%', maxWidth: '100%', paddingLeft: '1px', paddingRight: '1px', display: 'flex', justifyContent: 'space-around',
			}}
			>
				<TextField
					value={itemName}
					label="Name"
					variant="filled"
					sx={{
						backgroundColor: '#aaa',
						mx: 1,
						width: '100%',
					}}
					size="small"
					color="success"
					onChange={(event) => changeName(event.target.value)}
				/>
			</div>

			{/* Direction */}
			<div style={{
				margin: '10px', display: 'flex', justifyContent: 'space-around', alignContent: 'center',
			}}
			>
				<Button
					color="secondary"
					variant="contained"
					style={{
						width: "40%",
						fontSize: "0.7rem",
					}}
					onClick={() => { rotateRight("step"); }}
				>
					{`Rot right`}
				</Button>
				<Typography color="#ffffff" pt={0.7}>
					{`${theta}°`}
				</Typography>
				<Button
					color="secondary"
					variant="contained"
					style={{
						width: "40%",
						fontSize: "0.7rem",
					}}
					onClick={() => { rotateLeft("step"); }}
				>
					{`Rot left`}
				</Button>
			</div>

			{/* Hz */}
			<div style={{
				width: '100%', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center',
			}}
			>
				<Typography color="#ffffff" variant="caption" mr={1}>
					{`Hz (${hz})`}
				</Typography>
				<PrettoSlider
					aria-label="Always visible"
					value={hz}
					color="secondary"
					min={0}
					max={10}
					step={1}
					sx={{
						width: "150px",
					}}
					onChange={(event) => changeHz(event.target.value)}
				/>
			</div>

			{/* Range */}
			<div style={{
				width: '100%', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center',
			}}
			>
				<Typography color="#ffffff" variant="caption" mr={1}>
					{`Range (${Number(range.toFixed(2))}m)`}
				</Typography>
				<PrettoSlider
					aria-label="Always visible"
					value={Number(range.toFixed(2))}
					color="secondary"
					min={0}
					max={mapWidth * mapResolution}
					step={1}
					sx={{
						width: "150px",
					}}
					onChange={(event) => changeRange(event.target.value)}
				/>
			</div>

			{/* Mode */}
			<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
				<FormControl error sx={{ m: 1, width: "100%" }}>
					<Select
						value={mode}
						label="Mode"
						sx={{
							backgroundColor: 'gray',
						}}
						size="small"
					>
						{['mock', 'simulation'].map((m) => (
							<MenuItem key={Math.random()} value={m} onClick={() => changeMode(m)}>
								{`Mode: ${m.charAt(0).toUpperCase()}${m.slice(1)}`}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>

			<div
				style={{
					width: '100%', marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly',
				}}
			>
				<div style={{ width: '100px' }}>
					{"On Pan-Tilt"}
				</div>
				<Checkbox className="pan-tilt-checkbox" checked={onPanTilt} onChange={changeOnPanTilt} />
			</div>
			{(onPanTilt === 1)
				&& (
					<div
						style={{
							width: '100%', marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center',
						}}
					>
						<Button
							color="secondary"
							variant="contained"
							style={{
								width: "100%",
								m: 10,
							}}
							onClick={onPanTiltClick}
						>
							{`Pan-Tilt Settings`}
						</Button>
					</div>
				)}

			{/* Parameters */}
			{mode === 'mock'
				&& (
					<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
						<FormControl error sx={{ m: 1, width: "100%" }} size="small">
							<Select
								value={selectedOperation}
								label="Data generation"
								sx={{
									backgroundColor: 'gray',
								}}
							>
								{Object.keys(operationParameters).map((operation) => (
									<MenuItem key={Math.random()} value={operation} onClick={() => changeOperation(operation)}>
										{`Data generation: ${operation.charAt(0).toUpperCase()}${operation.slice(1)}`}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				)}
			{mode === 'mock'
				&& (
					<div
						style={{
							width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
						}}
					>
						{Object.keys(parameters[selectedOperation]).map((param) => (
							<div key={param}>
								<div
									style={{
										width: '100%', marginTop: '10px', display: 'flex', alignItems: 'center',
									}}
								>
									{parameters[selectedOperation][param].name || param}
								</div>
								<div style={{
									width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
								}}
								>
									<NumericInput
										clampValueOnBlur
										className="parameter-input"
										min={(typeof parameters[selectedOperation][param].min === 'number') ? parameters[selectedOperation][param].min : operationParameters[selectedOperation][parameters[selectedOperation][param].min]}
										max={(typeof parameters[selectedOperation][param].max === 'number') ? parameters[selectedOperation][param].max : operationParameters[selectedOperation][parameters[selectedOperation][param].max]}
										minorStepSize={parameters[selectedOperation][param].minorStepSize}
										placeholder={param}
										stepSize={parameters[selectedOperation][param].stepSize}
										defaultValue={operationParameters[selectedOperation][param]}
										onValueChange={(value) => changeParameter(value, param)}
									/>
								</div>
							</div>
						))}
					</div>
				)}
			<div style={{
				width: '100%', height: '20px', display: 'flex', justifyContent: 'space-around', marginTop: '10px',
			}}
			>
				<FontAwesomeIcon icon={faTrash} style={{ fontSize: '20px', cursor: 'pointer' }} onClick={deleteItem} />
				<FontAwesomeIcon icon={faXmarkCircle} style={{ fontSize: '20px', cursor: 'pointer' }} onClick={closePopover} />
			</div>
		</div>
	</Popover>
);

const PanTiltPopover = ({ className, elementDimensions, panTiltPopoverOpen, closePopover, panTiltLimits, changeLimits, panTiltSelectedOperation, panTiltOperationParameters, changeOperation, changeParameter, panTiltMode, changePanTiltMode }) => (
	<Popover usePortal popoverClassName={`${className} item-popover`} elementDimensions={elementDimensions} isOpen={panTiltPopoverOpen}>
		<div style={{
			width: '300px', padding: '10px', color: 'white', background: '#222', boxShadow: '4px 4px 10px 1px #333333',
		}}
		>
			<div
				style={{
					width: '100%', height: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center',
				}}
			>
				{"Pan Limits"}
			</div>
			<div
				style={{
					width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
				}}
			>
				{Object.keys(limits.pan).map((param) => (
					<div key={param} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
						<div style={{ paddingRight: "10px" }}>
							{param}
						</div>
						<NumericInput clampValueOnBlur className="parameter-input" min={(typeof limits.pan[param].min === 'number') ? limits.pan[param].min : panTiltLimits.pan[limits.pan[param].min]} max={(typeof limits.pan[param].max === 'number') ? limits.pan[param].max : panTiltLimits.pan[limits.pan[param].max]} minorStepSize={limits.pan[param].minorStepSize} placeholder={param} stepSize={limits.pan[param].stepSize} defaultValue={Number((panTiltLimits.pan[param]).toFixed(2))} onValueChange={(e) => changeLimits(e, param, 'pan')} />
					</div>
				))}
			</div>
			<div
				style={{
					width: '100%', height: '20px', marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center',
				}}
			>
				{"Tilt Limits"}
			</div>
			<div
				style={{
					width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
				}}
			>
				{Object.keys(limits.tilt).map((param) => (
					<div key={param} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
						<div style={{ paddingRight: "10px" }}>
							{param}
						</div>
						<NumericInput clampValueOnBlur className="parameter-input" min={(typeof limits.tilt[param].min === 'number') ? limits.tilt[param].min : panTiltLimits.tilt[limits.tilt[param].min]} max={(typeof limits.tilt[param].max === 'number') ? limits.tilt[param].max : panTiltLimits.tilt[limits.tilt[param].max]} minorStepSize={limits.tilt[param].minorStepSize} placeholder={param} stepSize={limits.tilt[param].stepSize} defaultValue={Number((panTiltLimits.tilt[param]).toFixed(2))} onValueChange={(e) => changeLimits(e, param, 'tilt')} />
					</div>
				))}
			</div>

			{/* Mode */}
			<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
				<FormControl error sx={{ m: 1, width: "100%" }}>
					<Select
						value={panTiltMode}
						label="Mode"
						sx={{
							backgroundColor: 'gray',
						}}
						size="small"
					>
						{['mock', 'simulation'].map((m) => (
							<MenuItem key={Math.random()} value={m} onClick={() => changePanTiltMode(m)}>
								{`Mode: ${m.charAt(0).toUpperCase()}${m.slice(1)}`}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>

			{panTiltMode === 'mock'
				&& (
					<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
						<FormControl error sx={{ m: 1, width: "100%" }} size="small">
							<Select
								value={panTiltSelectedOperation}
								label="Pan-Tilt Mode"
								sx={{
									backgroundColor: 'gray',
								}}
							>
								{Object.keys(panTiltOperationParameters).map((operation) => (
									<MenuItem key={Math.random()} value={operation} onClick={() => changeOperation(operation)}>
										{`Pan-Tilt Mode: ${operation.charAt(0).toUpperCase()}${operation.slice(1)}`}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				)}

			{panTiltMode === 'mock'
				&& (
					<div
						style={{
							width: '100%', height: '20px', marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center',
						}}
					>
						{"Pan-Tilt Parameters"}
					</div>
				)}
			{panTiltMode === 'mock'
				&& (
					<div
						style={{
							width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
						}}
					>
						{Object.keys(panTiltParameters[panTiltSelectedOperation]).map((param) => (
							<div key={param} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
								<div style={{ paddingRight: "10px" }}>
									{param}
								</div>
								<NumericInput clampValueOnBlur className="parameter-input" min={(typeof panTiltParameters[panTiltSelectedOperation][param].min === 'number') ? panTiltParameters[panTiltSelectedOperation][param].min : panTiltOperationParameters[panTiltSelectedOperation][panTiltParameters[panTiltSelectedOperation][param].min]} max={(typeof panTiltParameters[panTiltSelectedOperation][param].max === 'number') ? panTiltParameters[panTiltSelectedOperation][param].max : panTiltOperationParameters[panTiltSelectedOperation][panTiltParameters[panTiltSelectedOperation][param].max]} minorStepSize={panTiltParameters[panTiltSelectedOperation][param].minorStepSize} placeholder={param} stepSize={panTiltParameters[panTiltSelectedOperation][param].stepSize} value={Number((panTiltOperationParameters[panTiltSelectedOperation][param]).toFixed(2))} onValueChange={(e) => changeParameter(e, param)} />
							</div>
						))}
					</div>
				)}

			{/* Actions */}
			<div style={{
				width: '100%', height: '20px', display: 'flex', justifyContent: 'space-around', marginTop: '10px',
			}}
			>
				<FontAwesomeIcon icon={faXmarkCircle} style={{ fontSize: '20px', cursor: 'pointer' }} onClick={closePopover} />
			</div>
		</div>
	</Popover>
);

const CustomPanTiltPopover = styled(PanTiltPopover)`
    transform: ${(props) => `translate(${-(props.elementDimensions / 2)}px, 0px)`};
    border-radius: 10px;
`;

const CustomPopover = styled(BasicPopover)`
	transform: ${(props) => `translate(${-(props.elementDimensions / 2)}px, 0px)`};
	border-radius: 10px;
`;

const CustomIcon = styled(Icon)`
	cursor: pointer;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-o-user-select: none;
	user-select: none;
	transform: ${(props) => props.transform};
`;

class Distance extends React.Component {
	constructor(props) {
		super(props);

		const initialState = props.state;
		this.cat = initialState.cat;
		this.itemCat = initialState.itemCat;
		this.updateItem = props.updateItem;
		this.deleteItem = props.deleteItem;
		this.checkItemNameExists = props.checkItemNameExists;

		this.state = {
			id: initialState.id,
			itemName: initialState.itemName,
			x: initialState.x,
			y: initialState.y,
			theta: initialState.theta,
			hz: initialState.hz,
			range: initialState.range,
			mode: initialState.mode,
			selectedOperation: initialState.selectedOperation,
			operationParameters: initialState.operationParameters,
			onPanTilt: initialState.onPanTilt,
			panTiltLimits: initialState.panTiltLimits,
			panTiltMode: initialState.panTiltMode,
			panTiltSelectedOperation: initialState.panTiltSelectedOperation,
			panTiltOperationParameters: initialState.panTiltOperationParameters,
			elementDimensions: props.elementDimensions,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
			dragging: false,
			popoverOpen: false,
			panTiltPopoverOpen: false,
			resolution: props.resolution,
			metersPopupOpen: false,
			xMeters: 0,
			yMeters: 0,
			displayRange: false,
		};
		this.interval = null;
		this.initialName = initialState.itemName;

		this.onStart = this.onStart.bind(this);
		this.onDrag = this.onDrag.bind(this);
		this.onStop = this.onStop.bind(this);
		this.onClick = this.onClick.bind(this);
		this.onPanTiltClick = this.onPanTiltClick.bind(this);
		this.rotateLeft = this.rotateLeft.bind(this);
		this.rotateRight = this.rotateRight.bind(this);
		this.rotate90Left = this.rotate90Left.bind(this);
		this.rotate90Right = this.rotate90Right.bind(this);
		this.delete = this.delete.bind(this);
		this.sendUpdate = this.sendUpdate.bind(this);
		this.changeName = this.changeName.bind(this);
		this.checkName = this.checkName.bind(this);
		this.changeHz = this.changeHz.bind(this);
		this.changeRange = this.changeRange.bind(this);
		this.changeMode = this.changeMode.bind(this);
		this.changeOperation = this.changeOperation.bind(this);
		this.changeParameter = this.changeParameter.bind(this);
		this.changeOnPanTilt = this.changeOnPanTilt.bind(this);
		this.changeLimits = this.changeLimits.bind(this);
		this.changePanTiltMode = this.changePanTiltMode.bind(this);
		this.changePanTiltOperation = this.changePanTiltOperation.bind(this);
		this.changePanTiltParameter = this.changePanTiltParameter.bind(this);
	}

	componentDidMount() {
		const { itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters } = this.state;
		this.sendUpdate(itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters);
	}

	static getDerivedStateFromProps(props) {
		const initialState = props.state;
		return {
			id: initialState.id,
			itemName: initialState.itemName,
			x: initialState.x,
			y: initialState.y,
			theta: initialState.theta,
			hz: initialState.hz,
			range: initialState.range,
			mode: initialState.mode,
			selectedOperation: initialState.selectedOperation,
			operationParameters: initialState.operationParameters,
			onPanTilt: initialState.onPanTilt,
			panTiltLimits: initialState.panTiltLimits,
			panTiltMode: initialState.panTiltMode,
			panTiltSelectedOperation: initialState.panTiltSelectedOperation,
			panTiltOperationParameters: initialState.panTiltOperationParameters,
			elementDimensions: props.elementDimensions,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
			resolution: props.resolution,
		};
	}

	onStart(event) {
		event.preventDefault();
		const { x, y, mapWidth, mapHeight, resolution } = this.state;
		const xMeters = ((x / 100) * mapWidth) * resolution;
		const yMeters = ((y / 100) * mapHeight) * resolution;
		this.setState({ metersPopupOpen: true, xMeters, yMeters, displayRange: false });
	}

	onDrag(event, data) {
		event.preventDefault();
		const { x, y, mapWidth, mapHeight, resolution } = this.state;
		const xMeters = (((x / 100) * mapWidth) + data.x) * resolution;
		const yMeters = (((y / 100) * mapHeight) - data.y) * resolution;
		this.setState({ dragging: true, xMeters, yMeters, displayRange: false });
	}

	onStop(event, data) {
		event.preventDefault();
		const { dragging } = this.state;
		this.setState({ dragging: false, metersPopupOpen: false });
		if (!dragging) {
			this.onClick();
		}

		const { itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters, mapWidth, mapHeight } = this.state;
		let newX = ((((x / 100) * mapWidth) + data.x) / mapWidth) * 100;
		if (newX > 100) {
			newX = 100;
		}

		if (newX < 0) {
			newX = 0;
		}

		let newY = ((((y / 100) * mapHeight) - data.y) / mapHeight) * 100;
		if (newY > 100) {
			newY = 100;
		}

		if (newY < 0) {
			newY = 0;
		}

		this.sendUpdate(itemName, newX, newY, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters);
	}

	onClick() {
		this.setState({ popoverOpen: true });
	}

	onPanTiltClick() {
		this.setState({ popoverOpen: false, panTiltPopoverOpen: true });
	}

	rotateLeft(m) {
		if (m === 'step') {
			const { itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters } = this.state;
			this.sendUpdate(itemName, x, y, theta + 10, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters);
		} else if (m === 'start') {
			this.interval = setInterval(() => { const { itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters } = this.state; this.sendUpdate(itemName, x, y, theta + 1, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters); }, 100);
		} else {
			clearInterval(this.interval);
			this.interval = null;
		}
	}

	rotateRight(m) {
		if (m === 'step') {
			const { itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters } = this.state;
			this.sendUpdate(itemName, x, y, theta - 10, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters);
		} else if (m === 'start') {
			this.interval = setInterval(() => { const { itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters } = this.state; this.sendUpdate(itemName, x, y, theta - 1, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters); }, 100);
		} else {
			clearInterval(this.interval);
			this.interval = null;
		}
	}

	rotate90Left() {
		const { itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters } = this.state;
		const position = (theta % 360) / 90;
		let newTheta;
		newTheta = position === Math.ceil(position) ? (position + 1) * 90 : Math.ceil(position) * 90;
		this.sendUpdate(itemName, x, y, newTheta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters);
	}

	rotate90Right() {
		const { itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters } = this.state;
		const position = (theta % 360) / 90;
		let newTheta;
		newTheta = position === Math.floor(position) ? (position - 1) * 90 : Math.floor(position) * 90;
		this.sendUpdate(itemName, x, y, newTheta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters);
	}

	delete() {
		const { id } = this.state;
		this.deleteItem(this.cat, this.itemCat, id);
	}

	sendUpdate(itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters) {
		const { id } = this.state;
		this.updateItem(this.cat, this.itemCat, id, {
			id, itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters, cat: this.cat, itemCat: this.itemCat,
		});
	}

	changeName(value) {
		const { x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters } = this.state;
		this.sendUpdate(value.toLowerCase().split(' ').join('_'), x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters);
	}

	checkName(value) {
		const { id } = this.state;
		const { matchesPattern, found } = this.checkItemNameExists(value, this.initialName, this.cat, this.itemCat, id);
		if (matchesPattern) {
			ToasterBottom.show({
				intent: 'danger',
				message: 'Name cannot follow the template category_number',
			});
			const { x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters } = this.state;
			this.sendUpdate(this.initialName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters);
		} else if (found) {
			ToasterBottom.show({
				intent: 'danger',
				message: 'Name already exists',
			});
			const { x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters } = this.state;
			this.sendUpdate(this.initialName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters);
		}
	}

	changeHz(hz) {
		const { itemName, x, y, theta, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters } = this.state;
		this.sendUpdate(itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters);
	}

	changeRange(range) {
		const { itemName, x, y, theta, hz, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters } = this.state;
		this.sendUpdate(itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters);
	}

	changeMode(mode) {
		const { itemName, x, y, theta, hz, range, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters } = this.state;
		this.sendUpdate(itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters);
	}

	changeOperation(operation) {
		const { itemName, x, y, theta, hz, range, mode, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters } = this.state;
		this.sendUpdate(itemName, x, y, theta, hz, range, mode, operation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters);
	}

	changeParameter(value, param) {
		const { itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters } = this.state;
		const temp = {};
		for (const opParameter of Object.keys(operationParameters)) {
			temp[opParameter] = {};
			for (const opParam of Object.keys(operationParameters[opParameter])) {
				temp[opParameter][opParam] = operationParameters[opParameter][opParam];
			}
		}

		temp[selectedOperation][param] = value;
		this.sendUpdate(itemName, x, y, theta, hz, range, mode, selectedOperation, temp, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters);
	}

	changeOnPanTilt() {
		const { itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters } = this.state;
		const newPanTilt = (onPanTilt === 0) ? 1 : 0;
		this.sendUpdate(itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, newPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters);
	}

	changeLimits(value, param, category) {
		const { itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters } = this.state;
		const temp = {};
		for (const opParameter of Object.keys(panTiltLimits)) {
			temp[opParameter] = {};
			for (const opParam of Object.keys(panTiltLimits[opParameter])) {
				temp[opParameter][opParam] = panTiltLimits[opParameter][opParam];
			}
		}

		temp[category][param] = value;
		this.sendUpdate(itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, temp, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters);
	}

	changePanTiltMode(panTiltMode) {
		const { itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltSelectedOperation, panTiltOperationParameters } = this.state;
		this.sendUpdate(itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters);
	}

	changePanTiltOperation(operation) {
		const { itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltOperationParameters } = this.state;
		this.sendUpdate(itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, operation, panTiltOperationParameters);
	}

	changePanTiltParameter(value, param) {
		const { itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters } = this.state;
		const temp = {};
		for (const opParameter of Object.keys(panTiltOperationParameters)) {
			temp[opParameter] = {};
			for (const opParam of Object.keys(panTiltOperationParameters[opParameter])) {
				temp[opParameter][opParam] = panTiltOperationParameters[opParameter][opParam];
			}
		}

		temp[panTiltSelectedOperation][param] = value;
		this.sendUpdate(itemName, x, y, theta, hz, range, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, temp);
	}

	render() {
		const { id, itemName, x, y, theta, hz, range, resolution, mode, selectedOperation, operationParameters, onPanTilt, panTiltLimits, panTiltMode, panTiltSelectedOperation, panTiltOperationParameters, elementDimensions, dragging, popoverOpen, panTiltPopoverOpen, metersPopupOpen, xMeters, yMeters, displayRange, mapWidth } = this.state;
		const rangePx = range / resolution;

		return (
			<Draggable
				key={`distance_${id}`}
				allowAnyClick={false}
				grid={[10, 10]}
				position={{ x: 0, y: 0 }}
				handle=".itemhandler"
				onStart={this.onStart}
				onDrag={this.onDrag}
				onStop={this.onStop}
			>
				<ItemDiv id={`item_${id}`} x={x} y={y} elementDimensions={elementDimensions} dragging={dragging}>
					<Tooltip
						popoverClassName="item-info-tooltip"
						disabled={dragging || popoverOpen || panTiltPopoverOpen}
						content={(
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									textAlign: 'center',
									maxWidth: '200px',
									backgroundColor: "rgba(0, 0, 0, 0.8)",
									padding: '10px',
									borderRadius: '10px',
									color: 'white',
								}}
							>
								<div style={{ fontWeight: 'bold', fontSize: '16px' }}>
									{"Hz"}
								</div>
								<div style={{ textAlign: 'center', height: 'fit-content', wordBreak: 'break-word' }}>
									{`${hz}hz`}
								</div>
								<div style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '10px' }}>
									{"Range"}
								</div>
								<div style={{ textAlign: 'center', height: 'fit-content', wordBreak: 'break-word' }}>
									{`${range}m`}
								</div>
								<div style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '10px' }}>
									{"Mode"}
								</div>
								<div style={{ textAlign: 'center', height: 'fit-content', wordBreak: 'break-word' }}>
									{mode}
								</div>
								{mode === 'mock'
									&& (
										<div style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '10px' }}>
											{"Operation Mode"}
										</div>
									)}
								{mode === 'mock'
									&& (
										<div style={{ textAlign: 'center', height: 'fit-content', wordBreak: 'break-word' }}>
											{selectedOperation}
										</div>
									)}
							</div>
						)}
						interactionKind="hover"
						onOpening={() => this.setState({ displayRange: true })}
						onClosing={() => this.setState({ displayRange: false })}
					>
						<div style={{ transform: `rotate(${-theta}deg)` }}>
							<img
								key={Math.random()}
								id={`itemImg_${id}`}
								className="itemhandler"
								src={(onPanTilt === 0) ? distance : distancePanTilt}
								alt=""
								draggable={false}
								style={{ maxWidth: '100%', maxHeight: '100%' }}
							/>
							{(popoverOpen || displayRange)
								&& (
									<div
										style={{
											width: `${rangePx}px`,
											height: '5px',
											display: 'block',
											background: 'rgba(255, 0, 0, 0.4)',
											position: 'absolute',
											top: `${-2.5 + elementDimensions / 2}px`,
											left: `${elementDimensions - 10}px`,
											filter: 'blur(5px)',
											animation: 'areaAlarmAnimation 1s linear infinite',
											zIndex: -1,
										}}
									/>
								)}
						</div>
					</Tooltip>
					<div
						style={{
							display: (metersPopupOpen) ? 'block' : 'none', width: 'max-content', paddingLeft: '5px', paddingRight: '5px', height: '20px', position: 'absolute', top: `-${(elementDimensions / 2) + 5}px`, left: `${(elementDimensions / 2) - (145 / 2)}px`, fontSize: '14px', textAlign: 'center', background: '#7a8585', borderRadius: '10px', color: 'white',
						}}
					>
						{`x: ${xMeters.toFixed(3)}m, y: ${yMeters.toFixed(3)}m`}
					</div>
					{popoverOpen && (
						<CustomPopover
							elementDimensions={elementDimensions}
							popoverOpen={popoverOpen}
							closePopover={() => { this.setState({ popoverOpen: false }); }}
							changeName={this.changeName}
							checkName={this.checkName}
							itemName={itemName}
							rotateLeft={this.rotateLeft}
							rotateRight={this.rotateRight}
							rotate90Left={this.rotate90Left}
							rotate90Right={this.rotate90Right}
							changeHz={this.changeHz}
							hz={hz}
							changeRange={this.changeRange}
							range={range}
							mode={mode}
							changeMode={this.changeMode}
							selectedOperation={selectedOperation}
							operationParameters={operationParameters}
							changeOperation={this.changeOperation}
							changeParameter={this.changeParameter}
							changeOnPanTilt={this.changeOnPanTilt}
							deleteItem={this.delete}
							mapWidth={mapWidth}
							mapResolution={resolution}
							theta={theta}
							onPanTilt={onPanTilt}
							onPanTiltClick={this.onPanTiltClick}
						/>
					)}
					{panTiltPopoverOpen && (
						<CustomPanTiltPopover
							elementDimensions={elementDimensions}
							panTiltPopoverOpen={panTiltPopoverOpen}
							closePopover={() => { this.setState({ panTiltPopoverOpen: false }); }}
							panTiltLimits={panTiltLimits}
							changeLimits={this.changeLimits}
							panTiltSelectedOperation={panTiltSelectedOperation}
							panTiltOperationParameters={panTiltOperationParameters}
							changeOperation={this.changeOperation}
							changeParameter={this.changeParameter}
							panTiltMode={panTiltMode}
							changePanTiltMode={this.changePanTiltMode}
						/>
					)}
				</ItemDiv>
			</Draggable>
		);
	}
}

const createDistance = (state, resolution, elementDimensions, mapWidth, mapHeight, updateItem, deleteItem, checkItemNameExists) => (
	<Distance key={state.id} state={state} resolution={resolution} elementDimensions={elementDimensions} mapWidth={mapWidth} mapHeight={mapHeight} updateItem={updateItem} deleteItem={deleteItem} checkItemNameExists={checkItemNameExists} />
);

export default createDistance;

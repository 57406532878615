export const envDevices = {
	ph_sensors: {
		category: 'sensors',
		name: 'ph',
		props: {
			hz: 'hz',
			mode: 'mode',
			operation: 'selectedOperation',
			operation_parameters: 'operationParameters',
		},
	},
	temperature_sensors: {
		category: 'sensors',
		name: 'temperature',
		props: {
			hz: 'hz',
			mode: 'mode',
			operation: 'selectedOperation',
			operation_parameters: 'operationParameters',
		},
	},
	humidity_sensors: {
		category: 'sensors',
		name: 'humidity',
		props: {
			hz: 'hz',
			mode: 'mode',
			operation: 'selectedOperation',
			operation_parameters: 'operationParameters',
		},
	},
	gas_sensors: {
		category: 'sensors',
		name: 'gas',
		props: {
			hz: 'hz',
			mode: 'mode',
			operation: 'selectedOperation',
			operation_parameters: 'operationParameters',
		},
	},
	camera_sensors: {
		category: 'sensors',
		name: 'camera',
		props: {
			hz: 'hz',
			fov: 'fov',
			range: 'range',
			width: 'width',
			height: 'height',
			mode: 'mode',
		},
	},
	distance_sensors: {
		category: 'sensors',
		name: 'distance',
		props: {
			hz: 'hz',
			mode: 'mode',
			operation: 'selectedOperation',
			operation_parameters: 'operationParameters',
			max_range: 'range',
		},
	},
	alarms_linear: {
		category: 'sensors',
		name: 'linearAlarm',
		props: {
			hz: 'hz',
			mode: 'mode',
		},
	},
	alarms_area: {
		category: 'sensors',
		name: 'areaAlarm',
		props: {
			range: 'range',
			hz: 'hz',
			mode: 'mode',
		},
	},
	ambient_light_sensor: {
		category: 'sensors',
		name: 'ambientLight',
		props: {
			hz: 'hz',
			mode: 'mode',
			operation: 'selectedOperation',
			operation_parameters: 'operationParameters',
		},
	},
	microphones: {
		category: 'sensors',
		name: 'microphone',
		props: { mode: 'mode' },
	},
	relays: {
		category: 'effectors',
		name: 'relay',
		props: {
			states: 'allowedStates',
			initial_state: 'initialState',
		},
	},
	speakers: {
		category: 'effectors',
		name: 'speaker',
		props: {},
	},
	lights: {
		category: 'effectors',
		name: 'light',
		props: {
			luminosity: 'luminosity',
			range: 'range',
		},
	},
	thermostats: {
		category: 'effectors',
		name: 'thermostat',
		props: {
			temperature: 'temperature',
			range: 'range',
		},
	},
	humidifiers: {
		category: 'effectors',
		name: 'humidifier',
		props: {
			humidity: 'humidity',
			range: 'range',
		},
	},
};

export const actors = {
	humans: {
		name: 'human',
		props: {
			move: 'move',
			sound: 'sound',
			lang: 'lang',
			range: 'range',
			speech: 'speech',
			emotion: 'emotion',
			gender: 'gender',
			age: 'age',
		},
	},
	sound_sources: {
		name: 'sound',
		props: {
			lang: 'lang',
			range: 'range',
			speech: 'speech',
			emotion: 'emotion',
		},
	},
	qrs: {
		name: 'qr',
		props: { message: 'message' },
	},
	barcodes: {
		name: 'barcode',
		props: { message: 'message' },
	},
	colors: {
		name: 'color',
		props: {
			r: 'r',
			g: 'g',
			b: 'b',
		},
	},
	texts: {
		name: 'text',
		props: { text: 'text' },
	},
	rfid_tags: {
		name: 'rfid',
		props: { message: 'message' },
	},
	fires: {
		name: 'fire',
		props: {
			range: 'range',
			temperature: 'temperature',
		},
	},
	waters: {
		name: 'water',
		props: { range: 'range' },
	},
};

export const devicesConfiguration = {
	microphone: (robotName) => ([{
		orientation: 0,
		place: 'FRONT',
		name: `microphone_front_${robotName}`,
		sensor_configuration: {
			alsa_device: '1',
			hosts: ['127.0.0.1', '127.0.0.1'],
			ports: ['12333', '12334'],
			host_port: '12334',
			dev_name: 'dsnoop:CARD=Mic,DEV=0',
			channels: 6,
			framerate: 44_100,
			max_data_length: 10,
		},
	}]),
	sonar: (robotName) => ([
		{
			pose: { theta: 135 },
			orientation: 135,
			place: 'BL',
			hz: 1,
			max_range: 2,
			name: `sonar_bl_${robotName}`,
			sensor_configuration: { sim: 1 },
		},
		{
			pose: { theta: -135 },
			orientation: -135,
			place: 'BR',
			hz: 1,
			max_range: 2,
			name: `sonar_br_${robotName}`,
			sensor_configuration: { sim: 1 },
		},
		{
			pose: { theta: 45 },
			orientation: 45,
			place: 'FL',
			hz: 1,
			max_range: 2,
			name: `sonar_fl_${robotName}`,
			sensor_configuration: { sim: 1 },
		},
		{
			pose: { theta: -45 },
			orientation: -45,
			place: 'FR',
			hz: 1,
			max_range: 2,
			name: `sonar_fr_${robotName}`,
			sensor_configuration: { sim: 1 },
		},
		{
			pose: { theta: 90 },
			orientation: 90,
			place: 'L',
			hz: 1,
			max_range: 2,
			name: `sonar_l_${robotName}`,
			sensor_configuration: { sim: 1 },
		},
		{
			pose: { theta: -90 },
			orientation: -90,
			place: 'R',
			hz: 1,
			max_range: 2,
			name: `sonar_r_${robotName}`,
			sensor_configuration: { sim: 1 },
		},
	]),
	camera: (robotName) => ([{
		pose: { theta: 0 },
		orientation: 0,
		place: 'FRONT',
		hz: 0.5,
		name: `tektrain_camera_${robotName}`,
		host: `pan_tilt_${robotName}`,
		range: 3,
		fov: 90,
		width: 640,
		height: 480,
		sensor_configuration: { sim: 1 },
	}]),
	imu: (robotName) => ([{
		orientation: 1,
		place: 'CENTER',
		hz: 1,
		name: `imu_${robotName}`,
		sensor_configuration: { sim: 1 },
	}]),
	env: (robotName) => ([{
		orientation: 0,
		place: 'FRONT',
		hz: 1,
		name: `env_${robotName}`,
		sensor_configuration: { sim: 1 },
	}]),
	speaker: (robotName) => ([{
		orientation: 0,
		place: 'FRONT',
		name: `speaker_front_${robotName}`,
		sensor_configuration: { sim: 1 },
	}]),
	leds: (robotName) => ([{
		orientation: 0,
		place: 'FRONT',
		name: `leds_front_${robotName}`,
		sensor_configuration: { sim: 1 },
	}]),
	pan_tilt: (robotName) => ([{
		pose: { x: 0, y: 0, theta: 0 },
		orientation: 0,
		place: 'UNDER',
		name: `pan_tilt_${robotName}`,
		sensor_configuration: { sim: 1 },
	}]),
	encoder: (robotName) => ([
		{
			orientation: 90,
			place: 'BL',
			hz: 1,
			name: `encoder_bl_${robotName}`,
			sensor_configuration: { sim: 1 },
		},
		{
			orientation: -90,
			place: 'BR',
			hz: 1,
			name: `encoder_br_${robotName}`,
			sensor_configuration: { sim: 1 },
		},

	]),
	skid_steer: (robotName) => ([{
		orientation: 0,
		place: 'UNDER',
		name: `skid_steer_${robotName}`,
		sensor_configuration: {
			bus: 1,
			E1: 20,
			M1: 21,
			E2: 12,
			M2: 16,
			max_data_length: 10,
			wheel_separation: 0.147,
			wheel_radius: 0.022,
		},
	}]),
	rfid_reader: (robotName) => ([{
		pose: { x: 0, y: 0, theta: 0 },
		orientation: 0,
		place: 'FRONT',
		hz: 1,
		range: 2,
		fov: 360,
		name: `rfid_reader_${robotName}`,
		sensor_configuration: { sim: 1 },
	}]),
};

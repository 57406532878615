import { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import Image from "mui-image";
import { ExpandMore } from "@mui/icons-material";

import inspectionIcon from "../assets/icons/inspection.png";
// import { getTeamsData } from "../api/index.js";
import { useSnackbar } from "../utils/index.js";

import Accordion from "./Accordion.js";

const useStyles = makeStyles((theme) => ({
	sidebar: {
		height: "calc(100% - 220px)",
		position: "fixed",
		top: "130px",
		marginLeft: "25px",
		backgroundColor: theme.palette.third.main,
		color: "white",
		borderTopRightRadius: "30px",
		borderTopLeftRadius: "30px",
		borderBottomRightRadius: "400px",
		background: "linear-gradient(to bottom, #04598c 50%, #193256 100%)",
		boxShadow: `2px 2px 14px ${theme.palette.dark.main}`,
	},
}));

const ButtonWithText = ({ text, icon, more, handler }) => (
	<span key={text}>
		{!more
			&& (
				<Button key={text} sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "8px 40px 8px 16px" }} onClick={(event) => handler(event)}>
					<Image src={icon} alt={text} fit="contain" width="25px" />
					<Typography align="center" color="white.main" fontSize="medium" ml={1} display="flex" alignItems="center" sx={{ textTransform: "capitalize" }}>
						{text}
						{more && <ExpandMore />}
					</Typography>
				</Button>
			)}
		{more
			&& (
				<Accordion
					key={text}
					alwaysExpanded
					useShadow={false}
					title={(
						<Grid item sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
							<Image src={icon} alt={text} fit="contain" width="25px" />
							<Typography align="center" color="white.main" fontSize="medium" ml={1} display="flex" alignItems="center" sx={{ textTransform: "capitalize" }}>
								{text}
							</Typography>
						</Grid>
					)}
					content={(
						<Grid container flexDirection="column" width="100%">
							{more.map((el) => (
								<Button
									key={el.title}
									color="white"
									style={{
										justifyContent: "flex-start",
									}}
									onClick={el.handler}
								>
									<Typography ml={3} sx={{ textTransform: "capitalize" }}>{`▸ ${el.title}`}</Typography>
								</Button>
							))}
						</Grid>
					)}
					titleBackground="transparent"
					expandIconColor="white"
				/>
			)}
	</span>
);

const ButtonSimple = ({ text, icon, handler, ind }) => (
	<Button key={text} sx={{ minWidth: "30px!important", padding: "0px", marginTop: (ind === 0) ? "0px" : "10px" }} onClick={(event) => handler(event)}>
		<Image src={icon} alt={text} fit="contain" width="30px" />
	</Button>
);

const Sidebar = ({ isSmall: sidebarIsSmall }) => {
	const [isSmall, setIsSmall] = useState(false);
	const [anchorElServices, setAnchorElServices] = useState(null);
	const navigate = useNavigate();
	const classes = useStyles();
	const [teams, setTeams] = useState([]);
	const { error, success } = useSnackbar();

	const isMenuOpenServices = Boolean(anchorElServices);
	const handleServicesMenuOpen = (event) => setAnchorElServices(event.currentTarget);
	const handleServicesMenuClose = () => { setAnchorElServices(null); };

	useEffect(() => setIsSmall(sidebarIsSmall), [sidebarIsSmall]);

	const fetchData = useCallback(
		async () => {
			try {
				// const { success: scs, teams: tms } = await getTeamsData();
				// const final = [];

				// if (scs) {
				// 	// eslint-disable-next-line guard-for-in
				// 	for (const t in tms) {
				// 		final.push(
				// 			{
				// 				title: tms[t].name,
				// 				handler: () => navigate(`/teams/${tms[t]._id}`),
				// 			},
				// 		);
				// 	}

				// 	setTeams(final);
				// } else {
				// 	error();
				// }
			} catch {
				error();
			}
		},
		[error, navigate],
	);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, [fetchData]);

	const buttons = [
		{
			icon: inspectionIcon,
			text: "Teams",
			handler: (event) => {
				handleServicesMenuClose();
				handleServicesMenuOpen(event);
			},
			more: teams,
		},
		{
			icon: inspectionIcon,
			text: "Publications",
			handler: () => {
				handleServicesMenuClose();
				navigate("/publications");
			},
		},
		{
			icon: inspectionIcon,
			text: "My reviews",
			handler: () => {
				handleServicesMenuClose();
				navigate("/reviews");
			},
		},
	];

	return (
		<div className={classes.sidebar} style={{ width: (isSmall) ? "50px" : "200px", padding: (isSmall) ? "20px 5px" : "20px 5px", textAlign: "left" }}>
			{!isSmall && buttons.map((button) => (
				<ButtonWithText
					key={button.text}
					icon={button.icon}
					text={button.text}
					handler={button.handler}
					more={button.more}
				/>
			))}
			{isSmall && buttons.map((button, ind) => (
				<ButtonSimple
					key={button.text}
					icon={button.icon}
					text={button.text}
					handler={button.handler}
					more={button.more}
					ind={ind}
				/>
			))}
		</div>
	);
};

export default Sidebar;

import {
	FormControlLabel,
	Switch,
	Typography,
	Grid,
} from "@mui/material";

const ParametersSwitch = ({ param, parameters, setParameters, nodeData }) => (
	<Grid
		item
		container
		display="flex"
		direction="row"
		justifyContent="flex-start"
		alignItems="center"
		sx={{
			marginBottom: "5px",
			width: "100%",
		}}
	>
		<Typography sx={{ marginRight: "10px", width: "40%" }}>
			{param.label}
		</Typography>

		<Switch
			checked={parameters.find((p) => p.id === param.id).value}
			onChange={(e) => {
				const newParams = parameters.map((p) => {
					if (p.id === param.id) {
						return {
							...p,
							value: e.target.checked,
						};
					}

					return p;
				});
				setParameters(newParams);
			}}
		/>

	</Grid>
);

export default ParametersSwitch;

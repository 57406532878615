import { shortDescription } from "./envmaker/short-description.js";
import { getDocumentation } from "./envmaker/documentation.js";

import EnvmakerLight from "../assets/dsls-logos/envmaker_L.png";
import EnvmakerDark from "../assets/dsls-logos/envmaker_D.png";

/**
 * Retrieves the constants for the EnvMaker DSL.
 * @returns {Promise<Object>} The constants object.
 */
export const getEnvmakerConstants = async () => {
	const constants = {
		enabled: true,
		_id: "envmaker",
		name: "EnvMaker",
		short: "envmaker",
		description: "Tool for visually creating environments for IoT applications.",
		logoUrl: EnvmakerLight,
		logoUrlDark: EnvmakerDark,
		insertableEntities: {},
		shortDescription,
		documentation: getDocumentation(),
		subtypes: [],
		hasValidation: false,
		type: "Graphical",
		canTransformTo: [],
		mustImport: [],
		canImport: [],
		supportsGeneration: false,
		generationLanguage: "",
		supportsDeployment: false,
		generations: ["Default"],
		generationsOutputMap: {
			Default: "string",
		},
		keywords: [],
		llmSupport: false,
	};
	return constants;
};

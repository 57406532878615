export const getDocumentation = async () => {
	// Fetch the README.md file from the repository
	const response = await fetch('https://raw.githubusercontent.com/robotics-4-all/demol/main/README.md');
	if (!response.ok) {
		throw new Error('Failed to fetch README.md');
	}

	// Replace the relative paths to the assets with the absolute paths
	const content = await response.text();
	const modifiedContent = content.replaceAll("assets/", "https://raw.githubusercontent.com/robotics-4-all/demol/main/assets/");

	return modifiedContent;
};


import { Icon } from '@blueprintjs/core';
import React from 'react';
import styled from 'styled-components';
import LineTo from 'react-lineto';

const ProgressBarDiv = styled.div`
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-evenly;
`;

const ProgressStep = styled.div`
    width: 35px;
    height: 60px;
    display: flex;
    flex-direction: column;
`;

const ProgressCircle = styled.div`
    width: 35px;
    height: 35px;
    border-radius: 35px;
    border: 2px solid #3c9764;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const ProgressTitle = styled.div`
    width: 35px;
    height: 23px;
    margin-top: 2px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
`;

export class ProgressBar extends React.Component {
	constructor(props) {
		super(props);

		this.selectStep = props.selectStep;

		this.state = { currentStep: props.currentStep };

		this.missionSteps = props.missionSteps;

		this.update = this.update.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.update);
		document.addEventListener('scroll', this.update, true);
	}

	static getDerivedStateFromProps(props) {
		return { currentStep: props.currentStep };
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.update);
		document.removeEventListener('scroll', this.update, true);
	}

	update() {
		const { currentStep } = this.state;
		this.setState({ currentStep });
	}

	render() {
		const { currentStep } = this.state;

		return (
			<ProgressBarDiv>
				{this.missionSteps.map((step, ind) => (
					<ProgressStep key={step.id}>
						<ProgressCircle className={step.id} style={{ background: (currentStep >= ind) ? '#3c9764' : 'none' }} onClick={() => this.selectStep(ind)}>
							{currentStep > ind && <Icon color="#030305" icon="tick" iconSize={20} />}
						</ProgressCircle>
						{ind !== 0
                        && (
	<LineTo from={this.missionSteps[ind - 1].id} to={step.id} borderColor="#3c9764" borderWidth={2} zIndex={0} fromAnchor="center right" toAnchor="center left" delay={0} />
                        )}
						<ProgressTitle style={{ color: (currentStep >= ind) ? '#3c9764' : '#595959' }}>
							{step.name}
						</ProgressTitle>
					</ProgressStep>
				))}
			</ProgressBarDiv>
		);
	}
}

export default ProgressBar;

import { Accordion as MUIAccordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { memo } from "react";

import colors from "../_colors.scss";

const useStyles = makeStyles((theme) => ({
	accordion: {
		width: "100%!important",
		borderRadius: "10px!important",
		backgroundColor: "transparent",
		// boxShadow: "none",
	},
	accordionExpanded: {
		minHeight: "auto",
		margin: "0px!important",
	},
	accordionHeader: {
		color: "primary",
		borderRadius: "10px",
		boxShadow: "transparent!important",
		margin: "0px!important",
		backgroundColor: (props) => colors[props.titleBackground] || props.titleBackground,
	},
	accordionHeaderExpanded: {
		borderTopRightRadius: "10px",
		borderTopLeftRadius: "10px",
		borderBottomRightRadius: "0px",
		borderBottomLeftRadius: "0px",
		minHeight: "auto!important",
	},
	accordionContentGutters: {
		margin: "12px 0px!important",
	},
	accordionSubtitle: {
		backgroundColor: theme.palette.third.main,
		color: theme.palette.white.main,
		borderRadius: "0px 0px 10px 10px",
		padding: "8px 40px 8px 16px",
		boxShadow: `2px 2px 14px ${theme.palette.dark.main}`,
		marginBottom: "10px",
	},
	accordionSubtitleNoTitle: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.primary.main,
		borderRadius: "10px 10px 10px 10px",
		padding: "8px 40px 8px 16px",
		boxShadow: `2px 2px 14px ${theme.palette.dark.main}`,
		marginBottom: "10px",
	},
	accordionMain: {
		backgroundColor: "transparent",
		color: "white",
	},
}));

const Accordion = ({
	title,
	titleColor = "white",
	titleBackground = "secondary",
	subtitle,
	subtitleColor = "white",
	subtitleBackground = "third",
	expandIconColor = "primary",
	content,
	alwaysExpanded = false,
	useShadow = true,
}) => {
	const classes = useStyles({ titleColor, titleBackground, subtitleColor, subtitleBackground });
	return (
		<MUIAccordion
			classes={{
				rounded: classes.accordion,
				expanded: classes.accordionExpanded,
			}}
			sx={{ minHeight: "auto", boxShadow: (useShadow ? "2px 2px 14px" : "none") }}
			expanded={alwaysExpanded || undefined}
		>
			<AccordionSummary
				expandIcon={alwaysExpanded ? null : <ExpandMore color={expandIconColor} />}
				classes={{
					root: classes.accordionHeader,
					expanded: classes.accordionHeaderExpanded,
					contentGutters: classes.accordionContentGutters,
				}}
				sx={{ background: "secondary.main", ...(alwaysExpanded && { cursor: "default!important" }) }}
			>
				{typeof title === "string"
					? <Typography>{title}</Typography>
					: title}
			</AccordionSummary>
			{subtitle
				&& (
					<AccordionDetails
						classes={{
							root: classes.accordionSubtitle,
						}}
					>
						{typeof subtitle === "string"
							? <Typography>{subtitle}</Typography>
							: subtitle}
					</AccordionDetails>
				)}
			<AccordionDetails
				classes={{
					root: classes.accordionMain,
				}}
				style={{
					padding: (subtitle) ? "8px 40px 16px 16px" : "0px 0px 16px 0px",
				}}
			>
				{typeof content === "string"
					? <Typography>{content}</Typography>
					: content}
			</AccordionDetails>
		</MUIAccordion>
	);
};

export default memo(Accordion);


import humidifierIcon from '../../../../assets/images/missionItems/effectors/humidifier.png';
import lightIcon from '../../../../assets/images/missionItems/effectors/light.png';
import relayIcon from '../../../../assets/images/missionItems/effectors/relay.png';
import speakerIcon from '../../../../assets/images/missionItems/effectors/speaker.png';
import thermostatIcon from '../../../../assets/images/missionItems/effectors/thermostat.png';
import createHumidifier from './humidifier.js';
import createLight from './light.js';
import createRelay from './relay.js';
import createSpeaker from './speaker.js';
import createThermostat from './thermostat.js';

const effectors = {
	name: 'Actuators/Effectors',
	components: {
		humidifier: {
			icon: humidifierIcon,
			component: createHumidifier,
			props: {
				humidity: 70,
				range: 20,
			},
			header: 'Humidifier',
			text: 'A humidifier can be used to change the humidity in the area it is placed',
		},
		light: {
			icon: lightIcon,
			component: createLight,
			props: {
				luminosity: 70,
				range: 20,
			},
			header: 'Light',
			text: 'A programmable, smart light that can be turned on and off, defining its luminosity',
		},
		relay: {
			icon: relayIcon,
			component: createRelay,
			props: {
				allowedStates: ['On', 'Off'],
				initialState: 'Off',
			},
			header: 'Switch',
			text: 'A mechanical switch that can take various states',
		},
		speaker: {
			icon: speakerIcon,
			component: createSpeaker,
			props: {},
			header: 'Speaker',
			text: 'A speaker can be used to “say” things or play sounds',
		},
		thermostat: {
			icon: thermostatIcon,
			component: createThermostat,
			props: {
				temperature: 22,
				range: 20,
			},
			header: 'Thermostat',
			text: 'A thermostat can be used to change the temperature in the area it is placed',
		},
	},
};

export default effectors;

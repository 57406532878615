import { configureStore } from '@reduxjs/toolkit';
import brokersReducer from './slice-brokers.js';
import storageVariablesReducer from './slice-storage-variables.js';
import threadsReducer from './slice-threads.js';
import nodesReducer from './slice-nodes.js';
import logsReducer from './slice-logs.js';
import errorsReducer from './slice-errors.js';

export default configureStore({
	reducer: {
		brokers: brokersReducer,
		storageVariables: storageVariablesReducer,
		threads: threadsReducer,
		nodes: nodesReducer,
		logs: logsReducer,
		errors: errorsReducer,
	},
});

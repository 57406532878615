import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {Object} Error
 * @property {string} payload - The log payload.
 */

/**
 * @typedef {Object} ErrorsState
 * @property {Error[]} - The array of logs.
 */

/**
 * @typedef {import('@reduxjs/toolkit').CaseReducer<ErrorsState>} ErrorsCaseReducer
 */

/**
 * @typedef {import('@reduxjs/toolkit').Slice<ErrorsState, ErrorsCaseReducer>} ErrorsSlice
 */

/**
 * The slice for managing logs in the application state.
 * @type {ErrorsSlice}
 */
export const errorsSlice = createSlice({
	name: 'errors',
	initialState: [],
	reducers: {
		/**
		 * Set errors to slice
		 * @param {ErrorsState} state - The current state.
		 * @param {Error} log - The log to be added.
		 */
		setErrors: (state, errors) => {
			state = errors;
			return state;
		},
		/**
		 * Cleans the errors array.
		 * @param {ErrorsState} state - The current state.
		 */
		cleanErrors: (state) => {
			state = [];
			return state;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setErrors, cleanErrors } = errorsSlice.actions;

export default errorsSlice.reducer;

import React, { memo } from 'react';
import { Box } from 'rebass';
import styled from 'styled-components';
import Draggable from 'react-draggable';
import {
	Collapse, NumericInput, Switch,
} from '@blueprintjs/core';

import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import categories from "./categories";

const StyledBox = styled(Box)`
	height: 100%;
	width: 100%;
	display: block;
	align-items: center;
	justify-content: center;
	overflow: auto;
`;

const StyledArea = styled(Box)`
	height: 100%;
	width: 100%;
	max-width: 1400px;
	min-width: 1060px!important;
	min-height: 640px;
	display: flex;
	flex-direction: column;
	padding: 20px;
	padding-top: 30px;
	padding-bottom: 30px;
	margin: auto!important;
	color: white;
`;

const MenuRow = styled.div`
	width: 100%;
	height: 35px;
	font-size: 22px;
	display: flex;
	align-items: center;
	padding: 10px;
`;

const GridSetting = styled.div`
	width: 102px;
	height: 100%;
	display: flex;
	align-items: center;
	margin-left: 20px;
`;

const MainRow = styled.div`
	width: 100%;
	height: calc(100% - 10px);
	padding: 10px;
	display: flex;
`;

const MainMap = styled.div`
	width: calc(100% - 250px);
	height: 100%;
	display: flex;
	align-items: center;
	background: rgba(255, 255, 255, 0.1);
`;

const Map = styled.div`
	width: ${(props) => `${props.mapWidth}px`};
	height: ${(props) => `${props.mapHeight}px`};
	background: white;
	position: relative;
	margin: auto;
`;

const Menu = styled.div`
	width: 250px;
	height: 100%;
	padding-left: 20px;
	padding-right: 20px;
	display: block;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow-y: auto;
	overflow-x: hidden;
`;

const CategoryDiv = styled.div`
	width: 100%;
	background: white;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	margin-top: 2px;
`;

const DragComp = styled.div`
	width: 70px;
	height: 70px;
	cursor: move;
	z-index: 1;
	justify-content: center;
	margin: 5px;
`;

const EmptyComp = styled.div`
	width: 70px;
	height: 70px;
	cursor: move;
	z-index: 1;
	justify-content: center;
	margin: 5px;
`;

const PrettoSlider = styled(Slider)({
	color: '#52af77',
	height: 8,
	'& .MuiSlider-track': {
		border: 'none',
	},
	'& .MuiSlider-thumb': {
		height: 24,
		width: 24,
		backgroundColor: '#fff',
		border: '2px solid currentColor',
		'&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
			boxShadow: 'inherit',
		},
		'&:before': {
			display: 'none',
		},
	},
	'& .MuiSlider-valueLabel': {
		lineHeight: 0.5,
		fontSize: 11,
		background: 'unset',
		padding: 0,
		width: 20,
		height: 20,
		borderRadius: '50% 50% 50% 0',
		backgroundColor: '#52af77',
		transformOrigin: 'bottom left',
		transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
		'&:before': { display: 'none' },
		'&.MuiSlider-valueLabelOpen': {
			transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
		},
		'& > *': {
			transform: 'rotate(45deg)',
		},
	},
});

const getMapDimensions = (mainMapWidth, mainMapHeight) => {
	const availWidth = mainMapWidth;
	const availHeight = mainMapHeight;
	const initRatio = mainMapWidth / mainMapHeight;
	const ratio = 1.5;

	if (initRatio === ratio) {
		return { width: availWidth, height: availHeight };
	}

	if (initRatio > ratio) {
		return { width: ratio * availHeight, height: availHeight };
	}

	return { width: availWidth, height: availWidth / ratio };
};

const getElementDimension = (mapWidth, mapHeight, nRows, nCols) => {
	const elementWidth = (mapWidth) / nCols;
	const elementHeight = (mapHeight) / nRows;
	return Math.min(elementWidth, elementHeight);
};

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const toRadians = (angle) => (
	angle * (Math.PI / 180)
);

const transformWall = (wall, boxPos, rotation, mapPos, nextId) => {
	const x1Box = boxPos.width * (wall.x1 / 100);
	const y1Box = boxPos.height * (wall.y1 / 100);
	const newX1Box = (x1Box - (boxPos.width / 2)) * Math.cos(toRadians(-rotation)) - (y1Box - (boxPos.height / 2)) * Math.sin(toRadians(-rotation)) + (boxPos.width / 2);
	const newY1Box = (x1Box - (boxPos.width / 2)) * Math.sin(toRadians(-rotation)) + (y1Box - (boxPos.height / 2)) * Math.cos(toRadians(-rotation)) + (boxPos.height / 2);
	const x1Px = newX1Box + boxPos.left - mapPos.left;
	const x1 = (x1Px / mapPos.width) * 100;
	const y1Px = newY1Box - boxPos.bottom + mapPos.bottom;
	const y1 = (y1Px / mapPos.height) * 100;
	const x2Box = boxPos.width * (wall.x2 / 100);
	const y2Box = boxPos.height * (wall.y2 / 100);
	const newX2Box = (x2Box - (boxPos.width / 2)) * Math.cos(toRadians(-rotation)) - (y2Box - (boxPos.height / 2)) * Math.sin(toRadians(-rotation)) + (boxPos.width / 2);
	const newY2Box = (x2Box - (boxPos.width / 2)) * Math.sin(toRadians(-rotation)) + (y2Box - (boxPos.height / 2)) * Math.cos(toRadians(-rotation)) + (boxPos.height / 2);
	const x2Px = newX2Box + boxPos.left - mapPos.left;
	const x2 = (x2Px / mapPos.width) * 100;
	const y2Px = newY2Box - boxPos.bottom + mapPos.bottom;
	const y2 = (y2Px / mapPos.height) * 100;
	return {
		x1, y1, x2, y2, id: nextId,
	};
};

const transformWater = (water, boxPos, rotation, mapPos, nextId) => {
	const xBox = boxPos.width * (water.x / 100);
	const x2Box = boxPos.width * ((water.x + water.range) / 100);
	const yBox = boxPos.height * (water.y / 100);
	const newXBox = (xBox - (boxPos.width / 2)) * Math.cos(toRadians(-rotation)) - (yBox - (boxPos.height / 2)) * Math.sin(toRadians(-rotation)) + (boxPos.width / 2);
	const newYBox = (xBox - (boxPos.width / 2)) * Math.sin(toRadians(-rotation)) + (yBox - (boxPos.height / 2)) * Math.cos(toRadians(-rotation)) + (boxPos.height / 2);
	const newX2Box = (x2Box - (boxPos.width / 2)) * Math.cos(toRadians(-rotation)) - (yBox - (boxPos.height / 2)) * Math.sin(toRadians(-rotation)) + (boxPos.width / 2);
	const newY2Box = (x2Box - (boxPos.width / 2)) * Math.sin(toRadians(-rotation)) + (yBox - (boxPos.height / 2)) * Math.cos(toRadians(-rotation)) + (boxPos.height / 2);
	const xPx = newXBox + boxPos.left - mapPos.left;
	const x = (xPx / mapPos.width) * 100;
	const yPx = newYBox - boxPos.bottom + mapPos.bottom;
	const y = (yPx / mapPos.height) * 100;
	const x2Px = newX2Box + boxPos.left - mapPos.left;
	const x2 = (x2Px / mapPos.width) * 100;
	const y2Px = newY2Box - boxPos.bottom + mapPos.bottom;
	const y2 = (y2Px / mapPos.height) * 100;
	return {
		x, y, x2, y2, id: nextId,
	};
};

export class CreateMap extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			nRows: 4,
			nCols: 4,
			boxes: {},
			gridVisible: true,
			mapWidth: 100,
			mapHeight: 80,
			compDragging: null,
			openCategory: null,
			dragging: null,
			draggingCat: null,
			draggedItem: { x: 0, y: 0 },
		};

		this.updateModel = props.modelUpdate;

		this.onStart = this.onStart.bind(this);
		this.onDrag = this.onDrag.bind(this);
		this.onStop = this.onStop.bind(this);
		this.onCompStart = this.onCompStart.bind(this);
		this.onCompDrag = this.onCompDrag.bind(this);
		this.onCompStop = this.onCompStop.bind(this);
		this.changeNumberRows = this.changeNumberRows.bind(this);
		this.changeNumberCols = this.changeNumberCols.bind(this);
		this.changeGrid = this.changeGrid.bind(this);
		this.gridClick = this.gridClick.bind(this);
		this.changeMapDimensions = this.changeMapDimensions.bind(this);
		this.changeCategoryState = this.changeCategoryState.bind(this);
		this.calculateObstacles = this.calculateObstacles.bind(this);
		this.calculateWaters = this.calculateWaters.bind(this);
	}

	componentDidMount() {
		const { nRows, nCols } = this.state;
		const boxes = {};
		for (let i = 0; i < nRows; i += 1) {
			for (let j = 0; j < nCols; j += 1) {
				boxes[`${i + 1}_${j + 1}`] = {
					r: i + 1,
					c: j + 1,
					content: null,
					contentId: null,
					contentCat: null,
					rotation: 0,
				};
			}
		}

		this.setState({ boxes });
		this.changeMapDimensions();
		window.addEventListener('resize', this.changeMapDimensions);
	}

	componentDidUpdate(_prevProps) {
		if (this.props.model !== "" && _prevProps.model === "") {
			const retrievedState = JSON.parse(this.props.model);
			this.setState(retrievedState);
			this.setState({ compDragging: null, draggedItem: { x: 0, y: 0 }, dragging: null, draggingCat: null });
			this.changeMapDimensions();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.changeMapDimensions);
	}

	onStart(event) {
		event.preventDefault();
	}

	onDrag(event, comp, cat) {
		event.preventDefault();
		this.setState({ dragging: comp, draggingCat: cat, draggedItem: { x: event.pageX, y: event.pageY } });
	}

	onStop(event) {
		event.preventDefault();
		const { boxes, dragging, draggingCat } = this.state;
		const { bottom, left, top, right } = document.querySelector('#map').getBoundingClientRect();
		const { x, y } = event;
		if ((x >= left) && (x <= right) && (y <= bottom) && (y >= top)) {
			for (const box of Object.keys(boxes)) {
				const comp = document.getElementById(box).getBoundingClientRect();
				if ((x >= comp.left) && (x <= comp.right) && (y <= comp.bottom) && (y >= comp.top)) {
					boxes[box].content = categories[draggingCat].components[dragging].image;
					boxes[box].contentId = categories[draggingCat].components[dragging].id;
					boxes[box].contentCat = draggingCat;
					boxes[box].rotation = 0;
				}
			}
		}

		this.setState({ boxes, dragging: null, draggingCat: null });

		const tmpState = this.state;
		tmpState.boxes = boxes;
		this.updateModel(JSON.stringify(tmpState));
	}

	onCompStart(event) {
		event.preventDefault();
	}

	onCompDrag(event, comp) {
		event.preventDefault();
		this.setState({ compDragging: comp });
	}

	onCompStop(event, bbox) {
		event.preventDefault();
		const { boxes, compDragging } = this.state;
		if (compDragging === null) {
			this.gridClick(bbox);
			return;
		}

		const { bottom, left, top, right } = document.querySelector('#map').getBoundingClientRect();
		const { x, y } = event;
		if ((x >= left) && (x <= right) && (y <= bottom) && (y >= top)) {
			for (const box of Object.keys(boxes)) {
				const comp = document.getElementById(box).getBoundingClientRect();
				if ((x >= comp.left) && (x <= comp.right) && (y <= comp.bottom) && (y >= comp.top) && box !== compDragging) {
					boxes[box].content = boxes[compDragging].content;
					boxes[box].contentId = boxes[compDragging].contentId;
					boxes[box].contentCat = boxes[compDragging].contentCat;
					boxes[box].rotation = boxes[compDragging].rotation;
					boxes[compDragging].content = null;
					boxes[compDragging].contentId = null;
					boxes[compDragging].contentCat = null;
					boxes[compDragging].rotation = 0;
				}
			}
		} else {
			boxes[compDragging].content = null;
			boxes[compDragging].contentId = null;
			boxes[compDragging].contentCat = null;
			boxes[compDragging].rotation = 0;
		}

		this.setState({ compDragging: null });

		const tmpState = this.state;
		tmpState.boxes = boxes;
		this.updateModel(JSON.stringify(tmpState));
	}

	changeMapDimensions() {
		const mainMapWidth = document.querySelector('#mainmap').offsetWidth;
		const mainMapHeight = document.querySelector('#mainmap').offsetHeight;
		const dimensions = getMapDimensions(mainMapWidth, mainMapHeight);
		this.setState({ mapWidth: dimensions.width, mapHeight: dimensions.height });
	}

	changeNumberRows(value) {
		const { nRows, nCols, boxes } = this.state;
		if (nRows > value) {
			for (let i = 0; i < nCols; i += 1) {
				delete boxes[`${nRows}_${i + 1}`];
			}
		} else {
			for (let i = 0; i < nCols; i += 1) {
				boxes[`${value}_${i + 1}`] = {
					r: value,
					c: i + 1,
					content: null,
					contentId: null,
					contentCat: null,
					rotation: 0,
				};
			}
		}

		this.setState({ nRows: value, boxes });

		const tmpState = this.state;
		tmpState.nRows = value;
		tmpState.boxes = boxes;
		this.updateModel(JSON.stringify(tmpState));
	}

	changeNumberCols(value) {
		const { nRows, nCols, boxes } = this.state;
		if (nCols > value) {
			for (let i = 0; i < nRows; i += 1) {
				delete boxes[`${i + 1}_${nCols}`];
			}
		} else {
			for (let i = 0; i < nRows; i += 1) {
				boxes[`${i + 1}_${value}`] = {
					r: i + 1,
					c: value,
					content: null,
					contentId: null,
					contentCat: null,
					rotation: 0,
				};
			}
		}

		this.setState({ nCols: value, boxes });

		const tmpState = this.state;
		tmpState.nCols = value;
		tmpState.boxes = boxes;
		this.updateModel(JSON.stringify(tmpState));
	}

	changeGrid() {
		const { boxes, gridVisible } = this.state;
		for (const box of Object.keys(boxes)) {
			document.getElementById(box).style.border = (gridVisible) ? '' : '1px solid #f1f2f2';
		}

		this.setState({ gridVisible: !gridVisible });

		const tmpState = this.state;
		tmpState.gridVisible = !gridVisible;
		this.updateModel(JSON.stringify(tmpState));
	}

	gridClick(box) {
		const { boxes } = this.state;
		const rot = boxes[box].rotation + 90;
		boxes[box].rotation = rot % 360;
		this.setState({ boxes, compDragging: null });

		const tmpState = this.state;
		tmpState.boxes = boxes;
		tmpState.compDragging = null;
		this.updateModel(JSON.stringify(tmpState));
	}

	changeCategoryState(cat) {
		const { openCategory } = this.state;
		if (openCategory === cat) {
			this.setState({ openCategory: null });
		} else {
			this.setState({ openCategory: cat });
		}
	}

	calculateObstacles() {
		const { boxes } = this.state;
		const walls = [];

		const mapPos = document.querySelector('#map').getBoundingClientRect();
		let nextId = 1;
		for (const box of Object.keys(boxes)) {
			if (boxes[box].content !== null) {
				const boxPos = document.getElementById(box).getBoundingClientRect();
				for (const wall of categories[boxes[box].contentCat].components[boxes[box].contentId].walls) {
					walls.push(transformWall(wall, boxPos, boxes[box].rotation, mapPos, nextId));
					nextId += 1;
				}
			}
		}

		return walls;
	}

	calculateWaters() {
		const { boxes } = this.state;
		const waters = [];

		const mapPos = document.querySelector('#map').getBoundingClientRect();
		let nextId = 1;
		for (const box of Object.keys(boxes)) {
			if (boxes[box].content !== null) {
				const boxPos = document.getElementById(box).getBoundingClientRect();
				for (const water of categories[boxes[box].contentCat].components[boxes[box].contentId].waters) {
					waters.push(transformWater(water, boxPos, boxes[box].rotation, mapPos, nextId));
					nextId += 1;
				}
			}
		}

		return waters;
	}

	render() {
		const { nRows, nCols, boxes, gridVisible, mapWidth, mapHeight, openCategory, dragging, draggingCat, draggedItem } = this.state;

		const elementDimension = getElementDimension(mapWidth, mapHeight, nRows, nCols);

		const grid = [];
		for (const box of Object.keys(boxes)) {
			grid.push(
				<Draggable
					key={box}
					allowAnyClick={false}
					position={{ x: 0, y: 0 }}
					onStart={this.onCompStart}
					onDrag={(event) => { this.onCompDrag(event, box); }}
					onStop={(event) => { this.onCompStop(event, box); }}
				>
					<div
						key={box}
						id={box}
						style={{
							display: 'flex', width: elementDimension, height: elementDimension, border: (gridVisible) ? '1px solid #f1f2f2' : '', position: 'absolute', left: ((mapWidth - nCols * elementDimension) / 2 + (boxes[box].c - 1) * elementDimension), top: ((mapHeight - nRows * elementDimension) / 2 + (boxes[box].r - 1) * elementDimension), cursor: 'move', justifyContent: 'center',
						}}
					>
						<img src={boxes[box].content} alt="" style={{ maxWidth: '100%', maxHeight: '100%', transform: `rotate(${boxes[box].rotation}deg)` }} />
					</div>
				</Draggable>,
			);
		}

		return ([
			<StyledBox key="mainbox">
				<StyledArea>
					<MenuRow>
						<Typography mr={2}>{"Rows"}</Typography>
						<PrettoSlider
							aria-label="Always visible"
							value={nRows}
							color="secondary"
							min={3}
							max={10}
							step={1}
							sx={{
								width: "200px",
							}}
							valueLabelDisplay="auto"
							onChange={(event) => this.changeNumberRows(event.target.value)}
						/>
						<Typography mr={2} ml={2}>{"Columns"}</Typography>
						<PrettoSlider
							aria-label="Always visible"
							value={nCols}
							color="secondary"
							min={3}
							max={15}
							step={1}
							sx={{
								width: "200px",
							}}
							valueLabelDisplay="auto"
							onChange={(event) => this.changeNumberCols(event.target.value)}
						/>
						<Typography ml={2}>{`(${nRows}x${nCols})`}</Typography>
						<Typography mr={1} ml={2}>{"Grid"}</Typography>
						<GridSetting key="visibility-switch">
							<Switch large className="grid-visible" checked={gridVisible} onChange={this.changeGrid} />
						</GridSetting>
					</MenuRow>
					<MainRow>
						<MainMap id="mainmap">
							<Map id="map" mapWidth={mapWidth} mapHeight={mapHeight}>
								{grid}
							</Map>
						</MainMap>
						<div
							style={{
								display: ((dragging === null) ? 'none' : 'flex'), width: '80px', height: '80px', opacity: 0.6, position: 'absolute', left: `${draggedItem.x - 40}px`, top: `${draggedItem.y - 120}px`, cursor: 'move', zIndex: 1,
							}}
						>
							{dragging !== null
								&& <img src={categories[draggingCat].components[dragging].image} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />}
						</div>
						<Menu>
							{Object.keys(categories).map((cat, ind) => (
								<React.Fragment key={cat}>
									<Button
										key={`${cat}_int`}
										color="secondary"
										variant="contained"
										style={{
											width: "100%",
											marginBottom: "10px",
											marginTop: "10px",
										}}
										id={cat}
										onClick={() => { this.changeCategoryState(cat); }}
									>
										{categories[cat].name}
									</Button>
									<Collapse key={`${cat}_collapse`} isOpen={openCategory === cat}>
										<CategoryDiv style={{ background: 'transparent' }}>
											{Object.keys(categories[cat].components).map((comp) => (
												<React.Fragment key={`${cat}_${comp}_fragment`}>
													<Draggable
														allowAnyClick={false}
														position={{ x: 0, y: 0 }}
														onStart={this.onStart}
														onDrag={(event) => { this.onDrag(event, comp, cat); }}
														onStop={this.onStop}
													>
														<DragComp style={{ display: (dragging === comp) ? 'none' : 'flex' }}>
															<img src={categories[cat].components[comp].image} alt={comp} draggable={false} style={{ maxWidth: '100%', maxHeight: '100%' }} />
														</DragComp>
													</Draggable>
													<EmptyComp style={{ display: (dragging === comp) ? 'flex' : 'none' }} />
												</React.Fragment>
											))}
										</CategoryDiv>
									</Collapse>
								</React.Fragment>
							))}
						</Menu>
					</MainRow>
				</StyledArea>
			</StyledBox>,
		]);
	}
}

export default memo(CreateMap);

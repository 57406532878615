
/* eslint-disable max-len */

import {
	EditableText, Menu, NumericInput, Popover, Tooltip,
} from '@blueprintjs/core';
import { faTrash, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';
import { ToasterBottom } from '../../../lib_socioty/toaster';
import alarmStart from '../../../../assets/images/missionItems/sensors/alarmStart.png';
import alarmEnd from '../../../../assets/images/missionItems/sensors/alarmEnd.png';
import { GreenBorderButton } from '../../../lib_socioty/buttons';

import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const PrettoSlider = styled(Slider)({
	color: '#52af77',
	height: 8,
	'& .MuiSlider-track': {
		border: 'none',
	},
	'& .MuiSlider-thumb': {
		height: 24,
		width: 24,
		backgroundColor: '#fff',
		border: '2px solid currentColor',
		'&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
			boxShadow: 'inherit',
		},
		'&:before': {
			display: 'none',
		},
	},
});

const AlarmDiv = styled.div`
	width: ${(props) => `${Math.sqrt(((props.x2 - props.x1) ** 2) + ((props.y2 - props.y1) ** 2))}px`};
	height: 2px;
	background: red;
	transform: ${(props) => `rotate(${-1 * ((Math.atan2(props.y2 - props.y1, props.x2 - props.x1) * 180) / Math.PI)}deg)`};
	transform-origin: left center;
	z-index: -1;
`;

const AlarmOut = styled.div`
	display: ${(props) => ((props.draggingLeft || props.draggingRight) ? 'none' : 'block')};
	position: absolute;
	left: ${(props) => `${props.x1}px`};
	bottom: ${(props) => `${props.y1 - 1}px`};
	cursor: pointer;
	height: 2px;
`;

const ItemDiv = styled.div`
	width: ${(props) => `${props.elementDimensions}px`};
	height: ${(props) => `${props.elementDimensions}px`};
	border-radius: ${(props) => `${props.elementDimensions}px`};
	position: absolute;
	left: ${(props) => `${props.x - (props.elementDimensions / 2)}px`};
	bottom: ${(props) => `${props.y - (props.elementDimensions / 2)}px`};
	cursor: pointer;
	display: flex;
	z-index: 0;
	transform: ${(props) => (props.dragging ? '' : 'none!important')};
`;

const BasicPopover = ({ className, elementDimensions, popoverOpen, closePopover, changeName, checkName, itemName, changeHz, hz, mode, changeMode, deleteItem }) => (
	<Popover usePortal popoverClassName={`${className} item-popover`} elementDimensions={elementDimensions} isOpen={popoverOpen}>
		<div style={{
			width: '300px', padding: '10px', color: 'white', background: 'black', boxShadow: '4px 4px 10px 1px #333333',
		}}
		>
			{/* Name */}
			<div style={{
				width: '100%', maxWidth: '100%', paddingLeft: '1px', paddingRight: '1px', display: 'flex', justifyContent: 'space-around',
			}}
			>
				<TextField
					value={itemName}
					label="Name"
					variant="filled"
					sx={{
						backgroundColor: '#aaa',
						mx: 1,
						width: '100%',
					}}
					size="small"
					color="success"
					onChange={(event) => changeName(event.target.value)}
				/>
			</div>

			{/* Hz */}
			<div style={{
				width: '100%', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "10px",
			}}
			>
				<Typography color="#ffffff" variant="caption" mr={1}>
					{`Hz (${hz})`}
				</Typography>
				<PrettoSlider
					aria-label="Always visible"
					value={hz}
					color="secondary"
					min={0}
					max={10}
					step={1}
					sx={{
						width: "150px",
					}}
					onChange={(event) => changeHz(event.target.value)}
				/>
			</div>

			{/* Mode */}
			<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
				<FormControl error sx={{ m: 1, width: "100%" }}>
					<Select
						value={mode}
						label="Mode"
						sx={{
							backgroundColor: 'gray',
						}}
						size="small"
					>
						{['mock', 'simulation'].map((m) => (
							<MenuItem key={Math.random()} value={m} onClick={() => changeMode(m)}>
								{`Mode: ${m.charAt(0).toUpperCase()}${m.slice(1)}`}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>

			<div style={{
				width: '100%', height: '20px', display: 'flex', justifyContent: 'space-around', marginTop: '10px',
			}}
			>
				<FontAwesomeIcon icon={faTrash} style={{ fontSize: '20px', cursor: 'pointer' }} onClick={deleteItem} />
				<FontAwesomeIcon icon={faXmarkCircle} style={{ fontSize: '20px', cursor: 'pointer' }} onClick={closePopover} />
			</div>
		</div>
	</Popover>
);

const CustomPopover = styled(BasicPopover)`
	transform: ${(props) => `translate(${(props.x2n - props.x1n) / 2}px, ${-(props.y2n - props.y1n) / 2}px)`};
	border-radius: 10px;
`;

class LinearAlarm extends React.Component {
	constructor(props) {
		super(props);

		const initialState = props.state;
		this.cat = initialState.cat;
		this.itemCat = initialState.itemCat;
		this.updateItem = props.updateItem;
		this.deleteItem = props.deleteItem;
		this.checkItemNameExists = props.checkItemNameExists;

		let x1; let y1; let x2; let y2;
		if ('x' in initialState) {
			x1 = initialState.x - (props.elementDimensions / props.mapWidth) * 100;
			if (x1 < 0) {
				x1 = 0;
			}

			y1 = initialState.y;
			x2 = initialState.x + (props.elementDimensions / props.mapWidth) * 100;
			if (x2 > 100) {
				x2 = 100;
			}

			y2 = initialState.y;
		} else {
			x1 = initialState.x1;
			y1 = initialState.y1;
			x2 = initialState.x2;
			y2 = initialState.y2;
		}

		this.state = {
			id: initialState.id,
			itemName: initialState.itemName,
			x1,
			y1,
			x2,
			y2,
			hz: initialState.hz,
			mode: initialState.mode,
			elementDimensions: props.elementDimensions,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
			draggingLeft: false,
			draggingRight: false,
			popoverOpen: false,
			resolution: props.resolution,
			metersLeftPopupOpen: false,
			metersRightPopupOpen: false,
			xMeters: 0,
			yMeters: 0,
		};
		this.interval = null;
		this.initialName = initialState.itemName;

		this.onLeftStart = this.onLeftStart.bind(this);
		this.onLeftDrag = this.onLeftDrag.bind(this);
		this.onLeftStop = this.onLeftStop.bind(this);
		this.onRightStart = this.onRightStart.bind(this);
		this.onRightDrag = this.onRightDrag.bind(this);
		this.onRightStop = this.onRightStop.bind(this);
		this.onClick = this.onClick.bind(this);
		this.delete = this.delete.bind(this);
		this.sendUpdate = this.sendUpdate.bind(this);
		this.changeName = this.changeName.bind(this);
		this.checkName = this.checkName.bind(this);
		this.changeHz = this.changeHz.bind(this);
		this.changeMode = this.changeMode.bind(this);
		this.transformDimensions = this.transformDimensions.bind(this);
	}

	componentDidMount() {
		const { itemName, x1, y1, x2, y2, hz, mode } = this.state;
		this.sendUpdate(itemName, x1, y1, x2, y2, hz, mode);
	}

	static getDerivedStateFromProps(props) {
		const initialState = props.state;
		let x1; let y1; let x2; let y2;
		if ('x' in initialState) {
			x1 = initialState.x - (props.elementDimensions / props.mapWidth) * 100;
			if (x1 < 0) {
				x1 = 0;
			}

			y1 = initialState.y;
			x2 = initialState.x + (props.elementDimensions / props.mapWidth) * 100;
			if (x2 > 100) {
				x2 = 100;
			}

			y2 = initialState.y;
		} else {
			x1 = initialState.x1;
			y1 = initialState.y1;
			x2 = initialState.x2;
			y2 = initialState.y2;
		}

		return {
			id: initialState.id,
			itemName: initialState.itemName,
			x1,
			y1,
			x2,
			y2,
			hz: initialState.hz,
			mode: initialState.mode,
			elementDimensions: props.elementDimensions,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
			resolution: props.resolution,
		};
	}

	onLeftStart(event) {
		event.preventDefault();
		const { x1, y1, mapWidth, mapHeight, resolution } = this.state;
		const xMeters = ((x1 / 100) * mapWidth) * resolution;
		const yMeters = ((y1 / 100) * mapHeight) * resolution;
		this.setState({ metersLeftPopupOpen: true, xMeters, yMeters });
	}

	onLeftDrag(event, data) {
		event.preventDefault();
		const { x1, y1, mapWidth, mapHeight, resolution } = this.state;
		const xMeters = (((x1 / 100) * mapWidth) + data.x) * resolution;
		const yMeters = (((y1 / 100) * mapHeight) - data.y) * resolution;
		this.setState({ draggingLeft: true, xMeters, yMeters });
	}

	onLeftStop(event, data) {
		event.preventDefault();
		const { draggingLeft } = this.state;
		this.setState({ draggingLeft: false, metersLeftPopupOpen: false });
		if (!draggingLeft) {
			this.onClick();
		}

		const { itemName, x1, y1, x2, y2, hz, mode, mapWidth, mapHeight } = this.state;
		let newX = ((((x1 / 100) * mapWidth) + data.x) / mapWidth) * 100;
		if (newX > 100) {
			newX = 100;
		}

		if (newX < 0) {
			newX = 0;
		}

		let newY = ((((y1 / 100) * mapHeight) - data.y) / mapHeight) * 100;
		if (newY > 100) {
			newY = 100;
		}

		if (newY < 0) {
			newY = 0;
		}

		this.sendUpdate(itemName, newX, newY, x2, y2, hz, mode);
	}

	onRightStart(event) {
		event.preventDefault();
		const { x2, y2, mapWidth, mapHeight, resolution } = this.state;
		const xMeters = ((x2 / 100) * mapWidth) * resolution;
		const yMeters = ((y2 / 100) * mapHeight) * resolution;
		this.setState({ metersRightPopupOpen: true, xMeters, yMeters });
	}

	onRightDrag(event, data) {
		event.preventDefault();
		const { x2, y2, mapWidth, mapHeight, resolution } = this.state;
		const xMeters = (((x2 / 100) * mapWidth) + data.x) * resolution;
		const yMeters = (((y2 / 100) * mapHeight) - data.y) * resolution;
		this.setState({ draggingRight: true, xMeters, yMeters });
	}

	onRightStop(event, data) {
		event.preventDefault();
		const { draggingRight } = this.state;
		this.setState({ draggingRight: false, metersRightPopupOpen: false });
		if (!draggingRight) {
			this.onClick();
		}

		const { itemName, x1, y1, x2, y2, hz, mode, mapWidth, mapHeight } = this.state;
		let newX = ((((x2 / 100) * mapWidth) + data.x) / mapWidth) * 100;
		if (newX > 100) {
			newX = 100;
		}

		if (newX < 0) {
			newX = 0;
		}

		let newY = ((((y2 / 100) * mapHeight) - data.y) / mapHeight) * 100;
		if (newY > 100) {
			newY = 100;
		}

		if (newY < 0) {
			newY = 0;
		}

		this.sendUpdate(itemName, x1, y1, newX, newY, hz, mode);
	}

	onClick() {
		this.setState({ popoverOpen: true });
	}

	delete() {
		const { id } = this.state;
		this.deleteItem(this.cat, this.itemCat, id);
	}

	sendUpdate(itemName, x1, y1, x2, y2, hz, mode) {
		const { id } = this.state;
		this.updateItem(this.cat, this.itemCat, id, {
			id, itemName, x1, y1, x2, y2, hz, mode, cat: this.cat, itemCat: this.itemCat,
		});
	}

	changeName(value) {
		const { x1, y1, x2, y2, hz, mode } = this.state;
		this.sendUpdate(value.toLowerCase().split(' ').join('_'), x1, y1, x2, y2, hz, mode);
	}

	checkName(value) {
		const { id } = this.state;
		const { matchesPattern, found } = this.checkItemNameExists(value, this.initialName, this.cat, this.itemCat, id);
		if (matchesPattern) {
			ToasterBottom.show({
				intent: 'danger',
				message: 'Name cannot follow the template category_number',
			});
			const { x1, y1, x2, y2, hz, mode } = this.state;
			this.sendUpdate(this.initialName, x1, y1, x2, y2, hz, mode);
		} else if (found) {
			ToasterBottom.show({
				intent: 'danger',
				message: 'Name already exists',
			});
			const { x1, y1, x2, y2, hz, mode } = this.state;
			this.sendUpdate(this.initialName, x1, y1, x2, y2, hz, mode);
		}
	}

	changeHz(hz) {
		const { itemName, x1, y1, x2, y2, mode } = this.state;
		this.sendUpdate(itemName, x1, y1, x2, y2, hz, mode);
	}

	changeMode(mode) {
		const { itemName, x1, y1, x2, y2, hz } = this.state;
		this.sendUpdate(itemName, x1, y1, x2, y2, hz, mode);
	}

	transformDimensions() {
		const { x1, y1, x2, y2, mapWidth, mapHeight } = this.state;
		const x1n = (x1 / 100) * mapWidth;
		const y1n = (y1 / 100) * mapHeight;
		const x2n = (x2 / 100) * mapWidth;
		const y2n = (y2 / 100) * mapHeight;
		return { x1n, y1n, x2n, y2n };
	}

	render() {
		const { id, itemName, hz, mode, elementDimensions, draggingLeft, draggingRight, popoverOpen, metersLeftPopupOpen, metersRightPopupOpen, xMeters, yMeters } = this.state;

		const { x1n, y1n, x2n, y2n } = this.transformDimensions();

		return ([
			<AlarmOut key={`linearAlarm_${id}`} x1={x1n} y1={y1n} draggingLeft={draggingLeft} draggingRight={draggingRight}>
				<AlarmDiv id={`linearAlarm_${id}`} x1={x1n} y1={y1n} x2={x2n} y2={y2n} />
				{popoverOpen && (
					<CustomPopover
						x1n={x1n}
						y1n={y1n}
						x2n={x2n}
						y2n={y2n}
						elementDimensions={elementDimensions}
						popoverOpen={popoverOpen}
						closePopover={() => { this.setState({ popoverOpen: false }); }}
						changeName={this.changeName}
						checkName={this.checkName}
						itemName={itemName}
						changeHz={this.changeHz}
						hz={hz}
						mode={mode}
						changeMode={this.changeMode}
						deleteItem={this.delete}
					/>
				)}
			</AlarmOut>,
			<Draggable
				key={`linearAlarmLeft_${id}`}
				allowAnyClick={false}
				grid={[10, 10]}
				position={{ x: 0, y: 0 }}
				handle=".itemhandler"
				onStart={this.onLeftStart}
				onDrag={this.onLeftDrag}
				onStop={this.onLeftStop}
			>
				<ItemDiv id={`item_${id}`} x={x1n} y={y1n - 1} elementDimensions={elementDimensions}>
					<Tooltip
						popoverClassName="item-info-tooltip"
						disabled={draggingLeft || draggingRight || popoverOpen}
						content={(
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									textAlign: 'center',
									maxWidth: '200px',
									backgroundColor: "rgba(0, 0, 0, 0.8)",
									padding: '10px',
									borderRadius: '10px',
									color: 'white',
								}}
							>
								<div style={{ fontWeight: 'bold', fontSize: '16px' }}>
									{"Hz"}
								</div>
								<div style={{ textAlign: 'center', height: 'fit-content', wordBreak: 'break-word' }}>
									{`${hz}hz`}
								</div>
								<div style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '10px' }}>
									{"Mode"}
								</div>
								<div style={{ textAlign: 'center', height: 'fit-content', wordBreak: 'break-word' }}>
									{mode}
								</div>
							</div>
						)}
						interactionKind="hover"
					>
						<img
							key={Math.random()}
							id={`itemImg_${id}`}
							className="itemhandler"
							src={alarmStart}
							alt=""
							draggable={false}
							style={{ maxWidth: '100%', maxHeight: '100%' }}
						/>
					</Tooltip>
					<div
						style={{
							display: (metersLeftPopupOpen) ? 'block' : 'none', width: '145px', height: '20px', position: 'absolute', top: `-${(elementDimensions / 2) + 5}px`, left: `${(elementDimensions / 2) - (145 / 2)}px`, fontSize: '14px', textAlign: 'center', background: '#7a8585', borderRadius: '10px', color: 'white',
						}}
					>
						{`x: ${xMeters.toFixed(3)}m, y: ${yMeters.toFixed(3)}m`}
					</div>
				</ItemDiv>
			</Draggable>,
			<Draggable
				key={`linearAlarmRight_${id}`}
				allowAnyClick={false}
				grid={[10, 10]}
				position={{ x: 0, y: 0 }}
				handle=".itemhandler"
				onStart={this.onRightStart}
				onDrag={this.onRightDrag}
				onStop={this.onRightStop}
			>
				<ItemDiv id={`item_${id}`} x={x2n} y={y2n - 1} elementDimensions={elementDimensions}>
					<Tooltip
						popoverClassName="item-info-tooltip"
						disabled={draggingLeft || draggingRight || popoverOpen}
						content={(
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									textAlign: 'center',
									maxWidth: '200px',
									backgroundColor: "rgba(0, 0, 0, 0.8)",
									padding: '10px',
									borderRadius: '10px',
									color: 'white',
								}}
							>
								<div style={{ fontWeight: 'bold', fontSize: '16px' }}>
									{"Hz"}
								</div>
								<div style={{ textAlign: 'center', height: 'fit-content', wordBreak: 'break-word' }}>
									{`${hz}hz`}
								</div>
								<div style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '10px' }}>
									{"Mode"}
								</div>
								<div style={{ textAlign: 'center', height: 'fit-content', wordBreak: 'break-word' }}>
									{mode}
								</div>
							</div>
						)}
						interactionKind="hover"
					>
						<img
							key={Math.random()}
							id={`itemImg_${id}`}
							className="itemhandler"
							src={alarmEnd}
							alt=""
							draggable={false}
							style={{ maxWidth: '100%', maxHeight: '100%' }}
						/>
					</Tooltip>
					<div
						style={{
							display: (metersRightPopupOpen) ? 'block' : 'none', width: '145px', height: '20px', position: 'absolute', top: `-${(elementDimensions / 2) + 5}px`, left: `${(elementDimensions / 2) - (145 / 2)}px`, fontSize: '14px', textAlign: 'center', background: '#7a8585', borderRadius: '10px', color: 'white',
						}}
					>
						{`x: ${xMeters.toFixed(3)}m, y: ${yMeters.toFixed(3)}m`}
					</div>
				</ItemDiv>
			</Draggable>,
		]);
	}
}

const createLinearAlarm = (state, resolution, elementDimensions, mapWidth, mapHeight, updateItem, deleteItem, checkItemNameExists) => (
	<LinearAlarm key={state.id} state={state} resolution={resolution} elementDimensions={elementDimensions} mapWidth={mapWidth} mapHeight={mapHeight} updateItem={updateItem} deleteItem={deleteItem} checkItemNameExists={checkItemNameExists} />
);

export default createLinearAlarm;

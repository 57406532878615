/* eslint-disable max-len */
/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import styled from 'styled-components';
import { Button, Text } from '@blueprintjs/core';
import { GreenBorderButton, GreenButton } from '../lib_socioty/buttons';
import { ToasterBottom } from '../lib_socioty/toaster';
// import {getMaps} from '../../api/maps';
// import {getEditMissions} from '../../api/missions';

const MainBox = styled.div`
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    display: flex;
`;

const MainMap = styled.div`
    width: calc(100% - 250px);
    height: 100%;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
`;

const Map = styled.div`
    width: ${(props) => `${props.mapWidth}px`};
    height: ${(props) => `${props.mapHeight}px`};
    background: white;
    position: relative;
    margin: auto;
`;

const Menu = styled.div`
    width: 250px;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
`;

const StyledIcon = styled.img.attrs((props) => ({ src: props.src }))`
    width: 150px;
    height: 100px;
    margin-bottom: 5;
    flex-direction: column;
`;

const StyledText = styled(Text)`
    color: #595959;
    text-align: center;
    font-weight: 550;
`;

const MapButton = styled(Button)`
    width: 170px;
    height: 140px;
    text-align: center;
    padding: 10px;
`;

const getMapDimensions = (mainMapWidth, mainMapHeight) => {
	const availWidth = mainMapWidth;
	const availHeight = mainMapHeight;
	const initRatio = mainMapWidth / mainMapHeight;
	const ratio = 1.5;

	if (initRatio === ratio) {
		return { width: availWidth, height: availHeight };
	}

	if (initRatio > ratio) {
		return { width: ratio * availHeight, height: availHeight };
	}

	return { width: availWidth, height: availWidth / ratio };
};

const ButtonWithText = ({ text, src, handler }) => (
	<MapButton minimal onClick={handler}>
		<StyledIcon src={src} />
		<StyledText>{text}</StyledText>
	</MapButton>
);

export class OpenMap extends React.Component {
	constructor(props) {
		super(props);

		this.changeSpinner = props.changeSpinner;
		this.deleteMap = props.deleteMap;
		this.selectMap = props.selectMap;
		this.setMapDimensions = props.setMapDimensions;
		this.selectMission = props.selectMission;
		this.changeGridDimension = props.changeGridDimension;

		this.state = {
			display: props.display,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
			mapWidthM: props.mapWidthM,
			availableMaps: [],
			gridMeters: props.gridMeters,
			selectedMap: props.selectedMap,
			mapSelectionMode: false,
			missionSelectionMode: false,
			availableMissions: [],
		};

		this.firstChangeMapDimensions = this.firstChangeMapDimensions.bind(this);
		this.changeMapDimensions = this.changeMapDimensions.bind(this);
		this.openMap = this.openMap.bind(this);
		this.mapSelected = this.mapSelected.bind(this);
		this.fetchMissions = this.fetchMissions.bind(this);
		this.missionSelected = this.missionSelected.bind(this);
	}

	componentDidMount() {
		const { display } = this.state;
		if (display) {
			this.firstChangeMapDimensions();
			window.addEventListener('resize', this.changeMapDimensions);
		}
	}

	static getDerivedStateFromProps(props) {
		return {
			display: props.display,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
			mapWidthM: props.mapWidthM,
			gridMeters: props.gridMeters,
			selectedMap: props.selectedMap,
		};
	}

	componentDidUpdate(prevProps) {
		const { display } = this.props;
		if (display !== prevProps.display) {
			this.setState({ mapSelectionMode: false, missionSelectionMode: false }, () => {
				if (display) {
					this.firstChangeMapDimensions();
					window.addEventListener('resize', this.changeMapDimensions);
				} else {
					window.removeEventListener('resize', this.changeMapDimensions);
				}
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.changeMapDimensions);
	}

	firstChangeMapDimensions() {
		const mainMapWidth = document.querySelector('#openmap-mainmap').offsetWidth;
		const mainMapHeight = document.querySelector('#openmap-mainmap').offsetHeight;
		const dimensions = getMapDimensions(mainMapWidth, mainMapHeight);
		this.setMapDimensions(dimensions.width, dimensions.height, true);
	}

	changeMapDimensions() {
		const { mapWidthM, gridMeters } = this.state;
		const mainMapWidth = document.querySelector('#openmap-mainmap').offsetWidth;
		const mainMapHeight = document.querySelector('#openmap-mainmap').offsetHeight;
		const dimensions = getMapDimensions(mainMapWidth, mainMapHeight);
		const gridDimension = (gridMeters * dimensions.width) / mapWidthM;
		this.setMapDimensions(dimensions.width, dimensions.height);
		this.changeGridDimension(gridDimension);
	}

	async openMap() {
		this.changeSpinner(true);
		// const response = await getMaps();
		// if (response.success) {
		//     this.setState({
		//         availableMaps: response.maps,
		//         mapSelectionMode: true,
		//         missionSelectionMode: false
		//     });
		// } else {
		//     ToasterBottom.show({
		//         intent: 'danger',
		//         message: response.message || 'There was a problem trying to fetch the available maps'
		//     });
		// }
		this.changeSpinner(false);
	}

	mapSelected(map) {
		this.selectMap(map);
		this.setState({ mapSelectionMode: false, missionSelectionMode: false });
	}

	async fetchMissions() {
		this.changeSpinner(true);
		// const response = await getEditMissions();
		// if (response.success) {
		//     this.setState({
		//         availableMissions: response.missions,
		//         missionSelectionMode: true,
		//         mapSelectionMode: false
		//     });
		//     if (response.missions.length === 0) {
		//         ToasterBottom.show({
		//             intent: 'danger',
		//             message: 'There are no available missions to load'
		//         });
		//         this.setState({missionSelectionMode: false});
		//     }
		// } else {
		//     ToasterBottom.show({
		//         intent: 'danger',
		//         message: response.message || 'There was a problem trying to fetch the available missions'
		//     });
		// }
		this.changeSpinner(false);
	}

	missionSelected(mission) {
		this.selectMission(mission);
		this.setState({ missionSelectionMode: false });
	}

	render() {
		const { display, mapWidth, mapHeight, availableMaps, selectedMap, mapSelectionMode, availableMissions, missionSelectionMode } = this.state;

		return (
			<MainBox style={{ display: (display) ? 'flex' : 'none' }}>
				<MainMap id="openmap-mainmap">
					<Map id="openmap-map" mapWidth={mapWidth} mapHeight={mapHeight}>
						{!mapSelectionMode && !missionSelectionMode && selectedMap.name !== null
                        && (
	<img src={selectedMap.map} alt="" style={{ width: '100%', height: '100%' }} />
                        )}
						{mapSelectionMode
                        && (
	<div style={{
                        		width: '100%', height: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexWrap: 'wrap', padding: '20px', overflow: 'auto',
                        	}}
                        	>
	{availableMaps.map((m) => (
	<ButtonWithText key={m.name} text={m.name} src={m.map} handler={() => this.mapSelected(m)} />
                        		))}
                        	</div>
                        )}
						{missionSelectionMode
                        && (
	<div style={{
                        		width: '100%', height: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexWrap: 'wrap', padding: '20px', overflow: 'auto',
                        	}}
                        	>
	{availableMissions.map((m) => (
	<ButtonWithText key={m.name} text={m.name} src={(m.map.length > 0) ? m.map[0].map : null} handler={() => this.missionSelected(m)} />
                        		))}
                        	</div>
                        )}
					</Map>
				</MainMap>
				<Menu>
					<GreenButton width="100%" height="40px" onClick={this.openMap}>
						{"Open Map"}
					</GreenButton>
					<GreenBorderButton width="100%" height="40px" marginTop="15px" onClick={this.deleteMap}>
						{"Delete Map"}
					</GreenBorderButton>
					<GreenButton width="100%" height="40px" marginTop="15px" onClick={this.fetchMissions}>
						{"Load Mission"}
					</GreenButton>
				</Menu>
			</MainBox>
		);
	}
}

export default OpenMap;

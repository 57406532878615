
/* eslint-disable max-len */

/* eslint-disable react/no-did-update-set-state */
import { Popover } from '@blueprintjs/core';
import { faTrash, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';

const WallDiv = styled.div`
	width: ${(props) => `${Math.sqrt(((props.x2 - props.x1) ** 2) + ((props.y2 - props.y1) ** 2))}px`};
	height: 10px;
	background: #3c9764;
	transform: ${(props) => `rotate(${-1 * ((Math.atan2(props.y2 - props.y1, props.x2 - props.x1) * 180) / Math.PI)}deg)`};
	transform-origin: left center;
	z-index: -1;
`;

const WallOutDiv = styled.div`
	display: ${(props) => ((props.draggingLeft || props.draggingRight) ? 'none' : 'block')};
	position: absolute;
	left: ${(props) => `${props.x1}px`};
	bottom: ${(props) => `${props.y1 - 5}px`};
	cursor: ${(props) => ((props.dragging) ? 'move' : 'pointer')};
	height: 10px;
`;

const EdgeHandler = styled.div`
	display: ${(props) => ((props.dragging) ? 'none' : 'block')};
	position: absolute;
	left: ${(props) => `${props.x}px`};
	bottom: ${(props) => `${props.y}px`};
	cursor: e-resize;
`;

const EdgeContent = styled.div`
	display: ${(props) => ((props.dragging) ? 'none' : 'block')};
	width: 8px;
	height: 10px;
	background: #0B3F5D;
	border-top-left-radius: ${(props) => ((props.left) ? '10px' : '0px')};
	border-bottom-left-radius: ${(props) => ((props.left) ? '10px' : '0px')};
	border-top-right-radius: ${(props) => ((props.left) ? '0px' : '10px')};
	border-bottom-right-radius: ${(props) => ((props.left) ? '0px' : '10px')};
	border: 2px solid #3c9764;
	transform: ${(props) => `rotate(${-1 * ((Math.atan2(props.y2 - props.y1, props.x2 - props.x1) * 180) / Math.PI)}deg) translateX(-4px)`};
	transform-origin: left center;
`;

const BasicPopover = ({ className, x1n, y1n, x2n, y2n, popoverOpen, closePopover, deleteItem }) => (
	<Popover usePortal popoverClassName={`${className} item-popover`} x1={x1n} y1={y1n} x2={x2n} y2={y2n} isOpen={popoverOpen}>
		<div style={{
			width: '80px', height: '40px', padding: '10px', color: 'white', background: 'black',
		}}
		>
			<div style={{ width: '100%', height: '20px', display: 'flex', justifyContent: 'space-around' }}>
				<FontAwesomeIcon icon={faTrash} style={{ fontSize: '20px', cursor: 'pointer', marginRight: "10px" }} onClick={deleteItem} />
				<FontAwesomeIcon icon={faXmarkCircle} style={{ fontSize: '20px', cursor: 'pointer' }} onClick={closePopover} />
			</div>
		</div>
	</Popover>
);

const CustomPopover = styled(BasicPopover)`
	transform: ${(props) => `translate(${(props.x2n - props.x1n) / 2}px, ${-(props.y2n - props.y1n) / 2}px)`};
	border-radius: 10px;
`;

class Wall extends React.Component {
	constructor(props) {
		super(props);

		const initialState = props.state;
		this.cat = initialState.cat;
		this.itemCat = initialState.itemCat;
		this.updateItem = props.updateItem;
		this.deleteItem = props.deleteItem;

		let x1; let y1; let x2; let y2;
		if ('x' in initialState) {
			x1 = initialState.x - (props.elementDimensions / props.mapWidth) * 100;
			if (x1 < 0) {
				x1 = 0;
			}

			y1 = initialState.y;
			x2 = initialState.x + (props.elementDimensions / props.mapWidth) * 100;
			if (x2 > 100) {
				x2 = 100;
			}

			y2 = initialState.y;
		} else {
			x1 = initialState.x1;
			y1 = initialState.y1;
			x2 = initialState.x2;
			y2 = initialState.y2;
		}

		this.state = {
			id: initialState.id,
			itemName: initialState.itemName,
			x1,
			y1,
			x2,
			y2,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
			dragging: false,
			popoverOpen: false,
			draggedLeft: { x: 0, y: 0 },
			draggingLeft: false,
			draggedRight: { x: 0, y: 0 },
			draggingRight: false,
		};

		this.onStart = this.onStart.bind(this);
		this.onDrag = this.onDrag.bind(this);
		this.onStop = this.onStop.bind(this);
		this.onLeftStart = this.onLeftStart.bind(this);
		this.onLeftDrag = this.onLeftDrag.bind(this);
		this.onLeftStop = this.onLeftStop.bind(this);
		this.onRightStart = this.onRightStart.bind(this);
		this.onRightDrag = this.onRightDrag.bind(this);
		this.onRightStop = this.onRightStop.bind(this);
		this.onClick = this.onClick.bind(this);
		this.delete = this.delete.bind(this);
		this.transformDimensions = this.transformDimensions.bind(this);
		this.transformEdgeDimensions = this.transformEdgeDimensions.bind(this);
		this.sendUpdate = this.sendUpdate.bind(this);
	}

	componentDidMount() {
		const { x1, y1, x2, y2 } = this.state;
		this.sendUpdate(x1, y1, x2, y2);
	}

	static getDerivedStateFromProps(props) {
		const initialState = props.state;
		let x1; let y1; let x2; let y2;
		if ('x' in initialState) {
			x1 = initialState.x - (props.elementDimensions / props.mapWidth) * 100;
			if (x1 < 0) {
				x1 = 0;
			}

			y1 = initialState.y;
			x2 = initialState.x + (props.elementDimensions / props.mapWidth) * 100;
			if (x2 > 100) {
				x2 = 100;
			}

			y2 = initialState.y;
		} else {
			x1 = initialState.x1;
			y1 = initialState.y1;
			x2 = initialState.x2;
			y2 = initialState.y2;
		}

		return {
			id: initialState.id,
			itemName: initialState.itemName,
			x1,
			y1,
			x2,
			y2,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
		};
	}

	componentDidUpdate(prevProps) {
		const { state, mapWidth, mapHeight } = this.props;

		for (const it of ['id', 'x1', 'y1', 'x2', 'y2']) {
			if (state[it] !== prevProps.state[it]) {
				this.setState({ [it]: state[it] });
			}
		}

		if (mapWidth !== prevProps.mapWidth) {
			this.setState({ mapWidth });
		}

		if (mapHeight !== prevProps.mapHeight) {
			this.setState({ mapHeight });
		}
	}

	onStart(event) {
		event.preventDefault();
	}

	onDrag(event) {
		event.preventDefault();
		this.setState({ dragging: true });
	}

	onStop(event, data) {
		event.preventDefault();
		const { dragging } = this.state;
		this.setState({ dragging: false });
		if (!dragging) {
			this.onClick();
		}

		const { x1, y1, x2, y2, mapWidth, mapHeight } = this.state;
		let newX1 = ((((x1 / 100) * mapWidth) + data.x) / mapWidth) * 100;
		let newX2 = ((((x2 / 100) * mapWidth) + data.x) / mapWidth) * 100;
		let offset = 0;
		if (newX1 < 0 && newX2 < 0) {
			offset = -Math.min(newX1, newX2);
		} else if (newX1 < 0) {
			offset = -newX1;
		} else if (newX2 < 0) {
			offset = -newX2;
		} else if (newX1 > 100 && newX2 > 100) {
			offset = -Math.max(newX1, newX2) + 100;
		} else if (newX1 > 100) {
			offset = -newX1 + 100;
		} else if (newX2 > 100) {
			offset = -newX2 + 100;
		}

		newX1 += offset;
		newX2 += offset;

		let newY1 = ((((y1 / 100) * mapHeight) - data.y) / mapHeight) * 100;
		let newY2 = ((((y2 / 100) * mapHeight) - data.y) / mapHeight) * 100;
		offset = 0;
		if (newY1 < 0 && newY2 < 0) {
			offset = -Math.min(newY1, newY2);
		} else if (newY1 < 0) {
			offset = -newY1;
		} else if (newY2 < 0) {
			offset = -newY2;
		} else if (newY1 > 100 && newY2 > 100) {
			offset = -Math.max(newY1, newY2) + 100;
		} else if (newY1 > 100) {
			offset = -newY1 + 100;
		} else if (newY2 > 100) {
			offset = -newY2 + 100;
		}

		newY1 += offset;
		newY2 += offset;
		this.sendUpdate(newX1, newY1, newX2, newY2);
	}

	onLeftStart(event) {
		event.preventDefault();
	}

	onLeftDrag(event, data) {
		event.preventDefault();

		const { x1, y1, mapWidth, mapHeight } = this.state;
		const newX1 = ((((x1 / 100) * mapWidth) + data.x) / mapWidth) * 100;
		const newY1 = ((((y1 / 100) * mapHeight) - data.y) / mapHeight) * 100;
		this.setState({ draggingLeft: true, draggedLeft: { x: newX1, y: newY1 } });
	}

	onLeftStop(event, data) {
		event.preventDefault();
		this.setState({ draggingLeft: false });

		const { x1, y1, x2, y2, mapWidth, mapHeight } = this.state;
		let newX1 = ((((x1 / 100) * mapWidth) + data.x) / mapWidth) * 100;
		if (newX1 < 0) {
			newX1 = 0;
		} else if (newX1 > 100) {
			newX1 = 100;
		}

		let newY1 = ((((y1 / 100) * mapHeight) - data.y) / mapHeight) * 100;
		if (newY1 < 0) {
			newY1 = 0;
		} else if (newY1 > 100) {
			newY1 = 100;
		}

		this.sendUpdate(newX1, newY1, x2, y2);
	}

	onRightStart(event) {
		event.preventDefault();
	}

	onRightDrag(event, data) {
		event.preventDefault();

		const { x2, y2, mapWidth, mapHeight } = this.state;
		const newX2 = ((((x2 / 100) * mapWidth) + data.x) / mapWidth) * 100;
		const newY2 = ((((y2 / 100) * mapHeight) - data.y) / mapHeight) * 100;
		this.setState({ draggingRight: true, draggedRight: { x: newX2, y: newY2 } });
	}

	onRightStop(event, data) {
		event.preventDefault();
		this.setState({ draggingRight: false });

		const { x1, y1, x2, y2, mapWidth, mapHeight } = this.state;
		let newX2 = ((((x2 / 100) * mapWidth) + data.x) / mapWidth) * 100;
		if (newX2 < 0) {
			newX2 = 0;
		} else if (newX2 > 100) {
			newX2 = 100;
		}

		let newY2 = ((((y2 / 100) * mapHeight) - data.y) / mapHeight) * 100;
		if (newY2 < 0) {
			newY2 = 0;
		} else if (newY2 > 100) {
			newY2 = 100;
		}

		this.sendUpdate(x1, y1, newX2, newY2);
	}

	onClick() {
		this.setState({ popoverOpen: true });
	}

	transformDimensions() {
		const { x1, y1, x2, y2, mapWidth, mapHeight } = this.state;
		const x1n = (x1 / 100) * mapWidth;
		const y1n = (y1 / 100) * mapHeight;
		const x2n = (x2 / 100) * mapWidth;
		const y2n = (y2 / 100) * mapHeight;
		return { x1n, y1n, x2n, y2n };
	}

	transformEdgeDimensions() {
		const { draggedLeft, draggedRight, mapWidth, mapHeight } = this.state;
		const x1d = (draggedLeft.x / 100) * mapWidth;
		const y1d = (draggedLeft.y / 100) * mapHeight;
		const x2d = (draggedRight.x / 100) * mapWidth;
		const y2d = (draggedRight.y / 100) * mapHeight;
		return { x1d, y1d, x2d, y2d };
	}

	delete() {
		const { id } = this.state;
		this.deleteItem(this.cat, this.itemCat, id);
	}

	sendUpdate(x1, y1, x2, y2) {
		const { id, itemName } = this.state;
		this.updateItem(this.cat, this.itemCat, id, {
			id, itemName, x1, y1, x2, y2, cat: this.cat, itemCat: this.itemCat,
		});
	}

	render() {
		const { id, dragging, popoverOpen, draggingLeft, draggingRight } = this.state;

		const { x1n, y1n, x2n, y2n } = this.transformDimensions();
		const { x1d, y1d, x2d, y2d } = this.transformEdgeDimensions();
		return ([
			<Draggable
				key={`mainline_${id}`}
				grid={[10, 10]}
				allowAnyClick={false}
				position={{ x: 0, y: 0 }}
				onStart={this.onStart}
				onDrag={this.onDrag}
				onStop={this.onStop}
			>
				<WallOutDiv x1={x1n} y1={y1n} dragging={dragging} draggingLeft={draggingLeft} draggingRight={draggingRight}>
					<WallDiv id={`barrier_${id}`} x1={x1n} y1={y1n} x2={x2n} y2={y2n} />
					{popoverOpen && (
						<CustomPopover
							x1n={x1n}
							y1n={y1n}
							x2n={x2n}
							y2n={y2n}
							popoverOpen={popoverOpen}
							closePopover={() => { this.setState({ popoverOpen: false }); }}
							deleteItem={this.delete}
						/>
					)}
				</WallOutDiv>
			</Draggable>,
			<Draggable
				key={`leftedge_${id}`}
				allowAnyClick={false}
				grid={[10, 10]}
				position={{ x: 0, y: 0 }}
				onStart={this.onLeftStart}
				onDrag={this.onLeftDrag}
				onStop={this.onLeftStop}
			>
				<EdgeHandler dragging={dragging || popoverOpen} x={x1n} y={y1n - 5}>
					<EdgeContent left dragging={(draggingLeft || draggingRight)} x1={x1n} y1={y1n} x2={x2n} y2={y2n} />
				</EdgeHandler>
			</Draggable>,
			<Draggable
				key={`rightedge_${id}`}
				allowAnyClick={false}
				grid={[10, 10]}
				position={{ x: 0, y: 0 }}
				onStart={this.onRightStart}
				onDrag={this.onRightDrag}
				onStop={this.onRightStop}
			>
				<EdgeHandler dragging={dragging || popoverOpen} x={x2n} y={y2n - 5}>
					<EdgeContent dragging={(draggingLeft || draggingRight)} x1={x1n} y1={y1n} x2={x2n} y2={y2n} />
				</EdgeHandler>
			</Draggable>,
			<div
				key={`draggingleftleftedge_${id}`}
				style={{
					display: (draggingLeft) ? 'block' : 'none', position: 'absolute', left: `${x1d}px`, bottom: `${y1d - 5}px`, cursor: 'e-resize', width: '8px', height: '10px', background: '#0B3F5D', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', border: '2px solid #3c9764', transform: `rotate(${-1 * ((Math.atan2(y2n - y1d, x2n - x1d) * 180) / Math.PI)}deg) translateX(-4px)`, transformOrigin: 'left center',
				}}
			/>,
			<div
				key={`draggingleftrightedge_${id}`}
				style={{
					display: (draggingLeft) ? 'block' : 'none', position: 'absolute', left: `${x2n}px`, bottom: `${y2n - 5}px`, cursor: 'e-resize', width: '8px', height: '10px', background: '#0B3F5D', borderTopRightRadius: '10px', borderBottomRightRadius: '10px', border: '2px solid #3c9764', transform: `rotate(${-1 * ((Math.atan2(y2n - y1d, x2n - x1d) * 180) / Math.PI)}deg) translateX(-4px)`, transformOrigin: 'left center',
				}}
			/>,
			<div
				key={`draggingrightleftedge_${id}`}
				style={{
					display: (draggingRight) ? 'block' : 'none', position: 'absolute', left: `${x1n}px`, bottom: `${y1n - 5}px`, cursor: 'e-resize', width: '8px', height: '10px', background: '#0B3F5D', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', border: '2px solid #3c9764', transform: `rotate(${-1 * ((Math.atan2(y2d - y1n, x2d - x1n) * 180) / Math.PI)}deg) translateX(-4px)`, transformOrigin: 'left center',
				}}
			/>,
			<div
				key={`draggingrightrightedge_${id}`}
				style={{
					display: (draggingRight) ? 'block' : 'none', position: 'absolute', left: `${x2d}px`, bottom: `${y2d - 5}px`, cursor: 'e-resize', width: '8px', height: '10px', background: '#0B3F5D', borderTopRightRadius: '10px', borderBottomRightRadius: '10px', border: '2px solid #3c9764', transform: `rotate(${-1 * ((Math.atan2(y2d - y1n, x2d - x1n) * 180) / Math.PI)}deg) translateX(-4px)`, transformOrigin: 'left center',
				}}
			/>,
			<div
				key={`draggingleftline_${id}`}
				style={{
					display: (draggingLeft) ? 'block' : 'none', position: 'absolute', left: `${x1d}px`, bottom: `${y1d - 5}px`, width: `${Math.sqrt(((x2n - x1d) ** 2) + ((y2n - y1d) ** 2))}px`, height: '10px', background: '#3c9764', transform: `rotate(${-1 * ((Math.atan2(y2n - y1d, x2n - x1d) * 180) / Math.PI)}deg)`, transformOrigin: 'left center',
				}}
			/>,
			<div
				key={`draggingrightline_${id}`}
				style={{
					display: (draggingRight) ? 'block' : 'none', position: 'absolute', left: `${x1n}px`, bottom: `${y1n - 5}px`, width: `${Math.sqrt(((x2d - x1n) ** 2) + ((y2d - y1n) ** 2))}px`, height: '10px', background: '#3c9764', transform: `rotate(${-1 * ((Math.atan2(y2d - y1n, x2d - x1n) * 180) / Math.PI)}deg)`, transformOrigin: 'left center',
				}}
			/>,
		]);
	}
}

const createWall = (state, resolution, elementDimensions, mapWidth, mapHeight, updateItem, deleteItem) => (
	<Wall key={state.id} state={state} resolution={resolution} elementDimensions={elementDimensions} mapWidth={mapWidth} mapHeight={mapHeight} updateItem={updateItem} deleteItem={deleteItem} />
);

export default createWall;

import { createSlice } from '@reduxjs/toolkit';

export const threadsSlice = createSlice({
	name: 'threads',
	initialState: [],
	reducers: {
		addThread: (state, thread) => {
			// Create the separate threads
			for (let i = 0; i < thread.payload.data.outputs; i++) {
				state.push({
					threadId: thread.payload.data.id,
					threadCount: thread.payload.data.count,
					threadOutput: i,
				});
			}

			console.log("Added thread");
		},
		updateThread: (state, data) => {
			console.log("Updating thread", data.payload);

			// Remove the threads with the id
			state = state.filter((thread) => thread.threadId !== data.payload.id);
			// Reinsert
			for (let i = 0; i < data.payload.outputs; i++) {
				state.push({
					threadId: data.payload.id,
					threadCount: data.payload.count,
					threadOutput: i,
				});
			}

			// Return the updated state
			return state;
		},
		deleteThread: (state, id) => {
			console.log("Deleting thread", id.payload);
			// Remove the threads with the id
			state = state.filter((thread) => thread.threadId !== id.payload.id);
			// Return the updated state
			return state;
		},
		setThreads: (state, data) => {
			console.log("Setting threads", data.payload);
			state = data.payload;
			return state;
		},
	},
});

// Action creators are generated for each case reducer function
export const { addThread, updateThread, deleteThread, setThreads } = threadsSlice.actions;

export default threadsSlice.reducer;

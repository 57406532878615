export const shortDescription = `
# demol
Device Modeling Language (DeMoL) - A DSL for modeling IoT devices.
Enables automated source code generation currently for RaspberryPi and RiotOS.

## Usage

The implementation of the grammar of the language can be found [here]().

The core Concepts of the language are:

- Device
- Board
- Peripheral
- Synthesis
- Connection
- CommunicationTransport

The language allows multi-file model imports and also uses a global local
repository for loading and referencing existing Board, Peripheral and Synthesis models.
`;

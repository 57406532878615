export const MetadataExample = `Metadata
	name: "" // fill this
	description: "" // fill this
	author: "" // fill this
	token: "" // The Codin token
end
`;

export const BrokerExample = `Broker<MQTT> BROKER_NAME // change this
	host: "" // fill this
	port: 1883  // change this
	ssl: False  // Enable/Disable SSL communication with broker
	webPath: "/mqtt" // this should be ok
	webPort: 8883 // change this
	auth:
		username: "" // fill this
		password: "" // fill this
end
`;

export const GaugeExample = `Gauge NAME // change this
	label: "" // fill this
	entity: ENTITY_NAME  // The name of the entity to use as data source
	attribute:  ENTITY_ATTRIBUTE  // (Optional) The attribute of the entity to plot
	minValue: 0 // change accordingly
	maxValue: 1 // change accordingly
	leftColor: Red
	rightColor: Blue
	levels: 10
	hideTxt: False
	unit: "" // add postfix if needed
 	position:
		x: 0 // start x - change if needed
		y: 0 // start y - change if needed
		width: 4 // width - change if needed
		height: 4 // height - change if needed
end
`;

export const ValueDisplayExample = `ValueDisplay NAME // change this
	label: "" // fill this
	entity: ENTITY_NAME  // The name of the entity to use as data source
	attribute:  ENTITY_ATTRIBUTE  // (Optional) The attribute of the entity to plot
	unit: "" // add postfix if needed
	position:
		x: 10 // start x - change if needed
		y: 10 // start y - change if needed
		width: 10 // width - change if needed
		height: 10 // height - change if needed
end
`;

export const JsonViewerExample = `JsonViewer NAME // change this
	label: "" // fill this
	entity: ENTITY_NAME  // The name of the entity to use as data source
	attribute:  ENTITY_ATTRIBUTE  // (Optional) The attribute of the entity to plot
	position:
		x: 20 // start x - change if needed
		y: 20 // start y - change if needed
		width: 10 // width - change if needed
		height: 10 // height - change if needed
end
`;

export const AliveExample = `AliveDisplay NAME // change this
	label: "" // fill this
	entity: ENTITY_NAME  // The name of the entity to use as data source
	timeout: 60 // change if needed
	position:
		x: 30 // start x - change if needed
		y: 30 // start y - change if needed
		width: 10 // width - change if needed
		height: 10 // height - change if needed
end
`;

export const LogsExample = `LogsDisplay NAME // change this
	label: "" // fill this
	entity: ENTITY_NAME  // The name of the entity to use as data source
	attribute:  ENTITY_ATTRIBUTE  // (Optional) The attribute of the entity to plot
	position:
		x: 40 // start x - change if needed
		y: 40 // start y - change if needed
		width: 10 // width - change if needed
		height: 10 // height - change if needed
end
`;

export const ButtonExample = `Button NAME // change this
	label: "" // fill this
	entity: ENTITY_NAME  // The name of the entity to use as data source
	payload:
		- attribute: type = default_value // change this
end
`;

export const ButtonGroupExample = `Button Btn1
	label: "Button1"
	entity: ENTITY_NAME  // The name of the entity to use as data source
	payload:
		- attribute: type = default_value // change this
end

Button Btn2
	label: "Button2"
	entity: ENTITY_NAME  // The name of the entity to use as data source
	payload:
		- attribute: DEFAULT_VALUE // change this
end

ButtonGroup GROUP_NAME // change this
	label: "" // fill this
	alignTxt: Center
	alignBtns: Horizontal
	buttons:
		- Btn1
		- Btn2
	position:
		x: 0
		y: 0
		width: 4
		height: 4
end
`;

export const PlotExample = `Plot NAME // change this
	label: "" // fill this
	entity: ENTITY_NAME  // The name of the entity to use as data source
	ptype: Line  // Line | Bar
	attribute:  ENTITY_ATTRIBUTE  // (Optional) The attribute of the entity to plot
end
`;

export const PlotViewExample = `PlotView NAME // change this
	label: "" // fill this
	xAxis: True
    yAxis: True
    horizontalGrid: True
    verticalGrid: True
    legend: True
	maxValues: -1
	legendPosition: topRight
	plots:
		- PLOT_NAME  // change this
	position:
		x: 0
		y: 0
		width: 8
		height: 8
end

Plot PLOT_NAME // change this
	label: ""  // fill this
	entity: ENTITY_NAME  // The name of the entity to use as data source
	ptype: Line  // Line | Bar
	attribute:  ENTITY_ATTRIBUTE  // (Optional) The attribute of the entity to plot
	color: red  // red/blue/yellow/green/cyan
	smooth: False  // Smooth or not the plot (Codin plot functionality)
end
`;

export const EntitySensorExample = `Entity SENSOR_NAME // change this
	type: sensor
	freq: 1  // change this
	topic: ""  // fill this
	broker: default_broker  // change this with the name of your broker
	attributes:
		- attr1: type1  // change this
end
`;

export const EntityActuatorExample = `Entity ACTUATOR_NAME // change this
	type: actuator
	topic: ""  // fill this
	broker: default_broker  // change this with the name of your broker
	attributes:
		- attr1: type1  // change this
end
`;


/* eslint-disable max-len */

import {
	Checkbox,
	Icon,
	Menu,
	Popover,
} from '@blueprintjs/core';
import { faTrash, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';
import { GreenBorderButton } from '../../../lib_socioty/buttons.js';
import { ToasterBottom } from '../../../lib_socioty/toaster.js';
import robotImg from '../../../../assets/images/missionItems/general/robot.png';
import robotFormulaImg from '../../../../assets/images/missionItems/general/robotFormula.png';
import robotMiniImg from '../../../../assets/images/missionItems/general/robotMini.png';
import robotTractorImg from '../../../../assets/images/missionItems/general/robotTractor.png';
import robotDrone from '../../../../assets/images/missionItems/general/robotDrone.png';
import robotRoomba from '../../../../assets/images/missionItems/general/robotRoomba.png';

import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const skins = {
	normal: {
		name: 'Normal',
		image: robotImg,
		aspectRatio: 1.158,
	},
	formula: {
		name: 'Formula',
		image: robotFormulaImg,
		aspectRatio: 1.983,
	},
	mini: {
		name: 'Mini',
		image: robotMiniImg,
		aspectRatio: 1.746,
	},
	tractor: {
		name: 'Tractor',
		image: robotTractorImg,
		aspectRatio: 1.65,
	},
	drone: {
		name: 'Drone',
		image: robotDrone,
		aspectRatio: 1,
	},
	roomba: {
		name: 'Roomba',
		image: robotRoomba,
		aspectRatio: 1.004,
	},
};

const RobotDiv = styled.div`
    position: absolute;
    width: ${(props) => props.rWidth}px;
    height: ${(props) => props.rHeight}px;
    left: ${(props) => `calc(${props.x}% - ${props.rWidth / 2}px)`};
    bottom: ${(props) => `calc(${props.y}% - ${props.rHeight / 2}px)`};
    cursor: ${(props) => ((props.dragging) ? 'move' : 'pointer')};
`;

const CustomIcon = styled(Icon)`
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -o-user-select: none;
    user-select: none;
    transform: ${(props) => props.transform};
`;

const BasicPopover = ({ className, rWidth, rHeight, popoverOpen, closePopover, changeName, checkName, itemName, rotateLeft, rotateRight, rotate90Left, rotate90Right, skin, setSkin, onDevicesClick, deleteItem, theta }) => (
	<Popover usePortal popoverClassName={`${className} item-popover`} rWidth={rWidth} rHeight={rHeight} isOpen={popoverOpen}>
		<div style={{
			width: '300px', padding: '10px', color: 'white', background: 'black', boxShadow: '4px 4px 10px 1px #333333',
		}}
		>
			{/* Name */}
			<div style={{
				width: '100%', maxWidth: '100%', paddingLeft: '1px', paddingRight: '1px', display: 'flex', justifyContent: 'space-around',
			}}
			>
				<TextField
					value={itemName}
					label="Name"
					variant="filled"
					sx={{
						backgroundColor: '#aaa',
						mx: 1,
						width: '100%',
					}}
					size="small"
					color="success"
					onChange={(event) => changeName(event.target.value)}
				/>
			</div>

			{/* Direction */}
			<div style={{
				margin: '10px', display: 'flex', justifyContent: 'space-around', alignContent: 'center',
			}}
			>
				<Button
					color="secondary"
					variant="contained"
					style={{
						width: "40%",
						fontSize: "0.7rem",
					}}
					onClick={() => { rotateRight("step"); }}
				>
					{`Rot right`}
				</Button>
				<Typography color="#ffffff" pt={0.7}>
					{`${theta}°`}
				</Typography>
				<Button
					color="secondary"
					variant="contained"
					style={{
						width: "40%",
						fontSize: "0.7rem",
					}}
					onClick={() => { rotateLeft("step"); }}
				>
					{`Rot left`}
				</Button>
			</div>

			<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
				<FormControl error sx={{ m: 1, width: "100%" }}>
					<Select
						value={skins[skin].name}
						label="Mode"
						sx={{
							backgroundColor: 'gray',
						}}
						size="small"
					>
						{/* Iterate over skins */}
						{Object.keys(skins).map((key, index) => (
							<MenuItem key={index} value={skins[key].name} onClick={() => setSkin(key)}>
								{`Skin: ${skins[key].name}`}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>

			<div
				style={{
					width: '100%', marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center',
				}}
			>
				<Button
					color="secondary"
					variant="contained"
					style={{
						width: "100%",
						m: 10,
					}}
					onClick={onDevicesClick}
				>
					{"Devices Settings"}
				</Button>
			</div>

			<div style={{
				width: '100%', height: '20px', display: 'flex', justifyContent: 'space-around', marginTop: '10px',
			}}
			>
				<FontAwesomeIcon icon={faTrash} style={{ fontSize: '20px', cursor: 'pointer' }} onClick={deleteItem} />
				<FontAwesomeIcon icon={faXmarkCircle} style={{ fontSize: '20px', cursor: 'pointer' }} onClick={closePopover} />
			</div>
		</div>
	</Popover>
);

const CustomPopover = styled(BasicPopover)`
    transform: ${(props) => `translate(${-props.rWidth / 2}px, ${-props.rHeight / 2}px)`}!important;
    border-radius: 10px;
`;

const DevicesPopover = ({ className, rWidth, rHeight, devicesPopoverOpen, closePopover, devices, id, changeDevice }) => (
	<Popover usePortal popoverClassName={`${className} item-popover`} rWidth={rWidth} rHeight={rHeight} isOpen={devicesPopoverOpen}>
		<div style={{
			width: '300px', padding: '10px', color: 'white', background: 'black', boxShadow: '4px 4px 10px 1px #333333',
		}}
		>
			{Object.keys(devices).map((dev, ind) => (
				<div
					key={`robotDevices_${id}_${dev}`}
					style={{
						width: '100%', marginTop: (ind === 0) ? '0px' : '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly',
					}}
				>
					<div style={{ width: '100px' }}>
						{devices[dev].name}
					</div>
					<Checkbox className="pan-tilt-checkbox" checked={devices[dev].value} onChange={() => changeDevice(dev)} />
				</div>
			))}
			<div style={{
				width: '100%', height: '20px', display: 'flex', justifyContent: 'space-around', marginTop: '10px',
			}}
			>
				<FontAwesomeIcon icon={faXmarkCircle} style={{ fontSize: '20px', cursor: 'pointer' }} onClick={closePopover} />
			</div>
		</div>
	</Popover>
);

const CustomDevicesPopover = styled(DevicesPopover)`
    transform: ${(props) => `translate(${-props.rWidth / 2}px, ${-props.rHeight / 2}px)`}!important;
    border-radius: 10px;
`;

class Robot extends React.Component {
	constructor(props) {
		super(props);

		const initialState = props.state;
		this.cat = initialState.cat;
		this.itemCat = initialState.itemCat;
		this.updateItem = props.updateItem;
		this.deleteItem = props.deleteItem;
		this.checkItemNameExists = props.checkItemNameExists;

		this.state = {
			id: initialState.id,
			itemName: initialState.itemName,
			x: initialState.x,
			y: initialState.y,
			theta: initialState.theta,
			skin: initialState.skin,
			devices: initialState.devices,
			elementDimensions: props.elementDimensions,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
			dragging: false,
			popoverOpen: false,
			devicesPopoverOpen: false,
			resolution: props.resolution,
			metersPopupOpen: false,
			xMeters: 0,
			yMeters: 0,
		};
		this.interval = null;
		this.initialName = initialState.itemName;

		this.onStart = this.onStart.bind(this);
		this.onDrag = this.onDrag.bind(this);
		this.onStop = this.onStop.bind(this);
		this.onClick = this.onClick.bind(this);
		this.onDevicesClick = this.onDevicesClick.bind(this);
		this.rotateLeft = this.rotateLeft.bind(this);
		this.rotateRight = this.rotateRight.bind(this);
		this.rotate90Left = this.rotate90Left.bind(this);
		this.rotate90Right = this.rotate90Right.bind(this);
		this.delete = this.delete.bind(this);
		this.sendUpdate = this.sendUpdate.bind(this);
		this.setSkin = this.setSkin.bind(this);
		this.changeName = this.changeName.bind(this);
		this.checkName = this.checkName.bind(this);
		this.changeDevice = this.changeDevice.bind(this);
	}

	componentDidMount() {
		const { itemName, x, y, theta, skin, devices } = this.state;
		this.sendUpdate(itemName, x, y, theta, skin, devices);
	}

	static getDerivedStateFromProps(props) {
		const initialState = props.state;
		return {
			id: initialState.id,
			itemName: initialState.itemName,
			x: initialState.x,
			y: initialState.y,
			theta: initialState.theta,
			skin: initialState.skin,
			devices: initialState.devices,
			elementDimensions: props.elementDimensions,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
			resolution: props.resolution,
		};
	}

	onStart(event) {
		event.preventDefault();
		const { x, y, mapWidth, mapHeight, resolution } = this.state;
		const xMeters = ((x / 100) * mapWidth) * resolution;
		const yMeters = ((y / 100) * mapHeight) * resolution;
		this.setState({ metersPopupOpen: true, xMeters, yMeters });
	}

	onDrag(event, data) {
		event.preventDefault();
		const { x, y, mapWidth, mapHeight, resolution } = this.state;
		const xMeters = (((x / 100) * mapWidth) + data.x) * resolution;
		const yMeters = (((y / 100) * mapHeight) - data.y) * resolution;
		this.setState({ dragging: true, xMeters, yMeters });
	}

	onStop(event, data) {
		event.preventDefault();
		const { dragging } = this.state;
		this.setState({ dragging: false, metersPopupOpen: false });
		if (!dragging) {
			this.onClick();
		}

		const { itemName, x, y, theta, skin, devices, mapWidth, mapHeight } = this.state;
		let newX = ((((x / 100) * mapWidth) + data.x) / mapWidth) * 100;
		if (newX > 100) {
			newX = 100;
		}

		if (newX < 0) {
			newX = 0;
		}

		let newY = ((((y / 100) * mapHeight) - data.y) / mapHeight) * 100;
		if (newY > 100) {
			newY = 100;
		}

		if (newY < 0) {
			newY = 0;
		}

		this.sendUpdate(itemName, newX, newY, theta, skin, devices);
	}

	onClick() {
		this.setState({ popoverOpen: true });
	}

	onDevicesClick() {
		this.setState({ popoverOpen: false, devicesPopoverOpen: true });
	}

	setSkin(skin) {
		const { itemName, x, y, theta, devices } = this.state;
		this.sendUpdate(itemName, x, y, theta, skin, devices);
	}

	rotateLeft(mode) {
		if (mode === 'step') {
			const { itemName, x, y, theta, skin, devices } = this.state;
			this.sendUpdate(itemName, x, y, theta + 10, skin, devices);
		} else if (mode === 'start') {
			this.interval = setInterval(() => { const { itemName, x, y, theta, skin, devices } = this.state; this.sendUpdate(itemName, x, y, theta + 1, skin, devices); }, 100);
		} else {
			clearInterval(this.interval);
			this.interval = null;
		}
	}

	rotateRight(mode) {
		if (mode === 'step') {
			const { itemName, x, y, theta, skin, devices } = this.state;
			this.sendUpdate(itemName, x, y, theta - 10, skin, devices);
		} else if (mode === 'start') {
			this.interval = setInterval(() => { const { itemName, x, y, theta, skin, devices } = this.state; this.sendUpdate(itemName, x, y, theta - 1, skin, devices); }, 100);
		} else {
			clearInterval(this.interval);
			this.interval = null;
		}
	}

	rotate90Left() {
		const { itemName, x, y, theta, skin, devices } = this.state;
		const position = (theta % 360) / 90;
		let newTheta;
		newTheta = position === Math.ceil(position) ? (position + 1) * 90 : Math.ceil(position) * 90;

		this.sendUpdate(itemName, x, y, newTheta, skin, devices);
	}

	rotate90Right() {
		const { itemName, x, y, theta, skin, devices } = this.state;
		const position = (theta % 360) / 90;
		let newTheta;
		newTheta = position === Math.floor(position) ? (position - 1) * 90 : Math.floor(position) * 90;

		this.sendUpdate(itemName, x, y, newTheta, skin, devices);
	}

	delete() {
		const { id } = this.state;
		this.deleteItem(this.cat, this.itemCat, id);
	}

	sendUpdate(itemName, x, y, theta, skin, devices) {
		const { id } = this.state;
		this.updateItem(this.cat, this.itemCat, id, {
			id, itemName, x, y, theta, skin, devices, cat: this.cat, itemCat: this.itemCat,
		});
	}

	changeName(value) {
		const { x, y, theta, skin, devices } = this.state;
		this.sendUpdate(value.toLowerCase().split(' ').join('_'), x, y, theta, skin, devices);
	}

	checkName(value) {
		const { id } = this.state;
		const { matchesPattern, found } = this.checkItemNameExists(value, this.initialName, this.cat, this.itemCat, id);
		if (matchesPattern) {
			ToasterBottom.show({
				intent: 'danger',
				message: 'Name cannot follow the template category_number',
			});
			const { x, y, theta, skin, devices } = this.state;
			this.sendUpdate(this.initialName, x, y, theta, skin, devices);
		} else if (found) {
			ToasterBottom.show({
				intent: 'danger',
				message: 'Name already exists',
			});
			const { x, y, theta, skin, devices } = this.state;
			this.sendUpdate(this.initialName, x, y, theta, skin, devices);
		}
	}

	changeDevice(dev) {
		const { itemName, x, y, theta, skin, devices } = this.state;
		const temp = {};
		for (const d of Object.keys(devices)) {
			temp[d] = {};
			temp[d].name = devices[d].name;
			temp[d].value = devices[d].value;
			temp[d].ids = devices[d].ids;
			temp[d].parameters = devices[d].parameters;
		}

		temp[dev].value = !(temp[dev].value);
		this.sendUpdate(itemName, x, y, theta, skin, temp);
	}

	render() {
		const { id, itemName, x, y, theta, skin, devices, elementDimensions, dragging, popoverOpen, devicesPopoverOpen, metersPopupOpen, xMeters, yMeters } = this.state;
		const rHeight = elementDimensions;
		const rWidth = (elementDimensions * skins[skin].aspectRatio);

		return (
			<Draggable
				key={`robot_${id}`}
				allowAnyClick={false}
				grid={[5, 5]}
				position={{ x: 0, y: 0 }}
				handle=".robothandler"
				onStart={this.onStart}
				onDrag={this.onDrag}
				onStop={this.onStop}
			>
				<RobotDiv key={`robotKey_${id}`} id="robot" x={x} y={y} rWidth={rWidth} rHeight={rHeight} dragging={dragging}>
					<img
						key="robot"
						className="robothandler"
						src={skins[skin].image}
						alt="robot"
						draggable={false}
						style={{ width: rWidth, height: rHeight, transform: `rotate(${-theta}deg)` }}
					/>
					<div
						style={{
							display: (metersPopupOpen) ? 'block' : 'none', width: 'max-content', paddingLeft: '5px', paddingRight: '5px', height: '20px', position: 'absolute', top: `-${rHeight / 2 + 10}px`, left: `${rWidth / 2 - (145 / 2)}px`, fontSize: '14px', textAlign: 'center', background: '#7a8585', borderRadius: '10px', color: 'white',
						}}
					>
						{`x: ${xMeters.toFixed(3)}m, y: ${yMeters.toFixed(3)}m`}
					</div>
					{popoverOpen && (
						<CustomPopover
							rWidth={rWidth}
							rHeight={rHeight}
							popoverOpen={popoverOpen}
							closePopover={() => {
								this.setState({ popoverOpen: false });
							}}
							changeName={this.changeName}
							checkName={this.checkName}
							itemName={itemName}
							rotateLeft={this.rotateLeft}
							rotateRight={this.rotateRight}
							rotate90Left={this.rotate90Left}
							rotate90Right={this.rotate90Right}
							skin={skin}
							theta={theta}
							setSkin={this.setSkin}
							deleteItem={this.delete}
							onDevicesClick={this.onDevicesClick}
						/>
					)}
					{devicesPopoverOpen && (
						<CustomDevicesPopover
							rWidth={rWidth}
							rHeight={rHeight}
							devicesPopoverOpen={devicesPopoverOpen}
							closePopover={() => {
								this.setState({ devicesPopoverOpen: false });
							}}
							devices={devices}
							id={id}
							changeDevice={this.changeDevice}
						/>
					)}
				</RobotDiv>
			</Draggable>
		);
	}
}

const createRobot = (state, resolution, elementDimensions, mapWidth, mapHeight, updateItem, deleteItem, checkItemNameExists) => (
	<Robot key={`createRobot_${state.id}`} state={state} resolution={resolution} elementDimensions={elementDimensions} mapWidth={mapWidth} mapHeight={mapHeight} updateItem={updateItem} deleteItem={deleteItem} checkItemNameExists={checkItemNameExists} />
);

export default createRobot;

export const getDocumentation = () => `
![EnvPopLogo](https://locsys.issel.ee.auth.gr/static/media/envpop_L.496cd94ad842c3a52cea.png)

This graphical DSL imports an EnvMaker model and populates it with IoT sensors and effectors.

## Relations

An EnvPop model requires an EnvMaker model to be imported, so as to add elements to it. You may import an EnvMaker model by clicking on the "Import" button on the *Relations* tab, next to the *EnvMaker* relation.

Also, an EnvPop model canbe transformed into an SmAuto model, by clicking on the "Transform" button on the *Relations* tab, next to the *SmAuto* relation. This will create an SmAuto model, which will contain the same elements as the EnvPop model.

## Usage

In order to create the model, you can simply drag and drop elements from the General, Sensor and Effectors category in the map. When you drop an item, you can edit its properties by clicking on it. You can delete each element by clicking on the bin icon that exists in the popup.

Next, each item is described in detail.

### General

#### Wall
A Wall has no properties, and consists of two edges. It is used to create a boundary for the environment. You can drag each edge to change the shape of the wall.

### Sensors

Most of the sensors share some common properties, which are described below.
1. Name: The name of each sensor exists for identification purposes and can be changed at the top of the popup.
2. Hz: The frequency of the sensor's measurements.
3. Mode: The mode of the sensor. It can be either "Simulation" or "Mock". In the first case, the sensor will provide simulated values (in case a simulator can be deployed), while in the second case, the sensor will generate mock values, based on some predefined generators:
  - 3.1. Constant: The sensor will always provide the same value.
  - 3.2. Random: The sensor will provide random values, based on a uniform distribution.
  - 3.3. Triangle: The sensor will provide values based on a triangle distribution.
  - 3.4. Normal: The sensor will provide values based on a normal distribution.
  - 3.5. Sinus: The sensor will provide values based on a sinus function.

When you change a value, the model is automatically saved.

#### Ambient light sensor
The Ambient Light Sensor element provides the ability to obtain the brightness (in Lumen) of a room.

#### Area alarm sensor
The Area Alarm sensor provides the possibility to obtain the status of an Alarm located on a specific place, in order to conclude whether something is in the given area or not.

#### Distance sensor
The Distance Sensor provides the ability to receive distance values. You can rotate the sensor's orientation via the popup.

#### Gas sensor
The Gas sensor provides the possibility to obtain values of carbon dioxide (CO2) in the air as a percentage (%).

#### Humidity sensor
The Humidity sensor provides the possibility to obtain values of humidity in the air as a percentage (%).

#### Linear alarm sensor
The Linear Alarm sensor provides the possibility to obtain the status of an Alarm located on a specific place, in order to conclude whether something is in the given line of sight or not. You can move each edge of the sensor to change its shape.

#### PH sensor
The PH sensor provides the possibility to obtain values of PH in the soil or liquid.

#### Temperature sensor
The Temperature sensor provides the possibility to obtain values of temperature in the air.

### Actuators

#### Humidifier
The Humidifier provides the ability to change the humidity (in percentage) of a circular area.

#### Light
The Light provides the ability to change the luminosity (in percentage) of a circular area.

#### Switch
The Switch provides the ability to change its state to either "On" or "Off". You can also add more states and handle them accordingly.

#### Speaker
The Speaker provides the ability to play a sound or say something.

#### Thermostat
The Thermostat provides the ability to change the temperature (in Celsius) of a circular area.

`;

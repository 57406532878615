import {
	Grid,
	Typography,
	Slider,

} from "@mui/material";

const ParametersSlider = ({ param, parameters, setParameters, nodeData }) => (
	<Grid
		item
		container
		display="flex"
		direction="row"
		justifyContent="flex-start"
		alignItems="center"
		sx={{
			marginBottom: "5px",
			width: "100%",
		}}
	>
		<Typography sx={{ marginRight: "10px", width: "40%" }}>
			{param.label}
		</Typography>
		<Slider
			sx={{
				width: "55%",
			}}
			value={parameters.find((p) => p.id === param.id).value}
			size="small"
			step={param.step ?? 1}
			min={param.min ?? 0}
			max={param.max ?? 100}
			valueLabelDisplay="auto"
			onChange={(e, newValue) => {
				const newParams = parameters.map((p) => {
					if (p.id === param.id) {
						return {
							...p,
							value: newValue,
						};
					}

					return p;
				});
				setParameters(newParams);
			}}
		/>
	</Grid>
);

export default ParametersSlider;

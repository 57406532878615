import { createSlice } from '@reduxjs/toolkit';

export const nodesSlice = createSlice({
	name: 'nodes',
	initialState: [],
	reducers: {
		addNodeParameters: (state, node) => {
			const dataToImport = {
				id: node.payload.id,
				parameters: node.payload.parameters,
			};
			state.push(dataToImport);
			// console.log("Redux: Added node", dataToImport);
		},
		updateNodeParameters: (state, data) => {
			// console.log("Redux: Updating node", data.payload.id, data.payload.parameters);

			// Update the node in the state based on id
			const index = state.findIndex((node) => node.id === data.payload.id);
			if (index === -1) { // add the node
				state.push(data.payload);
			} else {
				state[index].parameters = data.payload.parameters;
			}

			// Return the updated state
			return state;
		},
		deleteNodeParameters: (state, id) => {
			// console.log("Redux: Deleting node", id.payload);
			// Remove the node with the id
			state = state.filter((node) => node.id !== id.payload.id);
			// Return the updated state
			return state;
		},
		setNodesParameters: (state, data) => {
			// console.log("Redux: Setting nodes", data.payload);
			state = data.payload;
			return state;
		},
	},
});

// Action creators are generated for each case reducer function
export const { addNodeParameters, updateNodeParameters, deleteNodeParameters, setNodesParameters } = nodesSlice.actions;

export default nodesSlice.reducer;

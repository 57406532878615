export const shortDescription = `
![SmartHomeImage](https://raw.githubusercontent.com/robotics-4-all/smauto/main/assets/images/smauto_logo.png)

## Description
Smart environments are becoming quite popular in the home setting consisting of a broad range of connected devices. While offering a novel set of possibilities, this also contributes to the complexity of the environment, posing new challenges to allowing the full potential of a sensorized home to be made available to users.

SmAuto is a Domain Specific Language (DSL) that enables users to program complex 
automation scenarios, for connected IoT devices in smart environments,
that go beyond simple tasks. 

The DSL is developed using Python and TextX and follows the model interpretation
and executable models paradigms. It includes a meta-model and a grammar, that is
specialized for smart environments, while it also provides the following features:

- **Command-line Interface**. Used to call the validator, the interpreter and the 
code generator explicitely.
- **REST Api**. The DSL implements a REST Api, that can be utilized to remotely call
the validator, the interpreter and the code generator on demand. ALso usefull for
integrating the language in bigger projects and cloud-based platforms.
- **Dynamically compile and execute models**. Model classes are constructed at runtime
and are enhanced with platform-specific code that implements the logic. This process is
executed by the language interpreter.
- **Generate Virtual Entities**. A code generator is provided that transforms
Entity model definitions into executable code with enhanced value generation with
optional noise functions applied on. This can be very usefull to automatically
generate the source code of virtual entities which simulate the behaviour of physical
sensors.
- **Generate Visualization graphs of Automations**. A generator is provided 
which takes a model as input and generates an image
of the automation graph.
`;


import { useEffect, useState } from "react";

import {
	Grid,
	Typography,
	Select,
	MenuItem,
} from "@mui/material";

import { useSelector } from 'react-redux';

const ParametersThread = ({ param, parameters, setParameters, nodeData }) => {
	const [thread, setThread] = useState(parameters.find((p) => p.id === param.id).value);
	const threads = useSelector((state) => state.threads);
	console.log("Threads", threads);

	useEffect(() => {
		setThread(parameters.find((p) => p.id === param.id).value);
	}, [parameters]);

	return (
		<Grid
			item
			container
			display="flex"
			direction="row"
			justifyContent="flex-start"
			alignItems="center"
			sx={{
				marginBottom: "5px",
				width: "100%",
			}}
		>
			<Typography sx={{ marginRight: "10px", width: "40%" }}>
				{param.label}
			</Typography>
			<Select
				variant="filled"
				sx={{
					width: "55%",
				}}
				value={thread ?? ""}
				onChange={(e) => {
					const newParams = parameters.map((p) => {
						if (p.id === param.id) {
							return {
								...p,
								value: e.target.value,
							};
						}

						return p;
					});
					setParameters(newParams);
				}}
			>
				<MenuItem
					key="none"
					value=""
				>
					{""}
				</MenuItem>
				{/* Iterate over threads and over thread.parameters.outputs number */}
				{threads.map((t) => (
					<MenuItem
						key={`${t.threadId}${t.threadOutput}`}
						value={`${t.threadCount} : ${t.threadOutput}`}
					>
						{`Thread #${t.threadCount} : Output ${t.threadOutput}`}
					</MenuItem>
				))}
			</Select>
		</Grid>
	);
};

export default ParametersThread;


/* eslint-disable max-len */
import React from 'react';
import { memo } from "react";
import { Box } from 'rebass';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { InputGroup, NumericInput } from '@blueprintjs/core';
import { ToasterBottom } from '../lib_socioty/toaster';
// import { createMission } from '../../api/missions';
// import {validateGoals} from '../../api/goals';
import ProgressBar from './progress-bar';
import {
	GreenBorderButton, GreenButton, NextGreenButton, PreviousGreenButton,
} from '../lib_socioty/buttons';
import OpenMap from './open-map';
import { CustomSpinner, MiniOverlay } from '../lib_socioty/overlays';
import SetSensorsObstacles from './set-sensors-obstacles';
import availableItems from './available-items';
// import SetPlaces from './set-places';
// import SetGoals from './set-goals';
// import FinalCheck from './final-check';
import missionToYaml from '../lib_socioty/yaml-converter';
// import goalComponents from './goal-components';
// import goalConverter from './goal-components/converters';
// import {extra1, extra2} from './goal-components/defaultGoalText';

import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

const jsYaml = require('js-yaml');
const fileDownload = require('js-file-download');

const StyledBox = styled(Box)`
	height: 100%;
	width: 100%;
	display: block;
	align-items: center;
	justify-content: center;
	overflow: auto;
`;

const StyledArea = styled(Box)`
	height: 100%;
	width: 100%;
	max-width: 1400px;
	min-width: 1060px!important;
	min-height: 640px;
	display: flex;
	flex-direction: column;
	padding: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	margin: auto!important;
	color: white;
`;

const MenuRow = styled.div`
	width: 100%;
	height: 60px;
	font-size: 22px;
	display: flex;
	align-items: center;
	padding: 5px;
`;

const MainMenu = styled.div`
	width: calc(100% - 250px);
	height: 100%;
	display: flex;
	align-items: center;
`;

const MainRow = styled.div`
	width: 100%;
	height: calc(100% - 60px);
	padding-left: 5px;
	padding-right: 5px;
	display: flex;
`;

const SaveMapHeader = styled.div`
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 28px;
`;

const SaveMapName = styled.div`
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	margin-top: 10px;
`;

const NameInputHeader = styled.div`
	width: 100px;
	height: 100%;
	display: flex;
	align-items: center;
`;

const ParameterInputHeader = styled.div`
	width: 300px;
	height: 100%;
	display: flex;
	align-items: center;
`;

const NameInput = styled.div`
	width: 400px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ParameterInput = styled.div`
	width: 135px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const SaveMapButtons = styled.div`
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	font-size: 20px;
	margin-top: 10px;
`;

const PrettoSlider = styled(Slider)({
	color: '#52af77',
	height: 8,
	'& .MuiSlider-track': {
		border: 'none',
	},
	'& .MuiSlider-thumb': {
		height: 24,
		width: 24,
		backgroundColor: '#fff',
		border: '2px solid currentColor',
		'&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
			boxShadow: 'inherit',
		},
		'&:before': {
			display: 'none',
		},
	},
});

const categoriesPrefix = {
	general: 'gn',
	sensors: 'sn',
	effectors: 'ef',
};

const valueInRange = (value, minim, maxim) => (
	(value >= minim) && (value <= maxim)
);

export class CreateMission extends React.Component {
	constructor(props) {
		super(props);

		this.initialGridDimension = 120;
		this.initialGridMeters = 20.0;

		// Comes from the parent component
		this.updateModel = props.modelUpdate;
		// Whole model
		this.dbmodel = props.dbitem;
		this.model = props.model;

		this.state = {
			currentStep: 0,
			spinnerOpen: false,
			selectedMap: {
				name: null,
				map: null,
			},
			autoWalls: true,
			items: {},
			nextId: 1,
			resolution: this.initialGridMeters / this.initialGridDimension,
			gridMeters: this.initialGridMeters,
			gridDimension: this.initialGridDimension,
			places: {},
			nextPlaceId: 1,
			selectedPlace: null,
			goals: [],
			reviewSelectedPlace: null,
			namePopupOpen: false,
			missionName: '',
			mapWidth: 100,
			mapHeight: 80,
			mapWidthM: 100 * (this.initialGridMeters / this.initialGridDimension),
			temperature: 22,
			humidity: 60,
			luminosity: 80,
			envmakerModel: null,
		};

		// this.previousStep = this.previousStep.bind(this);
		// this.nextStep = this.nextStep.bind(this);
		// this.selectStep = this.selectStep.bind(this);
		this.changeSpinner = this.changeSpinner.bind(this);
		this.deleteMap = this.deleteMap.bind(this);
		this.selectMap = this.selectMap.bind(this);
		this.selectMission = this.selectMission.bind(this);
		// this.saveMission = this.saveMission.bind(this);
		this.changeAutoWalls = this.changeAutoWalls.bind(this);
		this.addWaters = this.addWaters.bind(this);
		this.newItem = this.newItem.bind(this);
		this.updateItem = this.updateItem.bind(this);
		this.deleteItem = this.deleteItem.bind(this);
		this.changeGridMeters = this.changeGridMeters.bind(this);
		this.changeGridDimension = this.changeGridDimension.bind(this);
		this.clearItems = this.clearItems.bind(this);
		this.completeItems = this.completeItems.bind(this);
		this.checkItemNameExists = this.checkItemNameExists.bind(this);
		this.checkOverridenPlaces = this.checkOverridenPlaces.bind(this);
		this.newPlace = this.newPlace.bind(this);
		this.updatePlace = this.updatePlace.bind(this);
		this.deletePlace = this.deletePlace.bind(this);
		this.clearPlaces = this.clearPlaces.bind(this);
		this.checkPlaceNameExists = this.checkPlaceNameExists.bind(this);
		this.selectPlace = this.selectPlace.bind(this);
		this.changeAdvancedMode = this.changeAdvancedMode.bind(this);
		this.reviewSelectPlace = this.reviewSelectPlace.bind(this);
		this.openSavePopup = this.openSavePopup.bind(this);
		this.changeName = this.changeName.bind(this);
		this.setMapDimensions = this.setMapDimensions.bind(this);
		this.changeTemperature = this.changeTemperature.bind(this);
		this.changeHumidity = this.changeHumidity.bind(this);
		this.changeLuminosity = this.changeLuminosity.bind(this);
		this.convertToYaml = this.convertToYaml.bind(this);
		this.clearAllItems = this.clearAllItems.bind(this);
		this.test = this.test.bind(this);
	}

	componentDidMount() {
		this.clearItems();
		this.clearPlaces();
	}

	componentDidUpdate(_prevProps) {
		if (_prevProps.model !== this.props.model && this.props.model) {
			this.setState(JSON.parse(this.props.model));
		}

		if (_prevProps.dbitem !== this.props.dbitem || _prevProps.dbitem?.finalImports !== this.props.dbitem?.finalImports) {
			console.log("Something changed in imports!")
			// Handle imported envmaker model
			for (const imp of this.props.dbitem.finalImports) {
				if (imp.model_type === 'envmaker') {
					const impModel = JSON.parse(imp.model_text);
					const importedMap = {
						mapWidth: impModel.mapWidth,
						mapHeight: impModel.mapHeight,
						boxes: impModel.boxes,
						nCols: impModel.nCols,
						nRows: impModel.nRows,
					};
					this.setState({ envmakerModel: importedMap });
					break;
				}
			}
		}
	}

	setMapDimensions(width, height, calculateMapWidthM) {
		const { resolution } = this.state;
		if (calculateMapWidthM) {
			this.setState({
				mapWidth: width,
				mapHeight: height,
				mapWidthM: width * resolution,
			});
		} else {
			this.setState({
				mapWidth: width,
				mapHeight: height,
			});
		}
	}

	changeSpinner(value) {
		this.setState({ spinnerOpen: value });
	}

	deleteMap() {
		this.setState({
			selectedMap: {
				name: null,
				map: null,
			},
		});
	}

	clearAllItems() {
		this.setState({ items: {} });
	}

	selectMap(map) {
		const { selectedMap, resolution, mapWidth, mapHeight } = this.state;

		const waters = [];
		if (selectedMap.name !== map.name) {
			for (const w of map.waters) {
				const x = (w.x / 100) * mapWidth;
				const y = (w.y / 100) * mapHeight;
				const x2 = (w.x2 / 100) * mapWidth;
				const y2 = (w.y2 / 100) * mapHeight;
				const rangePx = Math.sqrt((x - x2) * (x - x2) + (y - y2) * (y - y2));
				const range = rangePx * resolution;
				waters.push({ x: w.x, y: w.y, range });
			}
		}

		this.setState({
			selectedMap: {
				name: map.name,
				map: map.map,
				walls: map.walls,
			},
		}, () => this.addWaters(waters));
	}

	selectMission(mission) {
		const { mapWidth, mapHeight } = this.state;

		const newResolution = mission.mapWidthM / mapWidth;

		const waters = [];
		if (mission.map.length > 0 && 'waters' in mission.map[0]) {
			for (const w of mission.map[0].waters) {
				const x = (w.x / 100) * mapWidth;
				const y = (w.y / 100) * mapHeight;
				const x2 = (w.x2 / 100) * mapWidth;
				const y2 = (w.y2 / 100) * mapHeight;
				const rangePx = Math.sqrt((x - x2) * (x - x2) + (y - y2) * (y - y2));
				const range = rangePx * newResolution;
				waters.push({ x: w.x, y: w.y, range });
			}
		}

		this.setState({
			selectedMap: {
				name: (mission.map.length > 0) ? mission.map[0].name : null,
				map: (mission.map.length > 0) ? mission.map[0].map : null,
				walls: (mission.map.length > 0) ? mission.map[0].walls : [],
			},
			autoWalls: mission.autoWalls,
			items: mission.items,
			nextId: mission.nextId,
			resolution: newResolution,
			gridMeters: mission.gridMeters,
			gridDimension: (mapWidth * mission.gridMeters) / mission.mapWidthM,
			mapWidthM: mission.mapWidthM,
			places: mission.places || {},
			nextPlaceId: mission.nextPlaceId,
			goals: mission.goals,
			missionName: mission.name,
			temperature: mission.environment.temperature,
			humidity: mission.environment.humidity,
			luminosity: mission.environment.luminosity,
			loadedMission: mission.name,
		}, () => {
			this.addWaters(waters);
			this.completeItems();
		});
	}

	changeAutoWalls() {
		const { autoWalls } = this.state;
		this.setState({ autoWalls: !autoWalls });
	}

	addWaters(waters) {
		const { items, nextId } = this.state;
		let id = nextId;
		for (const w of waters) {
			items.general.water[id] = {
				id,
				itemName: `water_${id}`,
				itemCat: 'water',
				cat: 'general',
				x: w.x,
				y: w.y,
				range: Number.parseFloat(w.range.toFixed(2)),
				auto: true,
			};
			id += 1;
		}

		this.setState({ items, nextId: id });
	}

	newItem(cat, itemId, id, item) {
		const { items, nextId } = this.state;
		items[cat][itemId][id] = item;
		this.setState({ items, nextId: nextId + 1 });
	}

	updateItem(cat, itemId, id, state) {
		const { items } = this.state;
		items[cat][itemId][id] = state;
		this.setState({ items });

		const tmpState = this.state;
		tmpState.items = items;
		this.updateModel(JSON.stringify(tmpState));
	}

	deleteItem(cat, itemId, id) {
		const { items } = this.state;
		delete items[cat][itemId][id];
		this.setState({ items });

		const tmpState = this.state;
		tmpState.items = items;
		this.updateModel(JSON.stringify(tmpState));
	}

	changeGridMeters(value) {
		const { gridDimension, mapWidth } = this.state;
		this.setState({ resolution: value / gridDimension, gridMeters: value, mapWidthM: mapWidth * (value / gridDimension) });

		const tmpState = this.state;
		tmpState.resolution = value / gridDimension;
		tmpState.gridMeters = value;
		tmpState.mapWidthM = mapWidth * (value / gridDimension);
		this.updateModel(JSON.stringify(tmpState));
	}

	changeGridDimension(value) {
		const { gridMeters } = this.state;
		this.setState({ resolution: gridMeters / value, gridDimension: value });
	}

	clearItems() {
		const items = {};
		for (const avItemCat of Object.keys(availableItems)) {
			items[avItemCat] = {};
			for (const avItem of Object.keys(availableItems[avItemCat].components)) {
				items[avItemCat][avItem] = {};
			}
		}

		this.setState({ items, nextId: 1 });

		const tmpState = this.state;
		tmpState.items = items;
		this.updateModel(JSON.stringify(tmpState));
	}

	completeItems() {
		const { items } = this.state;
		for (const avItemCat of Object.keys(availableItems)) {
			if (!(avItemCat in items)) {
				items[avItemCat] = {};
			}

			for (const avItem of Object.keys(availableItems[avItemCat].components)) {
				if (!(avItem in items[avItemCat])) {
					items[avItemCat][avItem] = {};
				}
			}
		}

		this.setState({ items });
	}

	checkItemNameExists(itemName, initialName, category, categoryItem, itemId) {
		if (itemName === initialName) {
			return { matchesPattern: false, found: false };
		}

		const { items } = this.state;
		let found = false;
		let matchesPattern = false;
		for (const cat of Object.keys(items)) {
			for (const catItem of Object.keys(items[cat])) {
				const checkResult = itemName.match(new RegExp(`${categoriesPrefix[cat]}_${catItem}_[1-9]{1,3}`, 'g'));
				if (checkResult && checkResult[0] === itemName) {
					matchesPattern = true;
				}
			}
		}

		if (matchesPattern) {
			return { matchesPattern: true, found: false };
		}

		for (const cat of Object.keys(items)) {
			for (const catItem of Object.keys(items[cat])) {
				for (const item of Object.keys(items[cat][catItem])) {
					if (items[cat][catItem][item].itemName === itemName && !(cat === category && catItem === categoryItem && Number.parseInt(item, 10) === itemId)) {
						found = true;
					}
				}
			}
		}

		return { matchesPattern: false, found };
	}

	checkOverridenPlaces(dimensions) {
		const { places } = this.state;
		let isOverllaping = false;
		for (const pl of Object.keys(places)) {
			const xOverlap = valueInRange(dimensions.x, places[pl].dimensions.x, places[pl].dimensions.x + places[pl].dimensions.w) || valueInRange(places[pl].dimensions.x, dimensions.x, dimensions.x + dimensions.w);
			const yOverlap = valueInRange(dimensions.y, places[pl].dimensions.y - places[pl].dimensions.h, places[pl].dimensions.y) || valueInRange(places[pl].dimensions.y, dimensions.y - dimensions.h, dimensions.y);
			if (xOverlap && yOverlap) {
				isOverllaping = true;
			}
		}

		return isOverllaping;
	}

	newPlace(id, name, dimensions) {
		const isOverllaping = this.checkOverridenPlaces(dimensions);
		if (isOverllaping) {
			ToasterBottom.show({
				intent: 'danger',
				message: 'Places cannot be overlapping',
			});
		} else {
			const { places, nextPlaceId } = this.state;
			places[id] = { name, dimensions };
			this.setState({ places, nextPlaceId: nextPlaceId + 1 });
		}
	}

	updatePlace(id, name, dimensions) {
		const { places } = this.state;
		places[id] = { name, dimensions };
		this.setState({ places });
	}

	deletePlace(id) {
		const { places } = this.state;
		delete places[id];
		this.setState({ places });
	}

	clearPlaces() {
		this.setState({ places: {}, nextPlaceId: 1 });
	}

	changeAdvancedMode(ind) {
		const { goals } = this.state;
		goals[ind].advancedMode = !(goals[ind].advancedMode);
		this.setState({ goals });
	}

	checkPlaceNameExists(placeName, placeId) {
		const { places } = this.state;
		let found = false;
		let matchesPattern = false;
		const checkResult = placeName.match(new RegExp('place_[1-9]{1,3}', 'g'));
		if (checkResult && checkResult[0] === placeName && placeName !== `place_${placeId}`) {
			matchesPattern = true;
		}

		if (matchesPattern) {
			return { matchesPattern: true, found: false };
		}

		for (const place of Object.keys(places)) {
			if (places[place].name === placeName && !(Number.parseInt(place, 10) === Number.parseInt(placeId, 10))) {
				found = true;
			}
		}

		return { matchesPattern: false, found };
	}

	selectPlace(id) {
		this.setState({ selectedPlace: id });
	}

	reviewSelectPlace(id) {
		const { reviewSelectedPlace } = this.state;
		if (reviewSelectedPlace === id) {
			this.setState({ reviewSelectedPlace: null });
		} else {
			this.setState({ reviewSelectedPlace: id });
		}
	}

	openSavePopup() {
		this.setState({ namePopupOpen: true });
	}

	changeName(event) {
		this.setState({ missionName: event.target.value });
	}

	changeTemperature(value) {
		this.setState({ temperature: value });
	}

	changeHumidity(value) {
		this.setState({ humidity: value });
	}

	changeLuminosity(value) {
		this.setState({ luminosity: value });
	}

	convertToYaml() {
		const { mapWidth, mapHeight, resolution, items, autoWalls, selectedMap, places, temperature, humidity, luminosity } = this.state;
		const mission = missionToYaml(mapWidth, mapHeight, resolution, items, autoWalls, selectedMap.walls, places, temperature, humidity, luminosity);
		const yaml = jsYaml.dump(mission);
		fileDownload(yaml, 'mission.yaml');
	}

	test() {
		const { items, goals, places, mapWidth, mapHeight, resolution } = this.state;
		for (const [ind, g] of goals.entries()) {
			if (g.advancedMode) {
				// eslint-disable-next-line no-alert
				alert(g.code);
			} else {
				const result = goalConverter(g, items, places, mapWidth, mapHeight, resolution);
				if (result === null) {
					ToasterBottom.show({
						intent: 'danger',
						message: `Goal ${(g.name === '') ? `no.${ind + 1}` : g.name} has missing or invalid arguments`,
					});
				} else {
					// eslint-disable-next-line no-alert
					alert(result);
				}
			}
		}
	}

	render() {
		const { currentStep, spinnerOpen, selectedMap, autoWalls, items, nextId, resolution, gridMeters, gridDimension, places, nextPlaceId, selectedPlace, goals, reviewSelectedPlace, missionName, namePopupOpen, mapWidth, mapHeight, mapWidthM, temperature, humidity, luminosity, envmakerModel } = this.state;

		return ([
			<StyledBox key="mainbox">
				<StyledArea>
					<MenuRow>
						<MainMenu>
							<Typography mr={2}>{`Meters per grid square`}</Typography>
							<PrettoSlider
								aria-label="Always visible"
								value={gridMeters}
								color="secondary"
								min={1}
								max={50}
								step={0.1}
								sx={{
									width: "200px",
								}}
								onChange={(event) => this.changeGridMeters(event.target.value)}
							/>
							<Typography ml={2}>{`(${gridMeters}m)`}</Typography>
						</MainMenu>
					</MenuRow>
					<MainRow>
						{/* <OpenMap
                            key="openmap"
                            display={missionSteps[currentStep].id === 'map'}
                            changeSpinner={this.changeSpinner}
                            selectedMap={selectedMap}
                            deleteMap={this.deleteMap}
                            selectMap={this.selectMap}
                            mapWidth={mapWidth}
                            mapHeight={mapHeight}
                            mapWidthM={mapWidthM}
                            gridMeters={gridMeters}
                            setMapDimensions={this.setMapDimensions}
                            selectMission={this.selectMission}
                            changeGridDimension={this.changeGridDimension}
                        /> */}
						<SetSensorsObstacles
							key="sensorsobstacles"
							display
							selectedMap={selectedMap}
							autoWalls={autoWalls}
							changeAutoWalls={this.changeAutoWalls}
							selectMap={this.selectMap}
							nextId={nextId}
							items={items}
							newItem={this.newItem}
							updateItem={this.updateItem}
							deleteItem={this.deleteItem}
							resolution={resolution}
							gridMeters={gridMeters}
							gridDimension={gridDimension}
							changeGridMeters={this.changeGridMeters}
							changeGridDimension={this.changeGridDimension}
							checkItemNameExists={this.checkItemNameExists}
							mapWidth={mapWidth}
							mapHeight={mapHeight}
							mapWidthM={mapWidthM}
							setMapDimensions={this.setMapDimensions}
							clearItems={this.clearItems}
							envmakerModel={envmakerModel}
						/>
						{/* <SetPlaces
                            key="setplaces"
                            display={missionSteps[currentStep].id === 'places'}
                            selectedMap={selectedMap}
                            items={items}
                            gridDimension={gridDimension}
                            gridMeters={gridMeters}
                            places={places}
                            nextPlaceId={nextPlaceId}
                            newPlace={this.newPlace}
                            updatePlace={this.updatePlace}
                            deletePlace={this.deletePlace}
                            checkPlaceNameExists={this.checkPlaceNameExists}
                            selectedPlace={selectedPlace}
                            selectPlace={this.selectPlace}
                            mapWidth={mapWidth}
                            mapHeight={mapHeight}
                            mapWidthM={mapWidthM}
                            setMapDimensions={this.setMapDimensions}
                            changeGridDimension={this.changeGridDimension}
                        /> */}
					</MainRow>
				</StyledArea>
			</StyledBox>,
			<CustomSpinner key="spinner" isOpen={spinnerOpen} />,
		]);
	}
}

export default memo(CreateMission);

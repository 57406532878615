
import React from 'react';
import styled from 'styled-components';
import { Button, Icon } from '@blueprintjs/core';

const GreenButtonStyle = styled(Button)`
	color: white;
	background: #3c9764!important;
	border: 0px!important;
	box-shadow: none!important;
	border-radius: 5px;
	font-size: 16px;
	font-family: 'Roboto Slab', serif;
	:hover& {
		background: #338054!important;
	}
	&:active {
	  position: relative;
	  top: 1px;
	}
`;

const GreenBorderButtonStyle = styled(Button)`
	color: #3c9764;
	background: none!important;
	border: 2px solid #3c9764!important;
	box-shadow: none!important;
	border-radius: 5px;
	font-size: 16px;
	font-family: 'Roboto Slab', serif;
	&:hover:enabled {
		background: #3c9764!important;
		color: white!important;
	}
	&:active:enabled {
	  position: relative;
	  top: 1px;
	}
	&:disabled {
		background: #4d4d4d!important;
		color: grey!important;
		border: 0px!important;
	}
`;

const RedBorderButtonStyle = styled(Button)`
	color: white;
	background: none!important;
	border: 2px solid red!important;
	box-shadow: none!important;
	border-radius: 5px;
	font-size: 16px;
	font-family: 'Roboto Slab', serif;
	&:hover:enabled {
		background: red!important;
	}
	&:active:enabled {
	  position: relative;
	  top: 1px;
	}
	&:disabled {
		background: #4d4d4d!important;
		color: grey!important;
		border: 0px!important;
	}
`;

const RedBorderRedButtonStyle = styled(Button)`
	color: red!important;
	background: none!important;
	border: 2px solid red!important;
	box-shadow: none!important;
	border-radius: 5px;
	font-size: 16px;
	font-family: 'Roboto Slab', serif;
	&:hover:enabled {
		background: red!important;
		color: white!important;
	}
	&:active:enabled {
	  position: relative;
	  top: 1px;
	}
	&:disabled {
		background: #4d4d4d!important;
		color: grey!important;
		border: 0px!important;
	}
`;

const GreenBorderCollapseButtonStyle = styled(Button)`
	color: white;
	background: none!important;
	border: 2px solid #3c9764!important;
	box-shadow: none!important;
	border-radius: 5px;
	font-size: 16px;
	font-family: 'Roboto Slab', serif;
	cursor: pointer;
	padding: 0px;
	:hover& {
		background: #3c9764!important;
	}
`;

const PreviousButtonStyle = styled(Button)`
	width: ${(props) => `${props.width}px`};
	height: ${(props) => `${props.height}px`};
	cursor: ${(props) => ((props.disabled) ? 'not-allowed' : 'pointer')};
	color: white;
	background: #3c9764!important;
	box-shadow: none!important;
	font-size: 16px;
	font-family: 'Roboto Slab', serif;
	cursor: pointer;
	padding: 0px;
	position: relative;
	display: flex;
	border-radius: 0px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	&:hover:enabled {
		background: #338054!important;
	}
	&:after {
		position: absolute;
		content: " ";
		width: 0px;
		height: 0px;
		border-style: solid;
		border-color: transparent #3c9764 transparent transparent!important;
		border-width: ${(props) => `${props.height / 2}px ${props.height / 2}px ${props.height / 2}px 0px`};
		left: ${(props) => `-${props.height / 2}px`};
	}
	&:hover:enabled:after {
		border-color: transparent #338054 transparent transparent!important;
	}
	&:disabled {
		background: #595959!important;
		color: #030305!important;
		font-weight: bold;
	}
	&:disabled:after {
		border-color: transparent #595959 transparent transparent!important;
		color: #030305!important;
	}
	&:active:enabled {
	  position: relative;
	  top: 1px;
	}
`;

const NextButtonStyle = styled(Button)`
	width: ${(props) => `${props.width}px`};
	height: ${(props) => `${props.height}px`};
	cursor: ${(props) => ((props.disabled) ? 'not-allowed' : 'pointer')};
	color: white;
	background: #3c9764!important;
	box-shadow: none!important;
	font-size: 16px;
	font-family: 'Roboto Slab', serif;
	cursor: pointer;
	padding: 0px;
	position: relative;
	display: flex;
	border-radius: 0px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	&:hover:enabled {
		background: #338054!important;
	}
	&:after {
		position: absolute;
		content: " ";
		width: 0px;
		height: 0px;
		border-style: solid;
		border-color: transparent transparent transparent #3c9764!important;
		border-width: ${(props) => `${props.height / 2}px 0px ${props.height / 2}px ${props.height / 2}px`};
		right: ${(props) => `-${props.height / 2}px`};
	}
	&:hover:enabled:after {
		border-color: transparent transparent transparent #338054!important;
	}
	&:disabled {
		background: #595959!important;
		color: #030305!important;
		font-weight: bold;
	}
	&:disabled:after {
		border-color: transparent transparent transparent #595959!important;
		color: #030305!important;
	}
	&:active:enabled {
	  position: relative;
	  top: 1px;
	}
`;

export const GreenButton = ({
	children,
	type,
	disabled,
	loading,
	form,
	width,
	height,
	onClick,
	...props
}) => (
	<GreenButtonStyle
		type={type}
		disabled={disabled}
		form={form}
		loading={loading}
		style={{
			width: width || '120px',
			height: height || '35px',
			cursor: (disabled) ? 'not-allowed' : 'pointer',
			...props,
		}}
		intent="primary"
		onClick={onClick}
	>
		{children}
	</GreenButtonStyle>
);

export const GreenBorderButton = ({
	children,
	type,
	disabled,
	form,
	width,
	height,
	onClick,
	rightIcon,
	...props
}) => (
	<GreenBorderButtonStyle
		type={type}
		disabled={disabled}
		form={form}
		rightIcon={rightIcon}
		style={{
			width: width || '120px',
			height: height || '35px',
			cursor: (disabled) ? 'not-allowed' : 'pointer',
			...props,
		}}
		intent="primary"
		onClick={onClick}
	>
		{children}
	</GreenBorderButtonStyle>
);

export const GreenBorderGreenButton = ({
	children,
	type,
	disabled,
	form,
	width,
	height,
	onClick,
	rightIcon,
	...props
}) => (
	<GreenBorderButtonStyle
		type={type}
		disabled={disabled}
		form={form}
		rightIcon={rightIcon}
		style={{
			width: width || '120px',
			height: height || '35px',
			cursor: (disabled) ? 'not-allowed' : 'pointer',
			...props,
		}}
		intent="primary"
		onClick={onClick}
	>
		{children}
	</GreenBorderButtonStyle>
);

export const RedBorderButton = ({
	children,
	type,
	disabled,
	form,
	width,
	height,
	onClick,
	rightIcon,
	...props
}) => (
	<RedBorderButtonStyle
		type={type}
		disabled={disabled}
		form={form}
		rightIcon={rightIcon}
		style={{
			width: width || '120px',
			height: height || '35px',
			cursor: (disabled) ? 'not-allowed' : 'pointer',
			...props,
		}}
		intent="primary"
		onClick={onClick}
	>
		{children}
	</RedBorderButtonStyle>
);

export const RedBorderRedButton = ({
	children,
	type,
	disabled,
	form,
	width,
	height,
	onClick,
	rightIcon,
	...props
}) => (
	<RedBorderRedButtonStyle
		type={type}
		disabled={disabled}
		form={form}
		rightIcon={rightIcon}
		style={{
			width: width || '120px',
			height: height || '35px',
			cursor: (disabled) ? 'not-allowed' : 'pointer',
			...props,
		}}
		intent="primary"
		onClick={onClick}
	>
		{children}
	</RedBorderRedButtonStyle>
);

export const GreenBorderCollapseButton = ({
	id,
	children,
	width,
	height,
	onClick,
	icon,
	...props
}) => {
	const h = (height) ? Number.parseInt(height.split('px')[0], 10) : 35;
	return (
		<GreenBorderCollapseButtonStyle
			id={id}
			className="collapse-button"
			style={{
				width: width || '120px',
				height: height || '35px',
				...props,
			}}
			intent="primary"
			onClick={onClick}
			onMouseOver={() => { document.getElementById(`${id}_icon`).style.color = 'white'; }}
			onMouseOut={() => { document.getElementById(`${id}_icon`).style.color = '#3c9764'; }}
		>
			<div style={{
				width: `calc(100% - ${h}px)`, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
			}}
			>
				{children}
			</div>
			<div
				id={`${id}_icon`}
				style={{
					width: `${h}px`, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', borderLeft: '2px solid #3c9764', color: '#3c9764',
				}}
			>
				<Icon icon={icon} iconSize="22" />
			</div>
		</GreenBorderCollapseButtonStyle>
	);
};

export const PreviousGreenButton = ({
	children,
	disabled,
	width,
	height,
	onClick,
	...props
}) => (
	<PreviousButtonStyle
		disabled={disabled}
		width={width || '120'}
		height={height || '35'}
		style={{ ...props }}
		intent="primary"
		onClick={onClick}
	>
		{children}
	</PreviousButtonStyle>
);

export const NextGreenButton = ({
	children,
	disabled,
	width,
	height,
	onClick,
	...props
}) => (
	<NextButtonStyle
		disabled={disabled}
		width={width || '120'}
		height={height || '35'}
		style={{ ...props }}
		intent="primary"
		onClick={onClick}
	>
		{children}
	</NextButtonStyle>
);

import appleTrees from '../../../assets/images/background/farm/apple_trees.png';
import cows from '../../../assets/images/background/farm/cows.png';
import field from '../../../assets/images/background/farm/field.png';
import fieldHall from '../../../assets/images/background/farm/field_hall.png';
import fieldRows from '../../../assets/images/background/farm/field_rows.png';
import fieldTractor from '../../../assets/images/background/farm/field_tractor.png';
import fieldUp from '../../../assets/images/background/farm/field_up.png';
import fieldUpLeft from '../../../assets/images/background/farm/field_up_left.png';
import garden from '../../../assets/images/background/farm/garden.png';
import greenhouses from '../../../assets/images/background/farm/greenhouses.png';
import photovoltaic from '../../../assets/images/background/farm/photovoltaic.png';
import start from '../../../assets/images/background/farm/start.png';
import street from '../../../assets/images/background/farm/street.png';
import streetCorner from '../../../assets/images/background/farm/street_corner.png';
import streetLake from '../../../assets/images/background/farm/street_lake.png';
import streetStraight from '../../../assets/images/background/farm/street_straight.png';
import streetT from '../../../assets/images/background/farm/street_t.png';
import trees from '../../../assets/images/background/farm/trees.png';

const farm = {
	name: 'Farm',
	components: {
		apple_trees: {
			id: 'apple_trees',
			image: appleTrees,
			text: 'Apple Trees',
			walls: [
				{ x1: 4, y1: 100, x2: 36, y2: 100 },
				{ x1: 36, y1: 100, x2: 36, y2: 71 },
				{ x1: 63, y1: 71, x2: 63, y2: 100 },
				{ x1: 63, y1: 100, x2: 95, y2: 100 },
				{ x1: 95, y1: 100, x2: 95, y2: 5 },
				{ x1: 4, y1: 5, x2: 95, y2: 5 },
				{ x1: 4, y1: 5, x2: 4, y2: 100 },
			],
			waters: [],
		},
		cows: {
			id: 'cows',
			image: cows,
			text: 'Cows',
			walls: [
				{ x1: 4, y1: 100, x2: 36, y2: 100 },
				{ x1: 36, y1: 100, x2: 36, y2: 71 },
				{ x1: 63, y1: 71, x2: 63, y2: 100 },
				{ x1: 63, y1: 100, x2: 95, y2: 100 },
				{ x1: 95, y1: 100, x2: 95, y2: 5 },
				{ x1: 4, y1: 5, x2: 95, y2: 5 },
				{ x1: 4, y1: 5, x2: 4, y2: 100 },
			],
			waters: [],
		},
		field: {
			id: 'field',
			image: field,
			text: 'Field',
			walls: [],
			waters: [],
		},
		fieldHall: {
			id: 'fieldHall',
			image: fieldHall,
			text: 'Field Hall',
			walls: [
				{ x1: 36, y1: 0, x2: 36, y2: 36 },
				{ x1: 36, y1: 36, x2: 0, y2: 36 },
				{ x1: 0, y1: 64, x2: 36, y2: 64 },
				{ x1: 36, y1: 64, x2: 36, y2: 100 },
			],
			waters: [],
		},
		fieldRows: {
			id: 'fieldRows',
			image: fieldRows,
			text: 'Field Rows',
			walls: [],
			waters: [],
		},
		fieldTractor: {
			id: 'fieldTractor',
			image: fieldTractor,
			text: 'Field Tractor',
			walls: [],
			waters: [],
		},
		fieldUp: {
			id: 'fieldUp',
			image: fieldUp,
			text: 'Field Up',
			walls: [{ x1: 0, y1: 64, x2: 100, y2: 64 }],
			waters: [],
		},
		fieldUpLeft: {
			id: 'fieldUpLeft',
			image: fieldUpLeft,
			text: 'Field Up Left',
			walls: [
				{ x1: 36, y1: 0, x2: 36, y2: 64 },
				{ x1: 36, y1: 64, x2: 100, y2: 64 },
			],
			waters: [],
		},
		garden: {
			id: 'garden',
			image: garden,
			text: 'Garden',
			walls: [
				{ x1: 4, y1: 100, x2: 36, y2: 100 },
				{ x1: 36, y1: 100, x2: 36, y2: 71 },
				{ x1: 63, y1: 71, x2: 63, y2: 100 },
				{ x1: 63, y1: 100, x2: 95, y2: 100 },
				{ x1: 95, y1: 100, x2: 95, y2: 5 },
				{ x1: 4, y1: 5, x2: 95, y2: 5 },
				{ x1: 4, y1: 5, x2: 4, y2: 100 },
			],
			waters: [],
		},
		greenhouses: {
			id: 'greenhouses',
			image: greenhouses,
			text: 'Greenhouses',
			walls: [
				{ x1: 4, y1: 100, x2: 36, y2: 100 },
				{ x1: 36, y1: 100, x2: 36, y2: 71 },
				{ x1: 63, y1: 71, x2: 63, y2: 100 },
				{ x1: 63, y1: 100, x2: 95, y2: 100 },
				{ x1: 95, y1: 100, x2: 95, y2: 5 },
				{ x1: 4, y1: 5, x2: 95, y2: 5 },
				{ x1: 4, y1: 5, x2: 4, y2: 100 },
			],
			waters: [],
		},
		photovoltaic: {
			id: 'photovoltaic',
			image: photovoltaic,
			text: 'Photovoltaic',
			walls: [
				{ x1: 4, y1: 100, x2: 36, y2: 100 },
				{ x1: 36, y1: 100, x2: 36, y2: 71 },
				{ x1: 63, y1: 71, x2: 63, y2: 100 },
				{ x1: 63, y1: 100, x2: 95, y2: 100 },
				{ x1: 95, y1: 100, x2: 95, y2: 5 },
				{ x1: 4, y1: 5, x2: 95, y2: 5 },
				{ x1: 4, y1: 5, x2: 4, y2: 100 },
			],
			waters: [],
		},
		start: {
			id: 'start',
			image: start,
			text: 'Start',
			walls: [
				{ x1: 4, y1: 100, x2: 36, y2: 100 },
				{ x1: 36, y1: 100, x2: 36, y2: 71 },
				{ x1: 63, y1: 71, x2: 63, y2: 100 },
				{ x1: 63, y1: 100, x2: 95, y2: 100 },
				{ x1: 95, y1: 100, x2: 95, y2: 5 },
				{ x1: 4, y1: 5, x2: 95, y2: 5 },
				{ x1: 4, y1: 5, x2: 4, y2: 100 },
			],
			waters: [],
		},
		street: {
			id: 'street',
			image: street,
			text: 'Street',
			walls: [
				{ x1: 0, y1: 36, x2: 36, y2: 36 },
				{ x1: 36, y1: 36, x2: 36, y2: 0 },
				{ x1: 0, y1: 64, x2: 36, y2: 64 },
				{ x1: 36, y1: 64, x2: 36, y2: 100 },
				{ x1: 64, y1: 100, x2: 64, y2: 64 },
				{ x1: 64, y1: 64, x2: 100, y2: 64 },
				{ x1: 100, y1: 36, x2: 64, y2: 36 },
				{ x1: 64, y1: 36, x2: 64, y2: 0 },
			],
			waters: [],
		},
		street_corner: {
			id: 'street_corner',
			image: streetCorner,
			text: 'Street Corner',
			walls: [
				{ x1: 36, y1: 0, x2: 36, y2: 36 },
				{ x1: 36, y1: 36, x2: 64, y2: 64 },
				{ x1: 64, y1: 64, x2: 100, y2: 64 },
				{ x1: 64, y1: 0, x2: 64, y2: 24 },
				{ x1: 64, y1: 24, x2: 76, y2: 36 },
				{ x1: 76, y1: 36, x2: 100, y2: 36 },
			],
			waters: [],
		},
		streetLake: {
			id: 'streetLake',
			image: streetLake,
			text: 'Street Lake',
			walls: [
				{ x1: 0, y1: 36, x2: 20, y2: 41 },
				{ x1: 20, y1: 41, x2: 80, y2: 41 },
				{ x1: 80, y1: 41, x2: 100, y2: 36 },
				{ x1: 0, y1: 64, x2: 20, y2: 59 },
				{ x1: 20, y1: 59, x2: 80, y2: 59 },
				{ x1: 80, y1: 59, x2: 100, y2: 64 },
				{ x1: 28, y1: 77, x2: 30, y2: 91 },
				{ x1: 30, y1: 91, x2: 42, y2: 95 },
				{ x1: 42, y1: 95, x2: 59, y2: 94 },
				{ x1: 59, y1: 94, x2: 68, y2: 69 },
				{ x1: 68, y1: 69, x2: 56, y2: 63 },
				{ x1: 56, y1: 63, x2: 47, y2: 69 },
				{ x1: 47, y1: 69, x2: 36, y2: 65 },
				{ x1: 36, y1: 65, x2: 28, y2: 77 },
			],
			waters: [{ x: 48, y: 80, range: 15 }],
		},
		street_straight: {
			id: 'street_straight',
			image: streetStraight,
			text: 'Street Straight',
			walls: [
				{ x1: 0, y1: 36, x2: 100, y2: 36 },
				{ x1: 0, y1: 64, x2: 100, y2: 64 },
			],
			waters: [],
		},
		street_t: {
			id: 'street_t',
			image: streetT,
			text: 'Street T',
			walls: [
				{ x1: 0, y1: 36, x2: 36, y2: 36 },
				{ x1: 36, y1: 36, x2: 36, y2: 0 },
				{ x1: 0, y1: 64, x2: 100, y2: 64 },
				{ x1: 100, y1: 36, x2: 64, y2: 36 },
				{ x1: 64, y1: 36, x2: 64, y2: 0 },
			],
			waters: [],
		},
		trees: {
			id: 'trees',
			image: trees,
			text: 'Trees',
			walls: [],
			waters: [],
		},
	},
};

export default farm;

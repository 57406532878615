
import { Popover } from '@blueprintjs/core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

const WallDiv = styled.div`
    width: ${(props) => `${Math.sqrt(((props.x2 - props.x1) ** 2) + ((props.y2 - props.y1) ** 2))}px`};
    height: 5px;
    background: #3c9764;
    transform: ${(props) => `rotate(${-1 * ((Math.atan2(props.y2 - props.y1, props.x2 - props.x1) * 180) / Math.PI)}deg)`};
    transform-origin: left center;
    z-index: -1;
`;

const WallOutDiv = styled.div`
    display: block;
    position: absolute;
    left: ${(props) => `${props.x1}px`};
    bottom: ${(props) => `${props.y1 - 2.5}px`};
    cursor: pointer;
    height: 5px;
`;

const BasicPopover = ({ className, popoverOpen, closePopover, deleteItem }) => (
	<Popover usePortal popoverClassName={`${className} item-popover`} isOpen={popoverOpen} canEscapeKeyClose={false} onClose={closePopover}>
		<button type="button" style={{ display: 'none' }}>{"a"}</button>
		<div
			style={{
				width: '50px', height: '40px', background: 'black', padding: '10px', color: 'white',
			}}
		>
			<div style={{ width: '100%', height: '20px', display: 'flex', justifyContent: 'space-around' }}>
				<FontAwesomeIcon icon={faTrash} style={{ fontSize: '20px', cursor: 'pointer' }} onClick={deleteItem} />
			</div>
		</div>
	</Popover>
);

const CustomPopover = styled(BasicPopover)`
    transform: ${(props) => `translate(${(props.x2n - props.x1n) / 2}px, ${-(props.y2n - props.y1n) / 2}px)`};
    border-radius: 10px;
`;

class AutoWall extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			id: props.id,
			x1: props.x1,
			y1: props.y1,
			x2: props.x2,
			y2: props.y2,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
			popoverOpen: false,
		};
		this.deleteItem = props.deleteItem;

		this.onClick = this.onClick.bind(this);
		this.delete = this.delete.bind(this);
		this.transformDimensions = this.transformDimensions.bind(this);
	}

	static getDerivedStateFromProps(props) {
		return {
			id: props.id,
			x1: props.x1,
			y1: props.y1,
			x2: props.x2,
			y2: props.y2,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
		};
	}

	onClick() {
		this.setState({ popoverOpen: true });
	}

	delete() {
		const { id } = this.state;
		this.deleteItem(id);
	}

	transformDimensions() {
		const { x1, y1, x2, y2, mapWidth, mapHeight } = this.state;
		const x1n = (x1 / 100) * mapWidth;
		const y1n = (y1 / 100) * mapHeight;
		const x2n = (x2 / 100) * mapWidth;
		const y2n = (y2 / 100) * mapHeight;
		return { x1n, y1n, x2n, y2n };
	}

	render() {
		const { id, popoverOpen } = this.state;

		const { x1n, y1n, x2n, y2n } = this.transformDimensions();
		return (
			<WallOutDiv x1={x1n} y1={y1n} onClick={this.onClick}>
				<WallDiv id={`barrier_${id}`} x1={x1n} y1={y1n} x2={x2n} y2={y2n} />
				<CustomPopover
					x1n={x1n}
					y1n={y1n}
					x2n={x2n}
					y2n={y2n}
					popoverOpen={popoverOpen}
					closePopover={() => { this.setState({ popoverOpen: false }); }}
					deleteItem={this.delete}
				/>
			</WallOutDiv>
		);
	}
}

export default AutoWall;


/* eslint-disable max-len */

import {
	EditableText, NumericInput, Popover, Tooltip,
} from '@blueprintjs/core';
import { faTrash, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';
import { ToasterBottom } from "../../../lib_socioty/toaster.js";
import light from '../../../../assets/images/missionItems/effectors/light.png';

import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const PrettoSlider = styled(Slider)({
	color: '#52af77',
	height: 8,
	'& .MuiSlider-track': {
		border: 'none',
	},
	'& .MuiSlider-thumb': {
		height: 24,
		width: 24,
		backgroundColor: '#fff',
		border: '2px solid currentColor',
		'&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
			boxShadow: 'inherit',
		},
		'&:before': {
			display: 'none',
		},
	},
	'& .MuiSlider-valueLabel': {
		lineHeight: 0.5,
		fontSize: 11,
		background: 'unset',
		padding: 0,
		width: 30,
		height: 30,
		borderRadius: '50% 50% 50% 0',
		backgroundColor: '#52af77',
		transformOrigin: 'bottom left',
		transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
		'&:before': { display: 'none' },
		'&.MuiSlider-valueLabelOpen': {
			transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
		},
		'& > *': {
			transform: 'rotate(45deg)',
		},
	},
});

const ItemDiv = styled.div`
	width: ${(props) => `${props.elementDimensions}px`};
	height: ${(props) => `${props.elementDimensions}px`};
	border-radius: ${(props) => `${props.elementDimensions}px`};
	position: absolute;
	left: ${(props) => `calc(${props.x}% - ${(props.elementDimensions / 2)}px)`};
	bottom: ${(props) => `calc(${props.y}% - ${(props.elementDimensions / 2)}px)`};
	cursor: ${(props) => ((props.dragging) ? 'move' : 'pointer')};
	display: flex;
	transform: ${(props) => (props.dragging ? '' : 'none!important')};
`;

const BasicPopover = ({ className, elementDimensions, popoverOpen, closePopover, changeName, checkName, itemName, changeLuminosity, luminosity, changeRange, range, deleteItem, mapWidth, mapResolution }) => (
	<Popover usePortal popoverClassName={`${className} item-popover`} elementDimensions={elementDimensions} isOpen={popoverOpen}>
		<div style={{
			width: '300px', padding: '10px', color: 'white', background: 'black', boxShadow: '4px 4px 10px 1px #333333',
		}}
		>
			{/* Name */}
			<div style={{
				width: '100%', maxWidth: '100%', paddingLeft: '1px', paddingRight: '1px', display: 'flex', justifyContent: 'space-around', paddingBottom: '10px',
			}}
			>
				<TextField
					value={itemName}
					label="Name"
					variant="filled"
					sx={{
						backgroundColor: '#aaa',
						mx: 1,
						width: '100%',
					}}
					size="small"
					color="success"
					onChange={(event) => changeName(event.target.value)}
				/>
			</div>

			<div style={{
				width: '100%', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center',
			}}
			>
				<Typography color="#ffffff" variant="caption" mr={1}>
					{`Luminocity (${Number(luminosity.toFixed(2))}%)`}
				</Typography>
				<PrettoSlider
					aria-label="Always visible"
					value={Number(luminosity.toFixed(2))}
					color="secondary"
					min={0}
					max={100}
					step={1}
					sx={{
						width: "150px",
					}}
					onChange={(event) => changeLuminosity(event.target.value)}
				/>
			</div>

			{/* Range */}
			<div style={{
				width: '100%', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center',
			}}
			>
				<Typography color="#ffffff" variant="caption" mr={1}>
					{`Range (${Number(range.toFixed(2))}m)`}
				</Typography>
				<PrettoSlider
					aria-label="Always visible"
					value={Number(range.toFixed(2))}
					color="secondary"
					min={0}
					max={mapWidth * mapResolution}
					step={1}
					sx={{
						width: "150px",
					}}
					onChange={(event) => changeRange(event.target.value)}
				/>
			</div>

			{/* Controls */}
			<div style={{
				width: '100%', height: '20px', display: 'flex', justifyContent: 'space-around', marginTop: '5px',
			}}
			>
				<FontAwesomeIcon icon={faTrash} style={{ fontSize: '20px', cursor: 'pointer' }} onClick={deleteItem} />
				<FontAwesomeIcon icon={faXmarkCircle} style={{ fontSize: '20px', cursor: 'pointer' }} onClick={closePopover} />
			</div>
		</div>
	</Popover>
);

const CustomPopover = styled(BasicPopover)`
	transform: ${(props) => `translate(${-(props.elementDimensions / 2)}px, 0px)`};
	border-radius: 10px;
`;

class Light extends React.Component {
	constructor(props) {
		super(props);

		const initialState = props.state;
		this.cat = initialState.cat;
		this.itemCat = initialState.itemCat;
		this.updateItem = props.updateItem;
		this.deleteItem = props.deleteItem;
		this.checkItemNameExists = props.checkItemNameExists;

		this.state = {
			id: initialState.id,
			itemName: initialState.itemName,
			x: initialState.x,
			y: initialState.y,
			luminosity: initialState.luminosity,
			range: initialState.range,
			elementDimensions: props.elementDimensions,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
			dragging: false,
			popoverOpen: false,
			resolution: props.resolution,
			metersPopupOpen: false,
			xMeters: 0,
			yMeters: 0,
			displayRange: false,
		};
		this.interval = null;
		this.initialName = initialState.itemName;

		this.onStart = this.onStart.bind(this);
		this.onDrag = this.onDrag.bind(this);
		this.onStop = this.onStop.bind(this);
		this.onClick = this.onClick.bind(this);
		this.delete = this.delete.bind(this);
		this.sendUpdate = this.sendUpdate.bind(this);
		this.changeName = this.changeName.bind(this);
		this.checkName = this.checkName.bind(this);
		this.changeLuminosity = this.changeLuminosity.bind(this);
		this.changeRange = this.changeRange.bind(this);
	}

	componentDidMount() {
		const { itemName, x, y, luminosity, range } = this.state;
		this.sendUpdate(itemName, x, y, luminosity, range);
	}

	static getDerivedStateFromProps(props) {
		const initialState = props.state;
		return {
			id: initialState.id,
			itemName: initialState.itemName,
			x: initialState.x,
			y: initialState.y,
			luminosity: initialState.luminosity,
			range: initialState.range,
			elementDimensions: props.elementDimensions,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
			resolution: props.resolution,
		};
	}

	onStart(event) {
		event.preventDefault();
		const { x, y, mapWidth, mapHeight, resolution } = this.state;
		const xMeters = ((x / 100) * mapWidth) * resolution;
		const yMeters = ((y / 100) * mapHeight) * resolution;
		this.setState({ metersPopupOpen: true, xMeters, yMeters, displayRange: false });
	}

	onDrag(event, data) {
		event.preventDefault();
		const { x, y, mapWidth, mapHeight, resolution } = this.state;
		const xMeters = (((x / 100) * mapWidth) + data.x) * resolution;
		const yMeters = (((y / 100) * mapHeight) - data.y) * resolution;
		this.setState({ dragging: true, xMeters, yMeters, displayRange: false });
	}

	onStop(event, data) {
		event.preventDefault();
		const { dragging } = this.state;
		this.setState({ dragging: false, metersPopupOpen: false });
		if (!dragging) {
			this.onClick();
		}

		const { itemName, x, y, luminosity, range, mapWidth, mapHeight } = this.state;
		let newX = ((((x / 100) * mapWidth) + data.x) / mapWidth) * 100;
		if (newX > 100) {
			newX = 100;
		}

		if (newX < 0) {
			newX = 0;
		}

		let newY = ((((y / 100) * mapHeight) - data.y) / mapHeight) * 100;
		if (newY > 100) {
			newY = 100;
		}

		if (newY < 0) {
			newY = 0;
		}

		this.sendUpdate(itemName, newX, newY, luminosity, range);
	}

	onClick() {
		this.setState({ popoverOpen: true });
	}

	delete() {
		const { id } = this.state;
		this.deleteItem(this.cat, this.itemCat, id);
	}

	sendUpdate(itemName, x, y, luminosity, range) {
		const { id } = this.state;
		this.updateItem(this.cat, this.itemCat, id, {
			id, itemName, x, y, luminosity, range, cat: this.cat, itemCat: this.itemCat,
		});
	}

	changeName(value) {
		const { x, y, luminosity, range } = this.state;
		this.sendUpdate(value.toLowerCase().split(' ').join('_'), x, y, luminosity, range);
	}

	checkName(value) {
		const { id } = this.state;
		const { matchesPattern, found } = this.checkItemNameExists(value, this.initialName, this.cat, this.itemCat, id);
		if (matchesPattern) {
			ToasterBottom.show({
				intent: 'danger',
				message: 'Name cannot follow the template category_number',
			});
			const { x, y, luminosity, range } = this.state;
			this.sendUpdate(this.initialName, x, y, luminosity, range);
		} else if (found) {
			ToasterBottom.show({
				intent: 'danger',
				message: 'Name already exists',
			});
			const { x, y, luminosity, range } = this.state;
			this.sendUpdate(this.initialName, x, y, luminosity, range);
		}
	}

	changeLuminosity(value) {
		const { itemName, x, y, range } = this.state;
		this.sendUpdate(itemName, x, y, value, range);
	}

	changeRange(value) {
		const { itemName, x, y, luminosity } = this.state;
		this.sendUpdate(itemName, x, y, luminosity, value);
	}

	render() {
		const { id, itemName, x, y, luminosity, range, resolution, elementDimensions, dragging, popoverOpen, metersPopupOpen, xMeters, yMeters, displayRange, mapWidth } = this.state;

		return (
			<Draggable
				key={`light_${id}`}
				allowAnyClick={false}
				grid={[10, 10]}
				position={{ x: 0, y: 0 }}
				handle=".itemhandler"
				onStart={this.onStart}
				onDrag={this.onDrag}
				onStop={this.onStop}
			>
				<ItemDiv id={`item_${id}`} x={x} y={y} elementDimensions={elementDimensions} dragging={dragging}>
					<Tooltip
						popoverClassName="item-info-tooltip"
						disabled={dragging || popoverOpen}
						content={(
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									textAlign: 'center',
									maxWidth: '200px',
									backgroundColor: "rgba(0, 0, 0, 0.8)",
									padding: '10px',
									borderRadius: '10px',
									color: 'white',
								}}
							>
								<div style={{ fontWeight: 'bold', fontSize: '16px' }}>
									{"Luminosity\r"}
								</div>
								<div style={{ textAlign: 'center', height: 'fit-content', wordBreak: 'break-word' }}>
									{`${luminosity}%`}
								</div>
								<div style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '10px' }}>
									{"Range\r"}
								</div>
								<div style={{ textAlign: 'center', height: 'fit-content', wordBreak: 'break-word' }}>
									{`${range}m`}
								</div>
							</div>
						)}
						interactionKind="hover"
						onOpening={() => this.setState({ displayRange: true })}
						onClosing={() => this.setState({ displayRange: false })}
					>
						<>
							<img
								key={Math.random()}
								id={`itemImg_${id}`}
								className="itemhandler"
								src={light}
								alt=""
								draggable={false}
								style={{ maxWidth: '100%', maxHeight: '100%' }}
							/>
							{(popoverOpen || displayRange)
								&& (
									<div
										style={{
											width: `${((2 * range) / resolution)}px`,
											height: `${((2 * range) / resolution)}px`,
											borderRadius: `${((2 * range) / resolution)}px`,
											background: 'rgba(250, 160, 0, 0.4)',
											position: 'absolute',
											top: `${-(range / resolution) + elementDimensions / 2}px`,
											left: `${-(range / resolution) + elementDimensions / 2}px`,
											filter: 'blur(5px)',
											zIndex: -1,
											animation: 'areaAlarmAnimation 1s linear infinite',
										}}
									/>
								)}
						</>
					</Tooltip>
					<div
						style={{
							display: (metersPopupOpen) ? 'block' : 'none', width: 'max-content', paddingLeft: '5px', paddingRight: '5px', height: '20px', position: 'absolute', top: `-${(elementDimensions / 2) + 5}px`, left: `${(elementDimensions / 2) - (145 / 2)}px`, fontSize: '14px', textAlign: 'center', background: '#7a8585', borderRadius: '10px', color: 'white',
						}}
					>
						{`x: ${xMeters.toFixed(3)}m, y: ${yMeters.toFixed(3)}m`}
					</div>
					{popoverOpen && (
						<CustomPopover
							elementDimensions={elementDimensions}
							popoverOpen={popoverOpen}
							closePopover={() => { this.setState({ popoverOpen: false }); }}
							changeName={this.changeName}
							checkName={this.checkName}
							itemName={itemName}
							changeLuminosity={this.changeLuminosity}
							luminosity={luminosity}
							changeRange={this.changeRange}
							range={range}
							deleteItem={this.delete}
							mapWidth={mapWidth}
							mapResolution={resolution}
						/>
					)}
				</ItemDiv>
			</Draggable>
		);
	}
}

const createLight = (state, resolution, elementDimensions, mapWidth, mapHeight, updateItem, deleteItem, checkItemNameExists) => (
	<Light key={state.id} state={state} resolution={resolution} elementDimensions={elementDimensions} mapWidth={mapWidth} mapHeight={mapHeight} updateItem={updateItem} deleteItem={deleteItem} checkItemNameExists={checkItemNameExists} />
);

export default createLight;

export const smautoExample = `MQTT:
    name: home_broker
    host: "localhost"
    port: 1883
    auth:
        username: "george"
        password: "georgesPassword"

Entity:
    name: weather_station
    type: sensor
    topic: "porch.weather_station"
    broker: home_broker
    attributes:
        - temperature: float
        - humidity: int

Entity:
    name: aircondition
    type: actuator
    topic: "bedroom.aircondition"
    broker: home_broker
    attributes:
        - temperature: float
        - mode: str
        - on: bool

Automation:
    name: start_aircondition
    condition:
        (weather_station.temperature > 32) AND
        (aircondition.on is true)
    enabled: true
    continuous: false
    actions:
        - aircondition.temperature:  25.0
        - aircondition.mode:  "cool"
        - aircondition.on:  true
`;

export const demolExample = `Network
	ssid: "Guest_Network_2.4GHz"
	passwd: "guest"
end

Communication<MQTT>
	host: "node.mqtt.local"
	port: 1885
	auth:
		username: "guest"
		password: "guest"
end

Connection SonarESP32
	board: ESP32Wroom32
	peripheral: SonarSRF04
	powerConnections:
	- ESP32Wroom32.gnd_1 -- SonarSRF04.gnd
	- ESP32Wroom32.power_5v -- SonarSRF04.vcc
	ioConnections:
	- gpio: ESP32Wroom32.p_13 -- SonarSRF04.trigger
	- gpio: ESP32Wroom32.p_14 -- SonarSRF04.echo
	endpoint:
		topic: "my_esp.sensors.srf04"
		msg: Distance
		frequency: 5 hz
end

Connection Bme680Esp32
	board: ESP32Wroom32
	peripheral: BME680
	powerConnections:
	- ESP32Wroom32.gnd_1 -- BME680.gnd
	- ESP32Wroom32.power_5v -- BME680.vcc
	ioConnections:
	- i2c:
		sda: ESP32Wroom32.p_21 -- BME680.sda
		scl: ESP32Wroom32.p_22 -- BME680.scl
		slave_address: 0x76
	endpoint:
		topic: "my_esp.sensors.bme680"
		msg: Env
		frequency: 2 hz
end
`;

export const smautoShortDescription = `
![SmartHomeImage](https://raw.githubusercontent.com/robotics-4-all/smauto/main/assets/images/smauto_logo.png)

## Description
Smart environments are becoming quite popular in the home setting consisting of a broad range of connected devices. While offering a novel set of possibilities, this also contributes to the complexity of the environment, posing new challenges to allowing the full potential of a sensorized home to be made available to users.

SmAuto is a Domain Specific Language (DSL) that enables users to program complex 
automation scenarios, for connected IoT devices in smart environments,
that go beyond simple tasks. 

The DSL is developed using Python and TextX and follows the model interpretation
and executable models paradigms. It includes a meta-model and a grammar, that is
specialized for smart environments, while it also provides the following features:

- **Command-line Interface**. Used to call the validator, the interpreter and the 
code generator explicitely.
- **REST Api**. The DSL implements a REST Api, that can be utilized to remotely call
the validator, the interpreter and the code generator on demand. ALso usefull for
integrating the language in bigger projects and cloud-based platforms.
- **Dynamically compile and execute models**. Model classes are constructed at runtime
and are enhanced with platform-specific code that implements the logic. This process is
executed by the language interpreter.
- **Generate Virtual Entities**. A code generator is provided that transforms
Entity model definitions into executable code with enhanced value generation with
optional noise functions applied on. This can be very usefull to automatically
generate the source code of virtual entities which simulate the behaviour of physical
sensors.
- **Generate Visualization graphs of Automations**. A generator is provided 
which takes a model as input and generates an image
of the automation graph.
`;

export const demolShortDescription = `
# demol
Device Modeling Language (DeMoL) - A DSL for modeling IoT devices.
Enables automated source code generation currently for RaspberryPi and RiotOS.

## Usage

The implementation of the grammar of the language can be found [here]().

The core Concepts of the language are:

- Device
- Board
- Peripheral
- Synthesis
- Connection
- CommunicationTransport

The language allows multi-file model imports and also uses a global local
repository for loading and referencing existing Board, Peripheral and Synthesis models.
`;

const smautoMQTTExample = `MQTT:
	name: 
	host: ""
	port: 
	auth:
		username: ""
		password: ""
`;

const smautoAMQPExample = `AMQP:
	name: 
	host: ""
	port: 
	auth:
		username: ""
		password: ""
`;

const smautoRedisExample = `AMQP:
	name: 
	host: ""
	port: 
	auth:
		username: ""
		password: ""
`;

const smautoEntityExample = `Entity:
	name: 
	type: 
	topic: ""
	broker: 
	attributes:
		- 
`;

const smautoAutomationExample = `Automation:
	name: 
	condition:
		...
	enabled: true
	continuous: false
	actions:
		- 
`;

export const getSmautoInsertableText = (entity) => {
	switch (entity) {
		case "MQTT": {
			return smautoMQTTExample;
		}

		case "AMQP": {
			return smautoAMQPExample;
		}

		case "Redis": {
			return smautoRedisExample;
		}

		case "Entity": {
			return smautoEntityExample;
		}

		case "Automation": {
			return smautoAutomationExample;
		}

		case "Example": {
			return smautoExample;
		}

		default: {
			return "";
		}
	}
};

export const getDemolInsertableText = (entity) => {
	switch (entity) {
		case "Example": {
			return demolExample;
		}

		default: {
			return "";
		}
	}
};

export const getInsertableText = (dsl, entity) => {
	switch (dsl) {
		case "smauto": {
			return getSmautoInsertableText(entity);
		}

		case "demol": {
			return getDemolInsertableText(entity);
		}

		default: {
			return "";
		}
	}
};

export const getShortDescription = (dsl) => {
	switch (dsl) {
		case "smauto": {
			return smautoShortDescription;
		}

		case "demol": {
			return demolShortDescription;
		}

		default: {
			return "";
		}
	}
};

export const getDslSubtypes = (dsl) => {
	switch (dsl) {
		case "smauto": {
			return [];
		}

		case "demol": {
			return ["device", "peripheral"];
		}

		default: {
			return "";
		}
	}
};

export const getDocumentation = async (dsl) => {
	switch (dsl) {
		case "smauto": {
			// Fetch the README.md file from the repository
			const response = await fetch('https://raw.githubusercontent.com/robotics-4-all/smauto/main/README.md');
			if (!response.ok) {
				throw new Error('Failed to fetch README.md');
			}

			// Replace the relative paths to the assets with the absolute paths
			const content = await response.text();
			const modifiedContent = content.replaceAll("assets/", "https://raw.githubusercontent.com/robotics-4-all/smauto/main/assets/");

			return modifiedContent;
		}

		case "demol": {
			// Fetch the README.md file from the repository
			const response = await fetch('https://raw.githubusercontent.com/robotics-4-all/demol/main/README.md');
			if (!response.ok) {
				throw new Error('Failed to fetch README.md');
			}

			// Replace the relative paths to the assets with the absolute paths
			const content = await response.text();
			const modifiedContent = content.replaceAll("assets/", "https://raw.githubusercontent.com/robotics-4-all/demol/main/assets/");

			return modifiedContent;
		}

		default: {
			return "";
		}
	}
};

export const getInsertableEntities = (dsl) => {
	switch (dsl) {
		case "smauto": {
			return ["MQTT", "AMQP", "Redis", "Entity", "Automation"];
		}

		case "demol": {
			return [];
		}

		default: {
			return [];
		}
	}
};

// export const getReservedKeywords = (dsl) => {
// 	switch (dsl) {
// 		case "smauto": {
// 			return {
// 				entities: ["MQTT", "AMQP", "Redis", "Entity", "Automation"],
// 				highlights: ["MQTT", "AMQP", "Redis", "Entity", "Automation"],
// 			};
// 		}

// 		default: {
// 			return [];
// 		}
// 	}
// };

export const countLines = (mainString) => {
	// Use match() to find all occurrences of the substring
	const matches = mainString.match(/\n/g);

	// Return the count of occurrences
	return matches ? matches.length + 1 : 0;
};

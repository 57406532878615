import {
	envDevices, actors, devicesConfiguration,
} from './yaml-templates';

const percToPixel = (value, map) => ((value / 100) * map);

const percToMeters = (value, map, resolution) => (((value / 100) * map) * resolution);

const dimsToPixels = (dimensions, width, height) => {
	const left = percToPixel(dimensions.x, width);
	const top = percToPixel(dimensions.y, height);
	const right = left + percToPixel(dimensions.w, width);
	const bottom = top - percToPixel(dimensions.h, height);

	return { left, right, top, bottom };
};

const findPlaces = (x, y, places, width, height) => {
	let place = 'world';

	for (const pKey of Object.keys(places)) {
		const { left, right, top, bottom } = dimsToPixels(places[pKey].dimensions, width, height);
		if (x >= left && x <= right && y <= top && y >= bottom) {
			place = places[pKey].name;
		}
	}

	return place;
};

const missionToYaml = (width, height, resolution, items, useAutoWalls, autoWalls, places, temperature, humidity, luminosity) => {
	const mission = {};
	let nextPanTiltId = 1;

	// Simulation data
	mission.simulation = {};
	mission.simulation.name = 'streamsim';

	// Map data
	mission.map = {};
	mission.map.width = width * resolution;
	mission.map.height = height * resolution;
	mission.map.resolution = resolution;
	mission.map.obstacles = {};
	mission.map.obstacles.lines = [];
	if ('general' in items && 'wall' in items.general) {
		for (const w of Object.keys(items.general.wall)) {
			mission.map.obstacles.lines.push({
				x1: percToMeters(items.general.wall[w].x1, width, resolution),
				y1: percToMeters(items.general.wall[w].y1, height, resolution),
				x2: percToMeters(items.general.wall[w].x2, width, resolution),
				y2: percToMeters(items.general.wall[w].y2, height, resolution),
			});
		}
	}

	if (useAutoWalls && autoWalls) {
		for (const w of autoWalls) {
			mission.map.obstacles.lines.push({
				x1: percToMeters(w.x1, width, resolution),
				y1: percToMeters(w.y1, height, resolution),
				x2: percToMeters(w.x2, width, resolution),
				y2: percToMeters(w.y2, height, resolution),
			});
		}
	}

	// World data
	mission.world = {};
	mission.world.name = 'World';
	mission.world.places = [];
	for (const pl of Object.keys(places)) {
		mission.world.places.push(places[pl].name);
	}

	mission.world.properties = {};
	mission.world.properties.temperature = temperature;
	mission.world.properties.humidity = humidity;
	mission.world.properties.luminosity = luminosity;

	// Robots data
	mission.robots = [];
	if ('general' in items && 'robot' in items.general) {
		for (const r of Object.keys(items.general.robot)) {
			const robot = {};
			robot.name = items.general.robot[r].itemName;
			robot.mode = 'simulation';
			robot.speak_mode = 'espeak';
			robot.wait_for = [];
			robot.amqp_inform = true;
			robot.step_by_step_execution = false;
			robot.starting_pose = {
				x: percToMeters(items.general.robot[r].x, width, resolution),
				y: percToMeters(items.general.robot[r].y, height, resolution),
				theta: items.general.robot[r].theta || 0,
			};
			robot.devices = {};
			robot.devices.skid_steer = devicesConfiguration.skid_steer(items.general.robot[r].itemName);
			for (const dev of Object.keys(items.general.robot[r].devices)) {
				if (items.general.robot[r].devices[dev].value) {
					robot.devices[dev] = devicesConfiguration[dev](items.general.robot[r].itemName);
					if (dev === 'camera') {
						robot.devices.pan_tilt = devicesConfiguration.pan_tilt(items.general.robot[r].itemName);
					}
				}
			}

			mission.robots.push(robot);
		}
	}

	// Env devices data
	mission.env_devices = {};
	mission.env_devices.pan_tilt = [];
	for (const envDevice of Object.keys(envDevices)) {
		mission.env_devices[envDevice] = [];
		if (envDevices[envDevice].category in items && envDevices[envDevice].name in items[envDevices[envDevice].category]) {
			for (const itemKey of Object.keys(items[envDevices[envDevice].category][envDevices[envDevice].name])) {
				const standardProps = {};
				standardProps.name = items[envDevices[envDevice].category][envDevices[envDevice].name][itemKey].itemName;
				standardProps.mode = items[envDevices[envDevice].category][envDevices[envDevice].name][itemKey].mode || 'mock';
				standardProps.place = findPlaces(
					percToPixel(items[envDevices[envDevice].category][envDevices[envDevice].name][itemKey].x, width),
					percToPixel(items[envDevices[envDevice].category][envDevices[envDevice].name][itemKey].y, height),
					places,
					width,
					height,
				);
				standardProps.pose = {};
				standardProps.pose = envDevice === 'alarms_linear' ? {
					start: {
						x: percToMeters(items[envDevices[envDevice].category][envDevices[envDevice].name][itemKey].x1, width, resolution),
						y: percToMeters(items[envDevices[envDevice].category][envDevices[envDevice].name][itemKey].y1, height, resolution),
					},
					end: {
						x: percToMeters(items[envDevices[envDevice].category][envDevices[envDevice].name][itemKey].x2, width, resolution),
						y: percToMeters(items[envDevices[envDevice].category][envDevices[envDevice].name][itemKey].y2, height, resolution),
					},
				} : {
					x: percToMeters(items[envDevices[envDevice].category][envDevices[envDevice].name][itemKey].x, width, resolution),
					y: percToMeters(items[envDevices[envDevice].category][envDevices[envDevice].name][itemKey].y, height, resolution),
					theta: items[envDevices[envDevice].category][envDevices[envDevice].name][itemKey].theta || 0,
				};

				const extraProps = {};
				for (const p of Object.keys(envDevices[envDevice].props)) {
					extraProps[p] = items[envDevices[envDevice].category][envDevices[envDevice].name][itemKey][envDevices[envDevice].props[p]];
				}

				if ((envDevice === 'camera_sensors' || envDevice === 'distance_sensors') && items[envDevices[envDevice].category][envDevices[envDevice].name][itemKey].onPanTilt === 1) {
					const newPanTiltName = `pan_tilt_${nextPanTiltId}`;
					nextPanTiltId += 1;

					extraProps.host = newPanTiltName;

					mission.env_devices.pan_tilt.push({
						pose: {
							x: percToMeters(items[envDevices[envDevice].category][envDevices[envDevice].name][itemKey].x, width, resolution),
							y: percToMeters(items[envDevices[envDevice].category][envDevices[envDevice].name][itemKey].y, height, resolution),
							theta: items[envDevices[envDevice].category][envDevices[envDevice].name][itemKey].theta || 0,
						},
						name: newPanTiltName,
						mode: items[envDevices[envDevice].category][envDevices[envDevice].name][itemKey].panTiltMode || 'mock',
						place: standardProps.place,
						limits: items[envDevices[envDevice].category][envDevices[envDevice].name][itemKey].panTiltLimits,
						operation: items[envDevices[envDevice].category][envDevices[envDevice].name][itemKey].panTiltSelectedOperation,
						operation_parameters: items[envDevices[envDevice].category][envDevices[envDevice].name][itemKey].panTiltOperationParameters,
					});
					standardProps.pose = {
						x: 0,
						y: 0,
						theta: 0,
					};
				}

				mission.env_devices[envDevice].push(Object.assign(standardProps, extraProps));
			}
		}
	}

	// Actors data
	mission.actors = {};
	for (const actor of Object.keys(actors)) {
		mission.actors[actor] = [];
		if ('general' in items && actors[actor].name in items.general) {
			for (const itemKey of Object.keys(items.general[actors[actor].name])) {
				const standardProps = {};
				standardProps.id = items.general[actors[actor].name][itemKey].id;
				standardProps.x = percToMeters(items.general[actors[actor].name][itemKey].x, width, resolution);
				standardProps.y = percToMeters(items.general[actors[actor].name][itemKey].y, height, resolution);

				const extraProps = {};
				for (const p of Object.keys(actors[actor].props)) {
					extraProps[p] = items.general[actors[actor].name][itemKey][actors[actor].props[p]];
				}

				mission.actors[actor].push(Object.assign(standardProps, extraProps));
			}
		}
	}

	return mission;
};

export default missionToYaml;

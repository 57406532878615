import bridge from '../../../assets/images/background/city/bridge.png';
import building1 from '../../../assets/images/background/city/building1.png';
import building2 from '../../../assets/images/background/city/building2.png';
import farm from '../../../assets/images/background/city/farm.png';
import footballField from '../../../assets/images/background/city/football_field.png';
import home from '../../../assets/images/background/city/home.png';
import lake from '../../../assets/images/background/city/lake.png';
import lakeCenter from '../../../assets/images/background/city/lake_center.png';
import lakeCorner from '../../../assets/images/background/city/lake_corner.png';
import lakeStraight from '../../../assets/images/background/city/lake_straight.png';
import mall from '../../../assets/images/background/city/mall.png';
import park from '../../../assets/images/background/city/park.png';
import parking from '../../../assets/images/background/city/parking.png';
import spaceLab from '../../../assets/images/background/city/space_lab.png';
import start from '../../../assets/images/background/city/start.png';
import street from '../../../assets/images/background/city/street.png';
import streetCorner from '../../../assets/images/background/city/street_corner.png';
import streetEmpty from '../../../assets/images/background/city/street_empty.png';
import streetHall from '../../../assets/images/background/city/street_hall.png';
import streetRound from '../../../assets/images/background/city/street_round.png';
import streetSquare from '../../../assets/images/background/city/street_square.png';
import streetStraight from '../../../assets/images/background/city/street_straight.png';
import streetT from '../../../assets/images/background/city/street_t.png';
import streetBuilding from '../../../assets/images/background/city/street_building.png';
import streetBuildings from '../../../assets/images/background/city/street_buildings.png';
import streetUp from '../../../assets/images/background/city/street_up.png';
import streetUpLeft from '../../../assets/images/background/city/street_up_left.png';
import trees from '../../../assets/images/background/city/trees.png';

const city = {
	name: 'City',
	components: {
		bridge: {
			id: 'bridge',
			image: bridge,
			text: 'Bridge',
			walls: [],
			waters: [{ x: 50, y: 55, range: 15 }],
		},
		building1: {
			id: 'building1',
			image: building1,
			text: 'Building 1',
			walls: [
				{ x1: 0, y1: 0, x2: 0, y2: 82 },
				{ x1: 0, y1: 82, x2: 36, y2: 82 },
				{ x1: 36, y1: 82, x2: 36, y2: 100 },
				{ x1: 64, y1: 100, x2: 64, y2: 82 },
				{ x1: 64, y1: 82, x2: 100, y2: 82 },
				{ x1: 100, y1: 82, x2: 100, y2: 0 },
				{ x1: 100, y1: 0, x2: 0, y2: 0 },
			],
			waters: [],
		},
		building2: {
			id: 'building2',
			image: building2,
			text: 'Building 2',
			walls: [
				{ x1: 0, y1: 0, x2: 0, y2: 82 },
				{ x1: 0, y1: 82, x2: 36, y2: 82 },
				{ x1: 36, y1: 82, x2: 36, y2: 100 },
				{ x1: 64, y1: 100, x2: 64, y2: 82 },
				{ x1: 64, y1: 82, x2: 100, y2: 82 },
				{ x1: 100, y1: 82, x2: 100, y2: 0 },
				{ x1: 100, y1: 0, x2: 0, y2: 0 },
			],
			waters: [],
		},
		farm: {
			id: 'farm',
			image: farm,
			text: 'Farm',
			walls: [
				{ x1: 0, y1: 0, x2: 0, y2: 82 },
				{ x1: 0, y1: 82, x2: 36, y2: 82 },
				{ x1: 36, y1: 82, x2: 36, y2: 100 },
				{ x1: 64, y1: 100, x2: 64, y2: 82 },
				{ x1: 64, y1: 82, x2: 100, y2: 82 },
				{ x1: 100, y1: 82, x2: 100, y2: 0 },
				{ x1: 100, y1: 0, x2: 0, y2: 0 },
			],
			waters: [],
		},
		football_field: {
			id: 'football_field',
			image: footballField,
			text: 'Football Field',
			walls: [
				{ x1: 36, y1: 0, x2: 36, y2: 14 },
				{ x1: 36, y1: 14, x2: 21, y2: 14 },
				{ x1: 21, y1: 14, x2: 21, y2: 36 },
				{ x1: 21, y1: 36, x2: 0, y2: 36 },
				{ x1: 0, y1: 64, x2: 21, y2: 64 },
				{ x1: 21, y1: 64, x2: 21, y2: 87 },
				{ x1: 21, y1: 87, x2: 86, y2: 87 },
				{ x1: 86, y1: 87, x2: 86, y2: 14 },
				{ x1: 86, y1: 14, x2: 64, y2: 14 },
				{ x1: 64, y1: 14, x2: 64, y2: 0 },
			],
			waters: [],
		},
		home: {
			id: 'home',
			image: home,
			text: 'Home',
			walls: [
				{ x1: 0, y1: 0, x2: 0, y2: 82 },
				{ x1: 0, y1: 82, x2: 36, y2: 82 },
				{ x1: 36, y1: 82, x2: 36, y2: 100 },
				{ x1: 64, y1: 100, x2: 64, y2: 82 },
				{ x1: 64, y1: 82, x2: 100, y2: 82 },
				{ x1: 100, y1: 82, x2: 100, y2: 0 },
				{ x1: 100, y1: 0, x2: 0, y2: 0 },
			],
			waters: [{ x: 50, y: 18, range: 5 }],
		},
		lake: {
			id: 'lake',
			image: lake,
			text: 'Lake',
			walls: [
				{ x1: 31, y1: 20, x2: 20, y2: 40 },
				{ x1: 20, y1: 40, x2: 17, y2: 60 },
				{ x1: 17, y1: 60, x2: 31, y2: 75 },
				{ x1: 31, y1: 75, x2: 60, y2: 78 },
				{ x1: 60, y1: 78, x2: 76, y2: 58 },
				{ x1: 76, y1: 58, x2: 83, y2: 40 },
				{ x1: 83, y1: 40, x2: 70, y2: 23 },
				{ x1: 70, y1: 23, x2: 52, y2: 31 },
				{ x1: 52, y1: 31, x2: 31, y2: 20 },
			],
			waters: [{ x: 50, y: 50, range: 22 }],
		},
		lakeCenter: {
			id: 'lakeCenter',
			image: lakeCenter,
			text: 'Lake Center',
			walls: [],
			waters: [{ x: 50, y: 50, range: 50 }],
		},
		lakeCorner: {
			id: 'lakeCorner',
			image: lakeCorner,
			text: 'Lake Corner',
			walls: [],
			waters: [{ x: 60, y: 40, range: 44 }],
		},
		lakeStraight: {
			id: 'lakeStraight',
			image: lakeStraight,
			text: 'Lake Straight',
			walls: [],
			waters: [{ x: 50, y: 40, range: 40 }],
		},
		mall: {
			id: 'mall',
			image: mall,
			text: 'Mall',
			walls: [
				{ x1: 0, y1: 36, x2: 36, y2: 36 },
				{ x1: 36, y1: 36, x2: 36, y2: 0 },
				{ x1: 64, y1: 0, x2: 64, y2: 36 },
				{ x1: 64, y1: 36, x2: 100, y2: 36 },
			],
			waters: [],
		},
		park: {
			id: 'park',
			image: park,
			text: 'Park',
			walls: [
				{ x1: 0, y1: 36, x2: 20, y2: 40 },
				{ x1: 20, y1: 40, x2: 80, y2: 40 },
				{ x1: 80, y1: 40, x2: 100, y2: 36 },
				{ x1: 5, y1: 78, x2: 5, y2: 96 },
				{ x1: 5, y1: 96, x2: 95, y2: 96 },
				{ x1: 95, y1: 96, x2: 96, y2: 78 },
			],
			waters: [{ x: 50, y: 18, range: 5 }],
		},
		parking: {
			id: 'parking',
			image: parking,
			text: 'Parking',
			walls: [
				{ x1: 0, y1: 0, x2: 0, y2: 82 },
				{ x1: 0, y1: 82, x2: 36, y2: 82 },
				{ x1: 36, y1: 82, x2: 36, y2: 100 },
				{ x1: 64, y1: 100, x2: 64, y2: 82 },
				{ x1: 64, y1: 82, x2: 100, y2: 82 },
				{ x1: 100, y1: 82, x2: 100, y2: 0 },
				{ x1: 100, y1: 0, x2: 0, y2: 0 },
			],
			waters: [],
		},
		space_lab: {
			id: 'space_lab',
			image: spaceLab,
			text: 'Space Lab',
			walls: [
				{ x1: 0, y1: 0, x2: 0, y2: 82 },
				{ x1: 0, y1: 82, x2: 36, y2: 82 },
				{ x1: 36, y1: 82, x2: 36, y2: 100 },
				{ x1: 64, y1: 100, x2: 64, y2: 82 },
				{ x1: 64, y1: 82, x2: 100, y2: 82 },
				{ x1: 100, y1: 82, x2: 100, y2: 0 },
				{ x1: 100, y1: 0, x2: 0, y2: 0 },
			],
			waters: [],
		},
		start: {
			id: 'start',
			image: start,
			text: 'Start',
			walls: [
				{ x1: 0, y1: 0, x2: 0, y2: 100 },
				{ x1: 0, y1: 100, x2: 64, y2: 100 },
				{ x1: 64, y1: 100, x2: 64, y2: 64 },
				{ x1: 64, y1: 64, x2: 100, y2: 64 },
				{ x1: 100, y1: 36, x2: 64, y2: 36 },
				{ x1: 64, y1: 36, x2: 64, y2: 0 },
				{ x1: 64, y1: 0, x2: 0, y2: 0 },
			],
			waters: [],
		},
		street: {
			id: 'street',
			image: street,
			text: 'Street',
			walls: [
				{ x1: 0, y1: 36, x2: 36, y2: 36 },
				{ x1: 36, y1: 36, x2: 36, y2: 0 },
				{ x1: 0, y1: 64, x2: 36, y2: 64 },
				{ x1: 36, y1: 64, x2: 36, y2: 100 },
				{ x1: 64, y1: 100, x2: 64, y2: 64 },
				{ x1: 64, y1: 64, x2: 100, y2: 64 },
				{ x1: 100, y1: 36, x2: 64, y2: 36 },
				{ x1: 64, y1: 36, x2: 64, y2: 0 },
			],
			waters: [],
		},
		street_corner: {
			id: 'street_corner',
			image: streetCorner,
			text: 'Street Corner',
			walls: [
				{ x1: 36, y1: 0, x2: 36, y2: 36 },
				{ x1: 36, y1: 36, x2: 64, y2: 64 },
				{ x1: 64, y1: 64, x2: 100, y2: 64 },
				{ x1: 64, y1: 0, x2: 64, y2: 24 },
				{ x1: 64, y1: 24, x2: 76, y2: 36 },
				{ x1: 76, y1: 36, x2: 100, y2: 36 },
			],
			waters: [],
		},
		streetEmpty: {
			id: 'streetEmpty',
			image: streetEmpty,
			text: 'Street Empty',
			walls: [],
			waters: [],
		},
		streetHall: {
			id: 'streetHall',
			image: streetHall,
			text: 'Street Hall',
			walls: [
				{ x1: 36, y1: 0, x2: 36, y2: 36 },
				{ x1: 36, y1: 36, x2: 0, y2: 36 },
				{ x1: 0, y1: 64, x2: 36, y2: 64 },
				{ x1: 36, y1: 64, x2: 36, y2: 100 },
			],
			waters: [],
		},
		street_round: {
			id: 'street_round',
			image: streetRound,
			text: 'Street Round',
			walls: [
				{ x1: 25, y1: 12, x2: 36, y2: 7 },
				{ x1: 36, y1: 7, x2: 36, y2: 0 },
				{ x1: 25, y1: 12, x2: 13, y2: 24 },
				{ x1: 13, y1: 24, x2: 7, y2: 36 },
				{ x1: 7, y1: 36, x2: 0, y2: 36 },
				{ x1: 0, y1: 64, x2: 7, y2: 64 },
				{ x1: 7, y1: 64, x2: 13, y2: 76 },
				{ x1: 13, y1: 76, x2: 25, y2: 88 },
				{ x1: 25, y1: 88, x2: 36, y2: 93 },
				{ x1: 36, y1: 93, x2: 36, y2: 100 },
				{ x1: 64, y1: 100, x2: 64, y2: 93 },
				{ x1: 64, y1: 93, x2: 75, y2: 88 },
				{ x1: 75, y1: 88, x2: 87, y2: 76 },
				{ x1: 87, y1: 76, x2: 93, y2: 64 },
				{ x1: 93, y1: 64, x2: 100, y2: 64 },
				{ x1: 100, y1: 36, x2: 93, y2: 36 },
				{ x1: 93, y1: 36, x2: 87, y2: 24 },
				{ x1: 87, y1: 24, x2: 75, y2: 12 },
				{ x1: 75, y1: 12, x2: 64, y2: 7 },
				{ x1: 64, y1: 7, x2: 64, y2: 0 },
				{ x1: 50, y1: 30, x2: 40, y2: 32 },
				{ x1: 40, y1: 32, x2: 32, y2: 40 },
				{ x1: 32, y1: 40, x2: 30, y2: 50 },
				{ x1: 30, y1: 50, x2: 32, y2: 59 },
				{ x1: 32, y1: 59, x2: 40, y2: 67 },
				{ x1: 40, y1: 67, x2: 50, y2: 70 },
				{ x1: 50, y1: 70, x2: 59, y2: 67 },
				{ x1: 59, y1: 67, x2: 67, y2: 59 },
				{ x1: 67, y1: 59, x2: 70, y2: 50 },
				{ x1: 70, y1: 50, x2: 67, y2: 40 },
				{ x1: 67, y1: 40, x2: 59, y2: 32 },
				{ x1: 59, y1: 32, x2: 50, y2: 30 },
			],
			waters: [],
		},
		streetSquare: {
			id: 'streetSquare',
			image: streetSquare,
			text: 'Street Square',
			walls: [
				{ x1: 50, y1: 80, x2: 71, y2: 71 },
				{ x1: 71, y1: 71, x2: 80, y2: 50 },
				{ x1: 80, y1: 50, x2: 71, y2: 29 },
				{ x1: 71, y1: 29, x2: 50, y2: 20 },
				{ x1: 50, y1: 20, x2: 29, y2: 29 },
				{ x1: 29, y1: 29, x2: 20, y2: 50 },
				{ x1: 20, y1: 50, x2: 29, y2: 71 },
				{ x1: 29, y1: 71, x2: 50, y2: 80 },
			],
			waters: [],
		},
		street_straight: {
			id: 'street_straight',
			image: streetStraight,
			text: 'Street Straight',
			walls: [
				{ x1: 0, y1: 36, x2: 100, y2: 36 },
				{ x1: 0, y1: 64, x2: 100, y2: 64 },
			],
			waters: [],
		},
		street_t: {
			id: 'street_t',
			image: streetT,
			text: 'Street T',
			walls: [
				{ x1: 0, y1: 36, x2: 36, y2: 36 },
				{ x1: 36, y1: 36, x2: 36, y2: 0 },
				{ x1: 0, y1: 64, x2: 100, y2: 64 },
				{ x1: 100, y1: 36, x2: 64, y2: 36 },
				{ x1: 64, y1: 36, x2: 64, y2: 0 },
			],
			waters: [],
		},
		streetBuilding: {
			id: 'streetBuilding',
			image: streetBuilding,
			text: 'Street with Building',
			walls: [
				{ x1: 7, y1: 94, x2: 89, y2: 94 },
				{ x1: 89, y1: 94, x2: 89, y2: 57 },
				{ x1: 89, y1: 57, x2: 7, y2: 57 },
				{ x1: 7, y1: 57, x2: 7, y2: 94 },
			],
			waters: [],
		},
		street_buildings: {
			id: 'street_buildings',
			image: streetBuildings,
			text: 'Street with Buildings',
			walls: [
				{ x1: 0, y1: 36, x2: 36, y2: 36 },
				{ x1: 36, y1: 36, x2: 36, y2: 0 },
				{ x1: 0, y1: 64, x2: 36, y2: 64 },
				{ x1: 36, y1: 64, x2: 36, y2: 100 },
				{ x1: 64, y1: 100, x2: 64, y2: 64 },
				{ x1: 64, y1: 64, x2: 100, y2: 64 },
				{ x1: 100, y1: 36, x2: 64, y2: 36 },
				{ x1: 64, y1: 36, x2: 64, y2: 0 },
			],
			waters: [],
		},
		streetUp: {
			id: 'streetUp',
			image: streetUp,
			text: 'Street Up',
			walls: [{ x1: 0, y1: 64, x2: 100, y2: 64 }],
			waters: [],
		},
		streetUpLeft: {
			id: 'streetUpLeft',
			image: streetUpLeft,
			text: 'Street Up Left',
			walls: [
				{ x1: 36, y1: 0, x2: 36, y2: 64 },
				{ x1: 36, y1: 64, x2: 100, y2: 64 },
			],
			waters: [],
		},
		trees: {
			id: 'trees',
			image: trees,
			text: 'Trees',
			walls: [],
			waters: [],
		},
	},
};

export default city;


/* eslint-disable max-len */

import {
	EditableText, Popover, Tooltip, Menu, NumericInput, InputGroup,
} from '@blueprintjs/core';
import { faTrash, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';
import { ToasterBottom } from '../../../lib_socioty/toaster';
import sound from '../../../../assets/images/missionItems/actors/sound.png';
import { GreenBorderButton } from '../../../lib_socioty/buttons';

import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const ItemDiv = styled.div`
    width: ${(props) => `${props.elementDimensions}px`};
    height: ${(props) => `${props.elementDimensions}px`};
    border-radius: ${(props) => `${props.elementDimensions}px`};
    position: absolute;
    left: ${(props) => `calc(${props.x}% - ${(props.elementDimensions / 2)}px)`};
    bottom: ${(props) => `calc(${props.y}% - ${(props.elementDimensions / 2)}px)`};
    cursor: ${(props) => ((props.dragging) ? 'move' : 'pointer')};
    display: flex;
	transform: ${(props) => (props.dragging ? '' : 'none!important')};
`;

const BasicPopover = ({ className, elementDimensions, popoverOpen, closePopover, changeName, checkName, itemName, languages, lang, changeLang, changeRange, range, changeSpeech, speech, emotions, emotion, changeEmotion, deleteItem }) => (
	<Popover usePortal popoverClassName={`${className} item-popover`} elementDimensions={elementDimensions} isOpen={popoverOpen}>
		<div style={{
			width: '300px', padding: '10px', color: 'white', background: 'black', boxShadow: '4px 4px 10px 1px #333333',
		}}
		>
			{/* Name */}
			<div style={{
				width: '100%', maxWidth: '100%', paddingLeft: '1px', paddingRight: '1px', display: 'flex', justifyContent: 'space-around',
			}}
			>
				<TextField
					value={itemName}
					label="Name"
					variant="filled"
					sx={{
						backgroundColor: '#aaa',
						mx: 1,
						width: '100%',
					}}
					size="small"
					color="success"
					onChange={(event) => changeName(event.target.value)}
				/>
			</div>

			{/* Range */}
			<div
				style={{
					width: '100%', height: '20px', marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center',
				}}
			>
				{"Range (m)"}
			</div>
			<div style={{
				width: '100%', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center',
			}}
			>
				<NumericInput clampValueOnBlur className="parameter-input" min={0} minorStepSize={0.1} placeholder="Range" stepSize={1} defaultValue={Number(range.toFixed(2))} onValueChange={changeRange} />
			</div>

			<div
				style={{
					width: '100%', height: '40px', display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop: '10px',
				}}
			>
				<FormControl error sx={{ m: 1, width: "100%" }}>
					<Select
						value={lang}
						label="Language"
						sx={{
							backgroundColor: 'gray',
						}}
						size="small"
					>
						{['EL', 'EN'].map((m) => (
							<MenuItem key={Math.random()} value={m} onClick={() => changeLang(m)}>
								{`Language: ${m}`}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>

			<div
				style={{
					width: '100%', height: '20px', marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center',
				}}
			>
				{"Speech"}
			</div>
			<div style={{
				width: '100%', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center',
			}}
			>
				<InputGroup fill round placeholder="Speech" value={speech} onChange={changeSpeech} />
			</div>

			{/* Emotion */}
			<div
				style={{
					width: '100%', height: '40px', display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop: "10px",
				}}
			>
				<FormControl error sx={{ m: 1, width: "100%" }}>
					<Select
						value={emotions[emotion]}
						label="Emotion"
						sx={{
							backgroundColor: 'gray',
						}}
						size="small"
					>
						{Object.keys(emotions).map((m) => (
							<MenuItem key={emotions[m]} value={emotions[m]} onClick={() => changeEmotion(m)}>
								{`Emotion: ${emotions[m]}`}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>

			<div style={{
				width: '100%', height: '20px', display: 'flex', justifyContent: 'space-around', marginTop: '10px',
			}}
			>
				<FontAwesomeIcon icon={faTrash} style={{ fontSize: '20px', cursor: 'pointer' }} onClick={deleteItem} />
				<FontAwesomeIcon icon={faXmarkCircle} style={{ fontSize: '20px', cursor: 'pointer' }} onClick={closePopover} />
			</div>
		</div>
	</Popover>
);

const CustomPopover = styled(BasicPopover)`
    transform: ${(props) => `translate(${-(props.elementDimensions / 2)}px, 0px)`};
    border-radius: 10px;
`;

class Sound extends React.Component {
	constructor(props) {
		super(props);

		const initialState = props.state;
		this.cat = initialState.cat;
		this.itemCat = initialState.itemCat;
		this.updateItem = props.updateItem;
		this.deleteItem = props.deleteItem;
		this.checkItemNameExists = props.checkItemNameExists;

		this.state = {
			id: initialState.id,
			itemName: initialState.itemName,
			x: initialState.x,
			y: initialState.y,
			lang: initialState.lang,
			range: initialState.range,
			speech: initialState.speech,
			emotion: initialState.emotion,
			elementDimensions: props.elementDimensions,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
			dragging: false,
			popoverOpen: false,
			resolution: props.resolution,
			metersPopupOpen: false,
			xMeters: 0,
			yMeters: 0,
			displayRange: false,
		};
		this.interval = null;
		this.initialName = initialState.itemName;
		this.languages = {
			EL: 'Greek',
			EN: 'English',
		};
		this.emotions = {
			happy: 'Happy',
			sad: 'Sad',
			crying: 'Crying',
			neutral: 'Neutral',
			delighted: 'Delighted',
			angry: 'Angry',
			disgusted: 'Disgusted',
			afraid: 'Afraid',
		};

		this.onStart = this.onStart.bind(this);
		this.onDrag = this.onDrag.bind(this);
		this.onStop = this.onStop.bind(this);
		this.onClick = this.onClick.bind(this);
		this.delete = this.delete.bind(this);
		this.sendUpdate = this.sendUpdate.bind(this);
		this.changeName = this.changeName.bind(this);
		this.checkName = this.checkName.bind(this);
		this.changeLang = this.changeLang.bind(this);
		this.changeRange = this.changeRange.bind(this);
		this.changeSpeech = this.changeSpeech.bind(this);
		this.changeEmotion = this.changeEmotion.bind(this);
	}

	componentDidMount() {
		const { itemName, x, y, lang, range, speech, emotion } = this.state;
		this.sendUpdate(itemName, x, y, lang, range, speech, emotion);
	}

	static getDerivedStateFromProps(props) {
		const initialState = props.state;
		return {
			id: initialState.id,
			itemName: initialState.itemName,
			x: initialState.x,
			y: initialState.y,
			lang: initialState.lang,
			range: initialState.range,
			speech: initialState.speech,
			emotion: initialState.emotion,
			elementDimensions: props.elementDimensions,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
			resolution: props.resolution,
		};
	}

	onStart(event) {
		event.preventDefault();
		const { x, y, mapWidth, mapHeight, resolution } = this.state;
		const xMeters = ((x / 100) * mapWidth) * resolution;
		const yMeters = ((y / 100) * mapHeight) * resolution;
		this.setState({ metersPopupOpen: true, xMeters, yMeters, displayRange: false });
	}

	onDrag(event, data) {
		event.preventDefault();
		const { x, y, mapWidth, mapHeight, resolution } = this.state;
		const xMeters = (((x / 100) * mapWidth) + data.x) * resolution;
		const yMeters = (((y / 100) * mapHeight) - data.y) * resolution;
		this.setState({ dragging: true, xMeters, yMeters, displayRange: false });
	}

	onStop(event, data) {
		event.preventDefault();
		const { dragging } = this.state;
		this.setState({ dragging: false, metersPopupOpen: false });
		if (!dragging) {
			this.onClick();
		}

		const { itemName, x, y, lang, range, speech, emotion, mapWidth, mapHeight } = this.state;
		let newX = ((((x / 100) * mapWidth) + data.x) / mapWidth) * 100;
		if (newX > 100) {
			newX = 100;
		}

		if (newX < 0) {
			newX = 0;
		}

		let newY = ((((y / 100) * mapHeight) - data.y) / mapHeight) * 100;
		if (newY > 100) {
			newY = 100;
		}

		if (newY < 0) {
			newY = 0;
		}

		this.sendUpdate(itemName, newX, newY, lang, range, speech, emotion);
	}

	onClick() {
		this.setState({ popoverOpen: true });
	}

	delete() {
		const { id } = this.state;
		this.deleteItem(this.cat, this.itemCat, id);
	}

	sendUpdate(itemName, x, y, lang, range, speech, emotion) {
		const { id } = this.state;
		this.updateItem(this.cat, this.itemCat, id, {
			id, itemName, x, y, lang, range, speech, emotion, cat: this.cat, itemCat: this.itemCat,
		});
	}

	changeName(value) {
		const { x, y, lang, range, speech, emotion } = this.state;
		this.sendUpdate(value.toLowerCase().split(' ').join('_'), x, y, lang, range, speech, emotion);
	}

	checkName(value) {
		const { id } = this.state;
		const { matchesPattern, found } = this.checkItemNameExists(value, this.initialName, this.cat, this.itemCat, id);
		if (matchesPattern) {
			ToasterBottom.show({
				intent: 'danger',
				message: 'Name cannot follow the template category_number',
			});
			const { x, y, lang, range, speech, emotion } = this.state;
			this.sendUpdate(this.initialName, x, y, lang, range, speech, emotion);
		} else if (found) {
			ToasterBottom.show({
				intent: 'danger',
				message: 'Name already exists',
			});
			const { x, y, lang, range, speech, emotion } = this.state;
			this.sendUpdate(this.initialName, x, y, lang, range, speech, emotion);
		}
	}

	changeLang(lang) {
		const { itemName, x, y, range, speech, emotion } = this.state;
		this.sendUpdate(itemName, x, y, lang, range, speech, emotion);
	}

	changeRange(value) {
		const { itemName, x, y, lang, speech, emotion } = this.state;
		this.sendUpdate(itemName, x, y, lang, value, speech, emotion);
	}

	changeSpeech(event) {
		const { itemName, x, y, lang, range, emotion } = this.state;
		this.sendUpdate(itemName, x, y, lang, range, event.target.value, emotion);
	}

	changeEmotion(emotion) {
		const { itemName, x, y, lang, range, speech } = this.state;
		this.sendUpdate(itemName, x, y, lang, range, speech, emotion);
	}

	render() {
		const { id, itemName, x, y, lang, range, resolution, speech, emotion, elementDimensions, dragging, popoverOpen, metersPopupOpen, xMeters, yMeters, displayRange } = this.state;

		return (
			<Draggable
				key={`sound_${id}`}
				allowAnyClick={false}
				grid={[10, 10]}
				position={{ x: 0, y: 0 }}
				handle=".itemhandler"
				onStart={this.onStart}
				onDrag={this.onDrag}
				onStop={this.onStop}
			>
				<ItemDiv id={`item_${id}`} x={x} y={y} elementDimensions={elementDimensions} dragging={dragging}>
					<Tooltip
						popoverClassName="item-info-tooltip"
						disabled={dragging || popoverOpen}
						content={(
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									textAlign: 'center',
									maxWidth: '200px',
									backgroundColor: "rgba(0, 0, 0, 0.8)",
									padding: '10px',
									borderRadius: '10px',
									color: 'white',
								}}
							>
								<div style={{ fontWeight: 'bold', fontSize: '16px' }}>
									{"Language"}
								</div>
								<div style={{ textAlign: 'center', height: 'fit-content', wordBreak: 'break-word' }}>
									{this.languages[lang]}
								</div>
								<div style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '10px' }}>
									{"Range"}
								</div>
								<div style={{ textAlign: 'center', height: 'fit-content', wordBreak: 'break-word' }}>
									{`${range}m`}
								</div>
								{speech.length > 0
									&& (
										<div style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '10px' }}>
											{"Speech"}
										</div>
									)}
								{speech.length > 0
									&& (
										<div style={{ textAlign: 'center', height: 'fit-content', wordBreak: 'break-word' }}>
											{speech}
										</div>
									)}
								<div style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '10px' }}>
									{"Emotion"}
								</div>
								<div style={{ textAlign: 'center', height: 'fit-content', wordBreak: 'break-word' }}>
									{emotion}
								</div>
							</div>
						)}
						interactionKind="hover"
						onOpening={() => this.setState({ displayRange: true })}
						onClosing={() => this.setState({ displayRange: false })}
					>
						<>
							<img
								key={Math.random()}
								id={`itemImg_${id}`}
								className="itemhandler"
								src={sound}
								alt=""
								draggable={false}
								style={{ maxWidth: '100%', maxHeight: '100%' }}
							/>
							{(popoverOpen || displayRange)
								&& (
									<div
										style={{
											width: `${((2 * range) / resolution)}px`,
											height: `${((2 * range) / resolution)}px`,
											borderRadius: `${((2 * range) / resolution)}px`,
											background: 'rgba(19, 78, 218, 0.4)',
											position: 'absolute',
											top: `${-(range / resolution) + elementDimensions / 2}px`,
											left: `${-(range / resolution) + elementDimensions / 2}px`,
											filter: 'blur(5px)',
											zIndex: -1,
											animation: 'areaAlarmAnimation 1s linear infinite',
										}}
									/>
								)}
						</>
					</Tooltip>
					<div
						style={{
							display: (metersPopupOpen) ? 'block' : 'none', width: 'max-content', paddingLeft: '5px', paddingRight: '5px', height: '20px', position: 'absolute', top: `-${(elementDimensions / 2) + 5}px`, left: `${(elementDimensions / 2) - (145 / 2)}px`, fontSize: '14px', textAlign: 'center', background: '#7a8585', borderRadius: '10px', color: 'white',
						}}
					>
						{`x: ${xMeters.toFixed(3)}m, y: ${yMeters.toFixed(3)}m`}
					</div>
					{popoverOpen && (
						<CustomPopover
							elementDimensions={elementDimensions}
							popoverOpen={popoverOpen}
							closePopover={() => { this.setState({ popoverOpen: false }); }}
							changeName={this.changeName}
							checkName={this.checkName}
							itemName={itemName}
							languages={this.languages}
							lang={lang}
							changeLang={this.changeLang}
							changeRange={this.changeRange}
							range={range}
							changeSpeech={this.changeSpeech}
							speech={speech}
							emotions={this.emotions}
							emotion={emotion}
							changeEmotion={this.changeEmotion}
							deleteItem={this.delete}
						/>
					)}
				</ItemDiv>
			</Draggable>
		);
	}
}

const createSound = (state, resolution, elementDimensions, mapWidth, mapHeight, updateItem, deleteItem, checkItemNameExists) => (
	<Sound key={state.id} state={state} resolution={resolution} elementDimensions={elementDimensions} mapWidth={mapWidth} mapHeight={mapHeight} updateItem={updateItem} deleteItem={deleteItem} checkItemNameExists={checkItemNameExists} />
);

export default createSound;

/* eslint-disable max-len */
import wallIcon from '../../../../assets/images/missionItems/general/wall.png';
import robotIcon from '../../../../assets/images/missionItems/general/robot.png';
import barcodeIcon from '../../../../assets/images/missionItems/actors/barcode.png';
import colorIcon from '../../../../assets/images/missionItems/actors/color.png';
import fireIcon from '../../../../assets/images/missionItems/actors/fire.png';
import humanIcon from '../../../../assets/images/missionItems/actors/human.png';
import qrIcon from '../../../../assets/images/missionItems/actors/qr.png';
import rfidIcon from '../../../../assets/images/missionItems/actors/rfid.png';
import soundIcon from '../../../../assets/images/missionItems/actors/sound.png';
import textIcon from '../../../../assets/images/missionItems/actors/text.png';
import waterIcon from '../../../../assets/images/missionItems/actors/water.png';
import createWall from './wall.js';
import createRobot from './robot.js';
import createBarcode from './barcode.js';
import createColor from './color.js';
import createFire from './fire.js';
import createHuman from './human.js';
import createQR from './qr.js';
import createRFID from './rfid.js';
import createSound from './sound.js';
import createText from './text.js';
import createWater from './water.js';

const general = {
	name: 'General',
	components: {
		wall: {
			icon: wallIcon,
			component: createWall,
			props: {},
			header: 'Wall',
			text: 'A wall is an obstacle you can place anywhere in the map in order to not allow the robot to pass through. Walls are detectable from the robot’s distance sensors.',
		},
		robot: {
			icon: robotIcon,
			component: createRobot,
			props: {
				theta: 0,
				skin: 'normal',
				devices: {
					microphone: {
						name: 'Microphone',
						value: false,
						ids: ['microphone_front_'],
						parameters: [['duration']],
					},
					sonar: {
						name: 'Sonar',
						value: false,
						ids: ['sonar_bl_', 'sonar_br_', 'sonar_fl_', 'sonar_fr_', 'sonar_l_', 'sonar_r_'],
						parameters: [['distance'], ['distance'], ['distance'], ['distance'], ['distance'], ['distance']],
					},
					camera: {
						name: 'Camera & PanTilt',
						value: false,
						ids: ['tektrain_camera_', 'pan_tilt_'],
						parameters: [['-'], ['pan', 'tilt']],
					},
					imu: {
						name: 'IMU',
						value: false,
						ids: ['imu_'],
						parameters: [['acceleration.x', 'acceleration.y', 'acceleration.z', 'gyroscope.yaw', 'gyroscope.pitch', 'gyroscope.roll', 'magnetometer.yaw', 'magnetometer.pitch', 'magnetometer.roll']],
					},
					env: {
						name: 'Env',
						value: false,
						ids: ['env_'],
						parameters: [['data.temperature', 'data.pressure', 'data.humidity', 'data.gas']],
					},
					speaker: {
						name: 'Speaker',
						value: false,
						ids: ['speaker_front_'],
						parameters: [['text', 'volume', 'language']],
					},
					leds: {
						name: 'Leds',
						value: false,
						ids: ['leds_front_'],
						parameters: [['r', 'g', 'b', 'intensity']],
					},
					encoder: {
						name: 'Encoder',
						value: false,
						ids: ['encoder_bl_', 'encoder_br_'],
						parameters: [['rpm'], ['rpm']],
					},
					rfid_reader: {
						name: 'RFID Reader',
						value: false,
						ids: ['rfid_reader_'],
						parameters: [['data.tags.<tag_name>']],
					},
				},
			},
			header: 'Robot',
			text: 'A robot is a moving agent that is able to interact with the environment via actuators and measure properties of the environment via sensors.',
		},
		barcode: {
			icon: barcodeIcon,
			component: createBarcode,
			props: { message: '' },
			header: 'Barcode',
			text: 'A barcode component with a message'
		},
		color: {
			icon: colorIcon,
			component: createColor,
			props: {
				r: 255,
				g: 255,
				b: 255
			},
			header: 'Color',
			text: 'A simulated area with a specific dominant color'
		},
		fire: {
			icon: fireIcon,
			component: createFire,
			props: {
				range: 20,
				temperature: 150
			},
			header: 'Fire',
			text: 'A source of fire, denoting large temperatures'
		},
		human: {
			icon: humanIcon,
			component: createHuman,
			props: {
				move: 0,
				sound: 0,
				lang: 'EL',
				range: 20,
				speech: '',
				emotion: 'neutral',
				gender: 'none',
				age: -1
			},
			header: 'Human',
			text: 'A human that can also speak and walk'
		},
		qr: {
			icon: qrIcon,
			component: createQR,
			props: { message: '' },
			header: 'QR',
			text: 'A QR component with a message'
		},
		rfid: {
			icon: rfidIcon,
			component: createRFID,
			props: { message: '' },
			header: 'RFID Tag',
			text: 'An RFID tag with a message'
		},
		sound: {
			icon: soundIcon,
			component: createSound,
			props: {
				lang: 'EL',
				range: 3,
				speech: '',
				emotion: 'neutral'
			},
			header: 'Sound',
			text: 'A sound source'
		},
		text: {
			icon: textIcon,
			component: createText,
			props: { text: '' },
			header: 'Text',
			text: 'A text that denotes a message and can be detected via OCR algorithms'
		},
		water: {
			icon: waterIcon,
			component: createWater,
			props: { range: 10 },
			header: 'Water',
			text: 'A source of water, denoting high humidity'
		}
	},
};

export default general;


/* eslint-disable max-len */

import {
	EditableText, InputGroup, Menu, Popover, Tooltip,
} from '@blueprintjs/core';
import {
	faMinusCircle, faPlusCircle, faTrash, faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';
import { ToasterBottom } from "../../../lib_socioty/toaster.js";
import { GreenBorderButton } from '../../../lib_socioty/buttons.js';
import relay from '../../../../assets/images/missionItems/effectors/relay.png';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

const ItemDiv = styled.div`
	width: ${(props) => `${props.elementDimensions}px`};
	height: ${(props) => `${props.elementDimensions}px`};
	border-radius: ${(props) => `${props.elementDimensions}px`};
	position: absolute;
	left: ${(props) => `calc(${props.x}% - ${(props.elementDimensions / 2)}px)`};
	bottom: ${(props) => `calc(${props.y}% - ${(props.elementDimensions / 2)}px)`};
	cursor: ${(props) => ((props.dragging) ? 'move' : 'pointer')};
	display: flex;
	transform: ${(props) => (props.dragging ? '' : 'none!important')};
`;

const BasicPopover = ({ className, elementDimensions, popoverOpen, closePopover, changeName, checkName, itemName, allowedStates, changeAllowedStates, changeTempValue, tempValue, addAllowedState, initialState, changeInitialState, deleteItem }) => (
	<Popover usePortal popoverClassName={`${className} item-popover`} elementDimensions={elementDimensions} isOpen={popoverOpen}>
		<div style={{
			width: '300px', padding: '10px', color: 'white', background: 'black', boxShadow: '4px 4px 10px 1px #333333',
		}}
		>
			{/* Name */}
			<div style={{
				width: '100%', maxWidth: '100%', paddingLeft: '1px', paddingRight: '1px', display: 'flex', justifyContent: 'space-around', paddingBottom: "10px",
			}}
			>
				<TextField
					value={itemName}
					label="Name"
					variant="filled"
					sx={{
						backgroundColor: '#aaa',
						mx: 1,
						width: '100%',
					}}
					size="small"
					color="success"
					onChange={(event) => changeName(event.target.value)}
				/>
			</div>

			<div
				style={{
					width: '100%', height: '20px', marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center',
				}}
			>
				{"Allowed States\r"}
			</div>
			<div
				style={{
					width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
				}}
			>
				{allowedStates.map((alState, ind) => (
					<div
						key={ind}
						style={{
							width: '100%', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center',
						}}
					>
						<div style={{ width: '80%', height: '100%', display: 'flex', alignItems: 'center' }}>
							{(alState.length <= 8) ? alState : `${alState.slice(0, 4)}...${alState.substring(alState.length - 4, alState.length)}`}
						</div>
						<div
							style={{
								width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer',
							}}
						>
							<FontAwesomeIcon icon={faMinusCircle} style={{ fontSize: '16px', color: 'red' }} onClick={() => changeAllowedStates(ind)} />
						</div>
					</div>
				))}
				<div
					style={{
						width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px',
					}}
				>
					<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
						<TextField
							value={tempValue}
							label="New State"
							variant="filled"
							sx={{
								backgroundColor: '#aaa',
							}}
							size="small"
							color="success"
							onChange={(event) => changeTempValue(event)}
						/>
					</div>
					<div
						style={{
							width: '30px', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer',
						}}
					>
						<FontAwesomeIcon icon={faPlusCircle} style={{ fontSize: '16px', color: '#3c9764' }} onClick={() => addAllowedState()} />
					</div>
				</div>
			</div>

			<div
				style={{
					width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
				}}
			>
				<FormControl sx={{ m: 1, width: "100%" }} size="small">
					<Select
						labelId="demo-select-small-label"
						value={initialState}
						label="Initial state"
						sx={{
							backgroundColor: '#aaa',
						}}
						size="small"
					>
						{allowedStates.map((alState) => (
							<MenuItem key={Math.random()} value={alState} onClick={() => changeInitialState(alState)}>
								{`Initial state: ${alState}`}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
			<div style={{
				width: '100%', height: '20px', marginTop: '10px', display: 'flex', justifyContent: 'space-around',
			}}
			>
				<FontAwesomeIcon icon={faTrash} style={{ fontSize: '20px', cursor: 'pointer' }} onClick={deleteItem} />
				<FontAwesomeIcon icon={faXmarkCircle} style={{ fontSize: '20px', cursor: 'pointer' }} onClick={closePopover} />
			</div>
		</div>
	</Popover>
);

const CustomPopover = styled(BasicPopover)`
	transform: ${(props) => `translate(${-(props.elementDimensions / 2)}px, 0px)`};
	border-radius: 10px;
`;

class Relay extends React.Component {
	constructor(props) {
		super(props);

		const initialState = props.state;
		this.cat = initialState.cat;
		this.itemCat = initialState.itemCat;
		this.updateItem = props.updateItem;
		this.deleteItem = props.deleteItem;
		this.checkItemNameExists = props.checkItemNameExists;

		this.state = {
			id: initialState.id,
			itemName: initialState.itemName,
			x: initialState.x,
			y: initialState.y,
			allowedStates: initialState.allowedStates,
			initialState: initialState.initialState,
			elementDimensions: props.elementDimensions,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
			dragging: false,
			popoverOpen: false,
			resolution: props.resolution,
			metersPopupOpen: false,
			xMeters: 0,
			yMeters: 0,
			tempValue: '',
		};
		this.interval = null;
		this.initialName = initialState.itemName;

		this.onStart = this.onStart.bind(this);
		this.onDrag = this.onDrag.bind(this);
		this.onStop = this.onStop.bind(this);
		this.onClick = this.onClick.bind(this);
		this.delete = this.delete.bind(this);
		this.sendUpdate = this.sendUpdate.bind(this);
		this.changeName = this.changeName.bind(this);
		this.checkName = this.checkName.bind(this);
		this.changeAllowedStates = this.changeAllowedStates.bind(this);
		this.changeInitialState = this.changeInitialState.bind(this);
		this.changeTempValue = this.changeTempValue.bind(this);
		this.addAllowedState = this.addAllowedState.bind(this);
	}

	componentDidMount() {
		const { itemName, x, y, allowedStates, initialState } = this.state;
		this.sendUpdate(itemName, x, y, allowedStates, initialState);
	}

	static getDerivedStateFromProps(props) {
		const initialState = props.state;
		return {
			id: initialState.id,
			itemName: initialState.itemName,
			x: initialState.x,
			y: initialState.y,
			allowedStates: initialState.allowedStates,
			initialState: initialState.initialState,
			elementDimensions: props.elementDimensions,
			mapWidth: props.mapWidth,
			mapHeight: props.mapHeight,
			resolution: props.resolution,
		};
	}

	onStart(event) {
		event.preventDefault();
		const { x, y, mapWidth, mapHeight, resolution } = this.state;
		const xMeters = ((x / 100) * mapWidth) * resolution;
		const yMeters = ((y / 100) * mapHeight) * resolution;
		this.setState({ metersPopupOpen: true, xMeters, yMeters });
	}

	onDrag(event, data) {
		event.preventDefault();
		const { x, y, mapWidth, mapHeight, resolution } = this.state;
		const xMeters = (((x / 100) * mapWidth) + data.x) * resolution;
		const yMeters = (((y / 100) * mapHeight) - data.y) * resolution;
		this.setState({ dragging: true, xMeters, yMeters });
	}

	onStop(event, data) {
		event.preventDefault();
		const { dragging } = this.state;
		this.setState({ dragging: false, metersPopupOpen: false });
		if (!dragging) {
			this.onClick();
		}

		const { itemName, x, y, allowedStates, initialState, mapWidth, mapHeight } = this.state;
		let newX = ((((x / 100) * mapWidth) + data.x) / mapWidth) * 100;
		if (newX > 100) {
			newX = 100;
		}

		if (newX < 0) {
			newX = 0;
		}

		let newY = ((((y / 100) * mapHeight) - data.y) / mapHeight) * 100;
		if (newY > 100) {
			newY = 100;
		}

		if (newY < 0) {
			newY = 0;
		}

		this.sendUpdate(itemName, newX, newY, allowedStates, initialState);
	}

	onClick() {
		this.setState({ popoverOpen: true });
	}

	delete() {
		const { id } = this.state;
		this.deleteItem(this.cat, this.itemCat, id);
	}

	sendUpdate(itemName, x, y, allowedStates, initialState) {
		const { id } = this.state;
		this.updateItem(this.cat, this.itemCat, id, {
			id, itemName, x, y, allowedStates, initialState, cat: this.cat, itemCat: this.itemCat,
		});
	}

	changeName(value) {
		const { x, y, allowedStates, initialState } = this.state;
		this.sendUpdate(value.toLowerCase().split(' ').join('_'), x, y, allowedStates, initialState);
	}

	checkName(value) {
		const { id } = this.state;
		const { matchesPattern, found } = this.checkItemNameExists(value, this.initialName, this.cat, this.itemCat, id);
		if (matchesPattern) {
			ToasterBottom.show({
				intent: 'danger',
				message: 'Name cannot follow the template category_number',
			});
			const { x, y, allowedStates, initialState } = this.state;
			this.sendUpdate(this.initialName, x, y, allowedStates, initialState);
		} else if (found) {
			ToasterBottom.show({
				intent: 'danger',
				message: 'Name already exists',
			});
			const { x, y, allowedStates, initialState } = this.state;
			this.sendUpdate(this.initialName, x, y, allowedStates, initialState);
		}
	}

	changeAllowedStates(ind) {
		const { itemName, x, y, allowedStates, initialState } = this.state;
		const states = [...allowedStates];
		let initState = initialState;
		let initialStateDeleted = false;
		if (initialState === states[ind]) {
			initialStateDeleted = true;
		}

		states.splice(ind, 1);
		if (initialStateDeleted) {
			if (states.length === 0) {
				initState = '-';
			} else {
				[initState] = states;
			}
		}

		this.sendUpdate(itemName, x, y, states, initState);
	}

	changeInitialState(value) {
		const { itemName, x, y, allowedStates } = this.state;
		this.sendUpdate(itemName, x, y, allowedStates, value);
	}

	changeTempValue(event) {
		this.setState({ tempValue: event.target.value });
	}

	addAllowedState() {
		const { itemName, x, y, allowedStates, initialState, tempValue } = this.state;
		if (tempValue !== '') {
			const states = [...allowedStates];
			if (!states.includes(tempValue)) {
				states.push(tempValue);
			}

			this.sendUpdate(itemName, x, y, states, initialState);
			this.setState({ tempValue: '' });
		}
	}

	render() {
		const { id, itemName, x, y, allowedStates, initialState, elementDimensions, dragging, popoverOpen, metersPopupOpen, xMeters, yMeters, tempValue } = this.state;

		return (
			<Draggable
				key={`switch_${id}`}
				allowAnyClick={false}
				grid={[10, 10]}
				position={{ x: 0, y: 0 }}
				handle=".itemhandler"
				onStart={this.onStart}
				onDrag={this.onDrag}
				onStop={this.onStop}
			>
				<ItemDiv id={`item_${id}`} x={x} y={y} elementDimensions={elementDimensions} dragging={dragging}>
					<Tooltip
						popoverClassName="item-info-tooltip"
						disabled={dragging || popoverOpen}
						content={(
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									textAlign: 'center',
									maxWidth: '200px',
									backgroundColor: "rgba(0, 0, 0, 0.8)",
									padding: '10px',
									borderRadius: '10px',
									color: 'white',
								}}
							>
								<div style={{ fontWeight: 'bold', fontSize: '16px' }}>
									{"Allowed States\r"}
								</div>
								<div style={{ textAlign: 'center', height: 'fit-content', wordBreak: 'break-word' }}>
									{`[${allowedStates.map((alState, ind) => ((ind === 0) ? alState : ` ${alState}`))}]`}
								</div>
								<div style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '10px' }}>
									{"Initial State\r"}
								</div>
								<div style={{ textAlign: 'center', height: 'fit-content', wordBreak: 'break-word' }}>
									{initialState}
								</div>
							</div>
						)}
						interactionKind="hover"
					>
						<img
							key={Math.random()}
							id={`itemImg_${id}`}
							className="itemhandler"
							src={relay}
							alt=""
							draggable={false}
							style={{ maxWidth: '100%', maxHeight: '100%' }}
						/>
					</Tooltip>
					<div
						style={{
							display: (metersPopupOpen) ? 'block' : 'none', width: 'max-content', paddingLeft: '5px', paddingRight: '5px', height: '20px', position: 'absolute', top: `-${(elementDimensions / 2) + 5}px`, left: `${(elementDimensions / 2) - (145 / 2)}px`, fontSize: '14px', textAlign: 'center', background: '#7a8585', borderRadius: '10px', color: 'white',
						}}
					>
						{`x: ${xMeters.toFixed(3)}m, y: ${yMeters.toFixed(3)}m`}
					</div>
					{popoverOpen && (
						<CustomPopover
							elementDimensions={elementDimensions}
							popoverOpen={popoverOpen}
							closePopover={() => { this.setState({ popoverOpen: false }); }}
							changeName={this.changeName}
							checkName={this.checkName}
							itemName={itemName}
							allowedStates={allowedStates}
							changeAllowedStates={this.changeAllowedStates}
							changeTempValue={this.changeTempValue}
							tempValue={tempValue}
							addAllowedState={this.addAllowedState}
							initialState={initialState}
							changeInitialState={this.changeInitialState}
							deleteItem={this.delete}
						/>
					)}
				</ItemDiv>
			</Draggable>
		);
	}
}

const createRelay = (state, resolution, elementDimensions, mapWidth, mapHeight, updateItem, deleteItem, checkItemNameExists) => (
	<Relay key={state.id} state={state} resolution={resolution} elementDimensions={elementDimensions} mapWidth={mapWidth} mapHeight={mapHeight} updateItem={updateItem} deleteItem={deleteItem} checkItemNameExists={checkItemNameExists} />
);

export default createRelay;

import bathroom from '../../../assets/images/background/home/bathroom.png';
import bedroom from '../../../assets/images/background/home/bedroom.png';
import dinnerRoom from '../../../assets/images/background/home/dinner_room.png';
import floor from '../../../assets/images/background/home/floor.png';
import floorArmchairs from '../../../assets/images/background/home/floor_armchairs.png';
import floorCorner from '../../../assets/images/background/home/floor_corner.png';
import floorCross from '../../../assets/images/background/home/floor_cross.png';
import floorHall from '../../../assets/images/background/home/floor_hall.png';
import floorStraight from '../../../assets/images/background/home/floor_straight.png';
import floorT from '../../../assets/images/background/home/floor_t.png';
import floorTable from '../../../assets/images/background/home/floor_table.png';
import floorUp from '../../../assets/images/background/home/floor_up.png';
import floorUpRight from '../../../assets/images/background/home/floor_up_right.png';
import grass from '../../../assets/images/background/home/grass.png';
import kidsRoom from '../../../assets/images/background/home/kids_room.png';
import kitchen from '../../../assets/images/background/home/kitchen.png';
import masterBedroom from '../../../assets/images/background/home/master_bedroom.png';
import photovoltaic from '../../../assets/images/background/home/photovoltaic.png';
import pool from '../../../assets/images/background/home/pool.png';
import start from '../../../assets/images/background/home/start.png';
import tvRoom from '../../../assets/images/background/home/tv_room.png';
import yard from '../../../assets/images/background/home/yard.png';
import yardCorner from '../../../assets/images/background/home/yard_corner.png';
import yardDoor from '../../../assets/images/background/home/yard_door.png';

const home = {
	name: 'Home',
	components: {
		bathroom: {
			id: 'bathroom',
			image: bathroom,
			text: 'Bathroom',
			walls: [
				{ x1: 32, y1: 0, x2: 0, y2: 0 },
				{ x1: 0, y1: 0, x2: 0, y2: 100 },
				{ x1: 0, y1: 100, x2: 100, y2: 100 },
				{ x1: 100, y1: 100, x2: 100, y2: 0 },
				{ x1: 100, y1: 0, x2: 68, y2: 0 },
			],
			waters: [],
		},
		bedroom: {
			id: 'bedroom',
			image: bedroom,
			text: 'Bedroom',
			walls: [
				{ x1: 32, y1: 0, x2: 0, y2: 0 },
				{ x1: 0, y1: 0, x2: 0, y2: 100 },
				{ x1: 0, y1: 100, x2: 100, y2: 100 },
				{ x1: 100, y1: 100, x2: 100, y2: 0 },
				{ x1: 100, y1: 0, x2: 68, y2: 0 },
			],
			waters: [],
		},
		dinnerRoom: {
			id: 'dinnerRoom',
			image: dinnerRoom,
			text: 'Dinner Room',
			walls: [
				{ x1: 32, y1: 0, x2: 0, y2: 0 },
				{ x1: 0, y1: 0, x2: 0, y2: 100 },
				{ x1: 0, y1: 100, x2: 100, y2: 100 },
				{ x1: 100, y1: 100, x2: 100, y2: 0 },
				{ x1: 100, y1: 0, x2: 68, y2: 0 },
			],
			waters: [],
		},
		floor: {
			id: 'floor',
			image: floor,
			text: 'Floor',
			walls: [],
			waters: [],
		},
		floorArmchairs: {
			id: 'floorArmchairs',
			image: floorArmchairs,
			text: 'Floor with Armchairs',
			walls: [
				{ x1: 8, y1: 3, x2: 8, y2: 95 },
				{ x1: 8, y1: 95, x2: 50, y2: 95 },
				{ x1: 50, y1: 95, x2: 50, y2: 3 },
				{ x1: 50, y1: 3, x2: 8, y2: 3 },
			],
			waters: [],
		},
		floorCorner: {
			id: 'floorCorner',
			image: floorCorner,
			text: 'Floor Corner',
			walls: [
				{ x1: 32, y1: 0, x2: 32, y2: 69 },
				{ x1: 32, y1: 69, x2: 100, y2: 69 },
				{ x1: 100, y1: 31, x2: 68, y2: 31 },
				{ x1: 68, y1: 31, x2: 68, y2: 0 },
			],
			waters: [],
		},
		floorCross: {
			id: 'floorCross',
			image: floorCross,
			text: 'Floor Cross',
			walls: [
				{ x1: 32, y1: 0, x2: 32, y2: 31 },
				{ x1: 32, y1: 31, x2: 0, y2: 31 },
				{ x1: 0, y1: 69, x2: 32, y2: 69 },
				{ x1: 32, y1: 69, x2: 32, y2: 100 },
				{ x1: 68, y1: 100, x2: 68, y2: 69 },
				{ x1: 68, y1: 69, x2: 100, y2: 69 },
				{ x1: 100, y1: 31, x2: 68, y2: 31 },
				{ x1: 68, y1: 31, x2: 68, y2: 0 },
			],
			waters: [],
		},
		floorHall: {
			id: 'floorHall',
			image: floorHall,
			text: 'Floor Hall',
			walls: [
				{ x1: 32, y1: 0, x2: 32, y2: 31 },
				{ x1: 32, y1: 31, x2: 0, y2: 31 },
				{ x1: 0, y1: 69, x2: 32, y2: 69 },
				{ x1: 32, y1: 69, x2: 32, y2: 100 },
			],
			waters: [],
		},
		floorStraight: {
			id: 'floorStraight',
			image: floorStraight,
			text: 'Floor Straight',
			walls: [
				{ x1: 0, y1: 31, x2: 100, y2: 31 },
				{ x1: 0, y1: 69, x2: 100, y2: 69 },
			],
			waters: [],
		},
		floorT: {
			id: 'floorT',
			image: floorT,
			text: 'Floor T',
			walls: [
				{ x1: 32, y1: 0, x2: 32, y2: 31 },
				{ x1: 32, y1: 31, x2: 0, y2: 31 },
				{ x1: 0, y1: 69, x2: 100, y2: 69 },
				{ x1: 100, y1: 31, x2: 68, y2: 31 },
				{ x1: 68, y1: 31, x2: 68, y2: 0 },
			],
			waters: [],
		},
		floorTable: {
			id: 'floorTable',
			image: floorTable,
			text: 'Floor Table',
			walls: [
				{ x1: 8, y1: 52, x2: 8, y2: 92 },
				{ x1: 8, y1: 92, x2: 53, y2: 92 },
				{ x1: 53, y1: 92, x2: 53, y2: 52 },
				{ x1: 53, y1: 52, x2: 8, y2: 52 },
				{ x1: 73, y1: 6, x2: 73, y2: 26 },
				{ x1: 73, y1: 26, x2: 94, y2: 26 },
				{ x1: 94, y1: 26, x2: 94, y2: 6 },
				{ x1: 94, y1: 6, x2: 73, y2: 6 },
			],
			waters: [],
		},
		floorUp: {
			id: 'floorUp',
			image: floorUp,
			text: 'Floor Up Wall',
			walls: [
				{ x1: 0, y1: 69, x2: 100, y2: 69 },
			],
			waters: [],
		},
		floorUpRight: {
			id: 'floorUpRight',
			image: floorUpRight,
			text: 'Floor Up Right Walls',
			walls: [
				{ x1: 0, y1: 69, x2: 68, y2: 69 },
				{ x1: 68, y1: 69, x2: 68, y2: 0 },
			],
			waters: [],
		},
		grass: {
			id: 'grass',
			image: grass,
			text: 'Grass',
			walls: [],
			waters: [],
		},
		kidsRoom: {
			id: 'kidsRoom',
			image: kidsRoom,
			text: 'Kids Room',
			walls: [
				{ x1: 32, y1: 0, x2: 0, y2: 0 },
				{ x1: 0, y1: 0, x2: 0, y2: 100 },
				{ x1: 0, y1: 100, x2: 100, y2: 100 },
				{ x1: 100, y1: 100, x2: 100, y2: 0 },
				{ x1: 100, y1: 0, x2: 68, y2: 0 },
			],
			waters: [],
		},
		kitchen: {
			id: 'kitchen',
			image: kitchen,
			text: 'Kitchen',
			walls: [
				{ x1: 32, y1: 0, x2: 0, y2: 0 },
				{ x1: 0, y1: 0, x2: 0, y2: 100 },
				{ x1: 0, y1: 100, x2: 100, y2: 100 },
				{ x1: 100, y1: 100, x2: 100, y2: 0 },
				{ x1: 100, y1: 0, x2: 68, y2: 0 },
			],
			waters: [],
		},
		masterBedroom: {
			id: 'masterBedroom',
			image: masterBedroom,
			text: 'Master Bedroom',
			walls: [
				{ x1: 32, y1: 0, x2: 0, y2: 0 },
				{ x1: 0, y1: 0, x2: 0, y2: 100 },
				{ x1: 0, y1: 100, x2: 100, y2: 100 },
				{ x1: 100, y1: 100, x2: 100, y2: 0 },
				{ x1: 100, y1: 0, x2: 68, y2: 0 },
			],
			waters: [],
		},
		photovoltaic: {
			id: 'photovoltaic',
			image: photovoltaic,
			text: 'Photovoltaic',
			walls: [],
			waters: [],
		},
		pool: {
			id: 'pool',
			image: pool,
			text: 'Pool',
			walls: [],
			waters: [{ x: 60, y: 46, range: 15 }],
		},
		start: {
			id: 'start',
			image: start,
			text: 'Start',
			walls: [
				{ x1: 0, y1: 69, x2: 32, y2: 69 },
				{ x1: 32, y1: 69, x2: 32, y2: 100 },
				{ x1: 68, y1: 100, x2: 68, y2: 69 },
				{ x1: 68, y1: 69, x2: 100, y2: 69 },
			],
			waters: [],
		},
		tvRoom: {
			id: 'tvRoom',
			image: tvRoom,
			text: 'Tv Room',
			walls: [
				{ x1: 32, y1: 0, x2: 0, y2: 0 },
				{ x1: 0, y1: 0, x2: 0, y2: 100 },
				{ x1: 0, y1: 100, x2: 100, y2: 100 },
				{ x1: 100, y1: 100, x2: 100, y2: 0 },
				{ x1: 100, y1: 0, x2: 68, y2: 0 },
			],
			waters: [],
		},
		yard: {
			id: 'yard',
			image: yard,
			text: 'Yard',
			walls: [
				{ x1: 0, y1: 69, x2: 100, y2: 69 },
			],
			waters: [],
		},
		yardCorner: {
			id: 'yardCorner',
			image: yardCorner,
			text: 'Yard Corner',
			walls: [
				{ x1: 0, y1: 69, x2: 32, y2: 69 },
				{ x1: 32, y1: 69, x2: 32, y2: 100 },
			],
			waters: [],
		},
		yardDoor: {
			id: 'yardDoor',
			image: yardDoor,
			text: 'Yard Door',
			walls: [
				{ x1: 0, y1: 69, x2: 32, y2: 69 },
				{ x1: 32, y1: 69, x2: 32, y2: 100 },
				{ x1: 68, y1: 100, x2: 68, y2: 69 },
				{ x1: 68, y1: 69, x2: 100, y2: 69 },
			],
			waters: [],
		},
	},
};

export default home;

export const systemToolboxes = [
	{
		name: "Utilities",
		backgroundColor: "#666",
		fontColor: "#fff",
		nodes: [
			{
				name: "Start",
				inputs: 0,
				outputs: 1,
				parameters: [
					{
						id: "delay",
						type: "input",
						label: "Artificial delay",
						value: 0,
						required: true,
						show: true,
					},
				],
			},
			{
				name: "End",
				inputs: 1,
				outputs: 0,
			},
			{
				name: "Delay",
				parameters: [
					{
						id: "time",
						type: "input",
						label: "Time",
						value: 1,
						required: true,
						show: true,
					},
				],
			},
			{
				name: "Thread split",
				inputs: 1,
				outputs: 2,
			},
			{
				name: "Thread join",
				inputs: 2,
				outputs: 1,
			},
			{
				name: "Condition",
				outputs: 2,
				parameters: [
					{
						id: "condition_1",
						type: "input",
						label: "Condition #1",
						required: true,
						value: "False",
					},
					{
						id: "default",
						type: "input",
						label: "Default",
						value: "True",
						locked: true,
					},
				],
			},
			{
				name: "Random",
				outputs: 2,
				parameters: [
					{
						id: "probability_1",
						type: "input",
						label: "Probability #1",
						required: true,
						value: 0.5,
						show: true,
					},
					{
						id: "probability_2",
						type: "input",
						label: "Probability #2",
						required: true,
						value: 0.5,
						show: true,
					},
				],
			},
			{
				name: "Preempt",
				outputs: 1,
				parameters: [
					{
						id: "thread",
						type: "thread",
						label: "Thread to preempt",
						required: true,
						show: true,
					},
				],
			},
			{
				name: "Log",
				parameters: [
					{
						id: "message",
						type: "input",
						label: "Message",
						required: true,
						show: true,
					},
				],
			},
		],
	},
	{
		name: "Advanced",
		backgroundColor: "#666",
		fontColor: "#fff",
		nodes: [
			{
				name: "Create variable",
				parameters: [
					{
						id: "variable",
						type: "input",
						label: "Variable name",
						required: true,
						show: true,
						// value: "variable",
					},
					{
						id: "value",
						type: "input",
						label: "Initial value",
						required: true,
						show: true,
						// value: "value",
					},
				],
			},
			{
				name: "Set variable",
				parameters: [
					{
						id: "variable",
						type: "input",
						label: "Variable name",
						required: true,
						show: true,
						// value: "variable",
					},
					{
						id: "value",
						type: "input",
						label: "Value",
						required: true,
						show: true,
						// value: "value",
					},
				],
			},
		],
	},
	{
		name: "Esp-robot",
		backgroundColor: "#800",
		fontColor: "#fff",
		nodes: [
			{
				name: "Move",
				inputs: 1,
				outputs: 1,
				action: {
					type: "publish",
					topic: "thing/esprobot/actuators/motion/base",
					payload: {
						linear: "{linear}",
						angular: "{angular}",
					},
				},
				parameters: [
					{
						id: "broker",
						type: "broker",
						label: "Broker",
						required: true,
						show: true,
					},
					{
						id: "linear",
						type: "input",
						label: "Linear speed",
						value: 0,
						required: true,
						show: true,
					},
					{
						id: "angular",
						type: "input",
						label: "Angular speed",
						value: 0,
						required: true,
						show: true,
					},
				],
			},
			{
				name: "Front range subscriber",
				inputs: 1,
				outputs: 1,
				action: {
					type: "subscribe",
					topic: "thing/esprobot/sensors/distance/front",
					storage: "esprobot_range",
				},
				parameters: [
					{
						id: "broker",
						type: "broker",
						label: "Broker",
						required: true,
						show: true,
					},
					{
						id: "operation",
						type: "select",
						label: "Operation",
						options: [
							{
								label: "Start",
								value: "start",
							},
							{
								label: "Stop",
								value: "stop",
							},
						],
						required: true,
						value: "start",
						show: true,
					},
				],
			},
			{
				name: "IMU subscriber",
				inputs: 1,
				outputs: 1,
				action: {
					type: "subscribe",
					topic: "thing/esprobot/sensors/motion/imu",
					storage: "esprobot_imu",
				},
				parameters: [
					{
						id: "broker",
						type: "broker",
						label: "Broker",
						required: true,
						show: true,
					},
					{
						id: "operation",
						type: "select",
						label: "Operation",
						options: [
							{
								label: "Start",
								value: "start",
							},
							{
								label: "Stop",
								value: "stop",
							},
						],
						required: true,
						value: "start",
						show: true,
					},
				],
			},
			{
				name: "Env sensor subscriber",
				inputs: 1,
				outputs: 1,
				action: {
					type: "subscribe",
					topic: "thing/esprobot/sensors/env/bme",
					storage: "esprobot_env",
				},
				parameters: [
					{
						id: "broker",
						type: "broker",
						label: "Broker",
						required: true,
						show: true,
					},
					{
						id: "operation",
						type: "select",
						label: "Operation",
						options: [
							{
								label: "Start",
								value: "start",
							},
							{
								label: "Stop",
								value: "stop",
							},
						],
						required: true,
						value: "start",
						show: true,
					},
				],
			},
		],

	},
];
